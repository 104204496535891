import axios from 'axios';
import { toast } from 'react-toastify';

export const newTeam = async (name, user_access_key) =>{
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/team/create`, {name, user_access_key})
        .then((response)=>{
            toast.success(`Team ${name} created!`)
            return response.data.access_key
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('An error ocurred.')
    }
}

export const newTeamPlayers = async (team, players, user_access_key) =>{
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/team/create_team_players`, {team, players, user_access_key})
        .then((response)=>{

            toast.success(`Team created!`)
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('An error ocurred.')
    }
}

export const listTeamByName = async (teamName = '') =>{
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/team/list_by_name/${teamName}`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('An error ocurred.')
    }
}

export const listTeamByCreateBy = async (user_access_key) =>{
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/team/list_by_created_by/${user_access_key}`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('An error ocurred.')
    }
}

export const loadDefaultTeamPlayers = async () => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/team/load_default_team/`)
        .then((response)=>{
            return response.data[0]
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('An error ocurred.')
    }
}

export const listPlayersByTeamId = async (team_access_key) => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/team/list_by_team/${team_access_key}`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('An error ocurred.')
    }
}
