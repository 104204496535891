import React from 'react'
import CourtLayoutItemComponent from './CourtLayoutItemComponent'

function SideLayoutComponent({menuButtons, court, courtPositions, gridLayout, positions}) {
    return (
        <div className='flex gap-2 w-full'>
            <div className='flex flex-column w-4'>
                {
                    court && courtPositions?.slice(0,3).map((item, key)=>{
                        const index = court.findIndex((e)=>Number(e.courtPosition) === Number(item.number))
                        return (
                            <CourtLayoutItemComponent key={key} item={index >= 0 ? court[index] : null} positions={positions} gridLayout={gridLayout} />)
                        })
                }
            </div>
            <div className='w-4'>
                {menuButtons}
            </div>
            <div className='flex flex-column w-4'>
                {
                    court && courtPositions?.slice(3,6).map((item, key)=>{
                        const index = court.findIndex((e)=>Number(e.courtPosition) === Number(item.number))
                        return (<CourtLayoutItemComponent key={key} item={index >= 0 ? court[index] : null} positions={positions} />)
                        })
                }
            </div>
        </div>
    )
}

export default SideLayoutComponent