import React, { useEffect, useState } from 'react'
import { listEventsByLeague } from '../../../services/EventService'
import EventItemListComponent from '../Event/EventComponent/EventItemListComponent/EventItemListComponent'

function LeagueEventsList({access_key}) {

    const [events, setEvents] = useState([])

    useEffect(()=>{
        handleListEvents()
    },[access_key])

    const handleListEvents = () => {
        access_key && listEventsByLeague(access_key).then((response)=>{
            setEvents(response)
        })
    }

    return (
        <div>
            <div className='grid'>
                {
                    events ? 
                    events.map((item, key)=>(
                        <EventItemListComponent key={key} data={item} />
                    )) :
                    <div>No events found</div> 
                }
            </div>
        </div>
    )
}

export default LeagueEventsList