import React, { useContext, useEffect, useState } from 'react'
import AppBodyComponent from '../../../../components/AppBody/AppBodyComponent'
import './EventList.css'
import { useNavigate } from 'react-router-dom'
import { APIProvider, AdvancedMarker, InfoWindow, Map } from '@vis.gl/react-google-maps'
import MarkerMap from '../../../../components/MarkerMap/MarkerMap'
import InfoWindowMapEvent from '../../../../components/InfoWindowMapEvent/InfoWindowMapEvent'
import EventItemListComponent from '../EventComponent/EventItemListComponent/EventItemListComponent'
import SportAutocompleteComponent from '../../../../components/SportAutocompleteComponent/SportAutocompleteComponent'
import { Button } from 'primereact/button'
import { Faders, MapTrifold, Plus, Rows } from '@phosphor-icons/react'
import { listEvents } from '../../../../services/EventService'
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { RequestType, geocode, setDefaults } from 'react-geocode'
import { toast } from 'react-toastify'
import AuthContext from '../../../../context/AuthContext/AuthContext'
import LeagueAutocompleteComponent from '../../../../components/LeagueAutocompleteComponent/LeagueAutocompleteComponent'
import { PiArrowArcLeftBold, PiArrowArcRightBold, PiCaretLeftBold, PiCaretRightBold } from 'react-icons/pi'
import PaginatorComponent from '../../../../components/PaginatorComponent/PaginatorComponent'
import ReactGA from 'react-ga4'        
import { Panel } from 'primereact/panel'

function EventList() {

    ReactGA.send({ hitType: "pageview", page: "/event_list", title: "Event List" });
    const {userAuth} = useContext(AuthContext)

    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    const [infowindowShown, setInfowindowShown] = useState(false);
    const [infoWindowData, setInfoWindowData] = useState()
    const [infoWindowLat, setInfoWindowLat] = useState();
    const [infoWindowLng, setInfoWindowLng] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [eventList, setEventList] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [mapBoundaries, setMapBoundaries] = useState()
    
    const [name, setName] = useState('');
    const [address, setAddress] = useState(userAuth.user.zipcode)
    const [addressMarker, setAddressMarker] = useState({lat: userAuth.user.lat, lng: userAuth.user.lng})
    const [miles, setMiles] = useState(userAuth?.user?.setting_miles_distance || 500)
    const [sport, setSport] = useState('')
    const [league, setLeague] = useState('')
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(()=>{
        var _date = new Date(startDate.toDateString())
        _date.setDate(_date.getDate() + 90)
        return _date
    })
    const [showSearchAreaButton, setShowSearchAreaButton] = useState(false)

    const navigate = useNavigate();

    const toggleInfoWindow = (data) => {
        setInfoWindowLat(parseFloat(data.lat))
        setInfoWindowLng(parseFloat(data.lng))
        setInfoWindowData(data)
        setInfowindowShown(previousState => !previousState)
    };

    setDefaults({
        key: googleMapsApiKey, // Your API key here.
        language: "en", // Default language for responses.
        region: "us", // Default region for responses.
      });

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(1)
    useEffect(()=>{
        handleListEvents()
    },[page])

    useEffect(()=>{
        let _mapBoundaries = {}
        if (eventList && eventList.length){
            _mapBoundaries = {
                east: parseFloat(eventList[0].lng),
                north: parseFloat(eventList[0].lat),
                south: parseFloat(eventList[0].lat),
                west: parseFloat(eventList[0].lng),
            }
            eventList.forEach((item)=>{
                _mapBoundaries.east = parseFloat(item.lng) > _mapBoundaries.east ? parseFloat(item.lng) : _mapBoundaries.east
                _mapBoundaries.west = parseFloat(item.lng) < _mapBoundaries.west ? parseFloat(item.lng) : _mapBoundaries.west
                _mapBoundaries.north = parseFloat(item.lat) > _mapBoundaries.north ? parseFloat(item.lat) : _mapBoundaries.north
                _mapBoundaries.south = parseFloat(item.lat) < _mapBoundaries.south ? parseFloat(item.lat) : _mapBoundaries.south
            })
        }
        setMapBoundaries(_mapBoundaries)
    },[eventList])

    const handleListEvents = async () => {
        if (startDate && endDate){
            setIsLoading(true)
            const response = await listEvents(
                name, 
                startDate, endDate, 
                showMap ? mapBoundaries : null,
                !showMap ? addressMarker : null, !showMap ? miles : null, 
                sport, league, page)
            setEventList(response.data)
            setTotal(response.total)
            setIsLoading(false)
        }
    }

    const closeInfoWindow = () => setInfowindowShown(false);

    const handleModalIsOpen = () => {
        setModalIsOpen(!modalIsOpen)
    }

    const handleConfirmFilter = async () => {
        setModalIsOpen(!modalIsOpen)
        address ? 
        await geocode(RequestType.ADDRESS, address+', USA')
        .then(async ({ results }) => {
            const { lat, lng } = results[0].geometry.location;
            await setAddressMarker({lat: lat, lng: lng});
            handleListEvents();
        })
        .catch((error) => {
            toast.error('Address invalid')
        }) : handleListEvents()
    }

    const handleCancelFilter = () => {
        setModalIsOpen(!modalIsOpen)
    }

    const  handleAddEvent = () =>{
        navigate('/app/event_form')
    }

    const handleShowMap = (param) => {
        setShowMap(param)
    }

    const handleDragingMap = (e) => {
        setShowSearchAreaButton(true)
        const _mapBoundaries = {
            east: e.detail.bounds.east,
            north: e.detail.bounds.north,
            south: e.detail.bounds.south,
            west: e.detail.bounds.west,
        }
        setMapBoundaries(_mapBoundaries)
}

    return (
        <AppBodyComponent activeMenuNumber={2}>
            <div className="event_list--container">

                <Panel unstyled>
                    <div className="flex justify-content-between flex-wrap p-2 bg-blue-900 w-full border-round gap-2">
                        <div className="flex align-content-center flex-wrap">
                            <span className="p-buttonset">
                                <Button severity='secondary' onClick={()=>handleShowMap(false)} size='small'>
                                    <Rows weight='bold' />
                                </Button>
                                <Button severity='secondary' onClick={()=>handleShowMap(true)} size='small'>
                                    <MapTrifold weight='bold' />
                                </Button>
                            </span>
                        </div>
                        <div className="flex align-content-center flex-wrap gap-2">
                            <Button onClick={handleAddEvent} size='small'><Plus weight='bold' /> Add Event</Button>
                            <Button severity='secondary' onClick={handleModalIsOpen} size='small'><Faders weight='bold' /></Button>
                        </div>
                    </div>
                </Panel>



                <Dialog header="Filter" visible={modalIsOpen} onHide={() => setModalIsOpen(false)} 
                footer={
                    <div>
                        <Button label="Cancel" icon="pi pi-times" onClick={handleCancelFilter} className="p-button-text" />
                        <Button label="Apply" icon="pi pi-check" onClick={handleConfirmFilter} autoFocus />
                    </div>
                }>
                    <div className="flex flex-column gap-2">

                        <div className="flex flex-column">
                            <div className="flex flex-row flex-wrap gap-2">
                                <div className='flex flex-column'>
                                    <label htmlFor='startDate' className='text--small--normal'>Start date</label>
                                    <Calendar value={startDate} onChange={(e)=>setStartDate(e.target.value)} className='p-inputtext-sm' name="startDate" id="startDate" />
                                </div>
                                <div className='flex flex-column'>
                                    <label htmlFor='endDate' className='text--small--normal'>End date</label>
                                    <Calendar value={endDate} onChange={(e)=>setEndDate(e.target.value)} className='p-inputtext-sm' name="endDate" id="endDate"/>
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-column'>
                            <label htmlFor='search' className='text--small--normal'>Event name</label>
                            <InputText autoComplete='off' value={name} onChange={(e)=>setName(e.target.value)} className='p-inputtext-sm' name="search" id="search" placeholder='' />
                        </div>

                        <div className='flex flex-column'>
                            <label htmlFor='league' className='text--small--normal'>League</label>
                            <LeagueAutocompleteComponent league={league} setLeague={(value)=>setLeague(value)} size='p-inputtext-sm' />
                        </div>

                        <div className='flex flex-column'>
                            <label htmlFor='sport' className='text--small--normal'>Sport</label>
                            <SportAutocompleteComponent sport={sport} setSport={(value)=>setSport(value)} size='p-inputtext-sm' />
                        </div>

                        <div className='flex flex-column'>
                            <label htmlFor='search' className='text--small--normal'>Address</label>
                            <InputText value={address} onChange={(e)=>setAddress(e.target.value)} className='p-inputtext-sm' name="address" id="address" />
                            <small>Address, zipcode, city, ...</small>
                        </div>
                        <div className='flex flex-column'>
                            <label htmlFor='search' className='text--small--normal'>Distance</label>
                            <InputText value={miles} onChange={(e)=>setMiles(e.target.value)} className='p-inputtext-sm' type="number" name="miles" id="miles"/>
                            <small>Enter the distance in miles</small>
                        </div>

                    </div>
                </Dialog>





                <div className="event_list--content">
                    {isLoading ? 'Loading...' : 
                    !eventList?.length ? 'No events found' :  
                    (showMap && mapBoundaries) ? 
                        <div className="event_list--map_area">
                            <APIProvider apiKey={googleMapsApiKey}>
                                {showSearchAreaButton && <Button label='Search in this area' size='small' onClick={handleListEvents} />}
                                <Map onBoundsChanged={handleDragingMap}  
                                mapId={'event_list_map_id'} 
                                initialBounds={{
                                    east: mapBoundaries.east,
                                    north: mapBoundaries.north,
                                    south: mapBoundaries.south,
                                    west: mapBoundaries.west
                                }}
                                >
                                    <>
                                        {
                                            eventList && eventList.map((item, key)=>(
                                                <AdvancedMarker key={key} position={{lat: parseFloat(item.lat), lng: parseFloat(item.lng)}} 
                                                onClick={()=>toggleInfoWindow(item)}>
                                                    <MarkerMap size='medium' type='sport' sport={item.sport_name} />
                                                </AdvancedMarker>
                                            ))
                                        }
                                        <>
                                            {
                                                infowindowShown && (
                                                    <InfoWindow position={{lat: infoWindowLat, lng: infoWindowLng}} onCloseClick={closeInfoWindow}>
                                                        <InfoWindowMapEvent data={infoWindowData} />
                                                    </InfoWindow>
                                                )
                                            }
                                        </>
                                    </>
                                </Map>
                            </APIProvider>
                        </div>

                    
                        :


                        <div className='flex flex-column gap-4'>
                            <div className="grid">
                                {isLoading ? 'Loading...' : 
                                eventList?.length ? eventList.map((item, key)=>(
                                    <EventItemListComponent key={key} data={item} />
                                )) : 
                                    "No events found."
                                }
                            </div>
                            <PaginatorComponent page={page} total={total} setPage={setPage} />
                        </div>
                    }
                </div>
            </div>
        </AppBodyComponent>
    )
}

export default EventList