import React from 'react'
import './ChatListItemComponent.css';
import { PiCaretRightBold } from 'react-icons/pi';
import UserPicture from '../../../../images/user_image_medium.png'

function ChatListItemComponent({data}) {
  return (
    <a href="/app/chat_detail" className='chat_list_item--container'>
        <div className="chat_list_item--icon_frame">
            <img src={UserPicture} alt="" />
        </div>
        <div className="chat_list_item--content">
            <div className="chat_list_item--content--info">
                <div className="chat_list_item--content--main">
                    <div className="text--large--bold">{data.title}</div>
                    <div className="text--regular--normal">{data.last_message}</div>
                </div>
                <div className="chat_list_item--content--detail">
                    <div className="text--small--normal">{data.last_message_date_time}</div>
                </div>
            </div>
            <div className="chat_list_item--content--action">
                <div className='icon_button'>
                    <PiCaretRightBold className="chat_list_item--content--action--icon" />
                </div>
            </div>
        </div>
    </a>
  )
}

export default ChatListItemComponent