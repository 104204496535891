import React, { useEffect, useState } from 'react'
import PinSportSmall from '../../images/pin_sport_small.png'
import PinUserSmall from '../../images/pin_user_small.png'
import PinSportMedium from '../../images/pin_sport_medium.png'
import PinUserMedium from '../../images/pin_user_medium.png'
import { PiBaseball, PiBasketball, PiFootball, PiMedal, PiSoccerBall, PiVolleyball } from 'react-icons/pi'
import './MarkerMap.css'
import { Avatar } from 'primereact/avatar'

function MarkerMap({size='small', type='user', sport='volleyball', userImg='', userName=''}) {
    
    const [markerIcon, setMarkerIcon] = useState()
    sport = sport.toLowerCase()

    useEffect(()=>{
        if (type === 'user'){
            size === 'small' && setMarkerIcon(PinUserSmall)
            size === 'medium' && setMarkerIcon(PinUserMedium)
        } else {
            size === 'small' && setMarkerIcon(PinSportSmall)
            size === 'medium' && setMarkerIcon(PinSportMedium)
        }
    }, [])

    return (
        <div className="map_marker--container">
            <img src={markerIcon} alt="" />
            <div className="map_marker--image--center">
            {
                type === 'user' ?
                <Avatar style={{width: '28px'}} label={userName} image={userImg} shape='circle' />
                :
                sport === 'volleyball' ?  <PiVolleyball className='map_marker--sport_icon' /> :
                sport === 'soccer' ? <PiSoccerBall className='map_marker--sport_icon' /> :
                sport === 'football' ? <PiFootball className='map_marker--sport_icon' /> :
                sport === 'baseball' ? <PiBaseball className='map_marker--sport_icon' /> :
                sport === 'basketball' ? <PiBasketball className='map_marker--sport_icon' /> :
                <PiMedal className='map_marker--sport_icon' />
            }
            </div>
        </div>
    )
}

export default MarkerMap