import axios from 'axios'
import { toast } from 'react-toastify'

export const createUserHistory = async (user_access_key, league_access_key, event_access_key, date, position, awards) =>{
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/user_history/create`, 
        {user_access_key, league_access_key, event_access_key, date, position, awards})
        .then((response)=>{
            toast.success(`History created!`)
            return response.data.access_key
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        console.log(error)
        toast.error('An error ocurred.')
    }
}

export const updateUserHistory = async (access_key, user_access_key, league_access_key, event_access_key, date, position, awards) =>{
    try {
        return await axios.put(`${process.env.REACT_APP_API_URL}/api/user_history/update`, 
        {access_key, user_access_key, league_access_key, event_access_key, date, position, awards})
        .then((response)=>{
            toast.success(`History updated!`)
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        console.log(error)
        toast.error('An error ocurred.')
    }
}

export const deleteUserHistory = async (access_key) => {
    if (access_key){
        try {
            return await axios.delete(`${process.env.REACT_APP_API_URL}/api/user_history/delete/${access_key}`)
            .then((response)=>{
                response.data && toast.success('History deleted.')
            }).catch((error)=>{
                toast.error(error.response.data.message)
                return false
            })
        } catch (error) {
            toast.error('An error ocurred.')
        }
    } else {
        return null;
    }
}

export const listUserHistorysByUser = async (user_access_key) => {
    if (user_access_key){
        try {
            return await axios.get(`${process.env.REACT_APP_API_URL}/api/user_history/list_by_user/${user_access_key}`)
            .then((response)=>{
                return response.data
            }).catch((error)=>{
                toast.error(error.response.data.message)
                return false
            })
        } catch (error) {
            toast.error('An error ocurred.')
        }
    } else {
        return null;
    }
}

export const getUserHistoryByAccessKey = async (access_key) => {
    if (access_key){
        try {
            return await axios.get(`${process.env.REACT_APP_API_URL}/api/user_history/get_by_access_key/${access_key}`)
            .then((response)=>{
                return response.data[0]
            }).catch((error)=>{
                toast.error(error.response.data.message)
                return false
            })
        } catch (error) {
            toast.error('An error ocurred.')
        }
    } else {
        return null;
    }
}


