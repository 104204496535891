import { Rating } from 'primereact/rating'
import React, { useState } from 'react'

function RatingComponent({isEditable, value='', access_key='', onChangeRating}) {
    const [rating, setRating] = useState(value)

    const handleOnChange = (e) => {
        onChangeRating(e.value, access_key)
        setRating(e.value)
    }
    return (
        <Rating 
        readOnly={!isEditable}
        onChange={handleOnChange}
        value={rating}
        cancel={false}
        /> 
    )
}

export default RatingComponent