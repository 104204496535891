import React, { useContext, useEffect, useState } from 'react'
import Profile from '../Profile'
import ProfileHistoryItem from './ProfileHistoryItem/ProfileHistoryItem'
import './ProfileHistory.css'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { deleteUserHistory, listUserHistorysByUser } from '../../../../services/ProfileHistoryService'
import AuthContext from '../../../../context/AuthContext/AuthContext'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { PiWarningCircle } from 'react-icons/pi'

function ProfileHistory() {
    const [histories, setHistories] = useState()
    const {userAuth} = useContext(AuthContext)
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)

    const handleAddHistory = () => {
        navigate('/app/profile_history_form')
    }

    useEffect(()=>{
        handleListUserHistory();
    },[])

    const handleListUserHistory = async () => {
        setIsLoading(true)
        await setHistories(await listUserHistorysByUser(userAuth.user.access_key))
        setIsLoading(false)
    }

    const handleDelete = (access_key) => {
        confirmDialog({
            message: `Do you want to delete the history?`,
            header: 'Confirm?',
            icon: <PiWarningCircle />,
            defaultFocus: 'accept',
            accept: () => acceptDelete(access_key),
            reject
        });
    }

    const acceptDelete = async (access_key) => {
        await deleteUserHistory(access_key).then(()=>
        {
            handleListUserHistory()
        })
    }

    const reject = () => {

    }

    return (
        <Profile activeMenuNumber={3}>
            <ConfirmDialog />
            <div className="profile_history--container">
                <div className="profile_history--actions">
                    <Button onClick={handleAddHistory}>+ Add History</Button>
                </div>
                <div className="profile_history--content">
                    {
                        isLoading ? 'Loading...' :
                        histories && histories.map((item, key)=>(
                            <ProfileHistoryItem key={key} data={item} deleteProfileHistory={handleDelete} />
                        ))
                    }
                </div>
            </div>
        </Profile>
    )
}

export default ProfileHistory