import React, { useContext, useEffect, useState } from 'react'
import './Home.css'
import AuthContext from '../../../context/AuthContext/AuthContext'
import EventItemListComponent from '../Event/EventComponent/EventItemListComponent/EventItemListComponent'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'
import { listEvents } from '../../../services/EventService'
import { listUsersByFilters } from '../../../services/UserService'
import PlayerItemListComponent from '../Player/PlayerComponent/PlayerItemListComponent/PlayerItemListComponent'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'

import { TabView, TabPanel } from 'primereact/tabview';
        

function Home() {

    const [isLoadingEvent, setIsLoadingEvent] = useState(false)
    const [isLoadingPlayer, setIsLoadingPlayer] = useState(false)
    const [eventList, setEventList] = useState()
    const [playerList, setPlayerList] = useState()
    const { userAuth } = useContext(AuthContext)
    const navigate = useNavigate()

    useEffect(()=>{
        handleListEvents()
        handleListPlayers()
    },[])

    const handleListPlayers = async () => {
        setIsLoadingPlayer(true)
        const response = await listUsersByFilters(
            '', 
            null,
            {lat: userAuth.user.lat, lng:userAuth.user.lng}, 500, 
            null, null, null, 
            1)
        await setPlayerList(response.data)
        setIsLoadingPlayer(false)
    }

    const handleListEvents = async () => {
        setIsLoadingEvent(true)
        var _date = new Date()
        _date.setDate(_date.getDate() + 30)
        const response = await listEvents(
            '', 
            new Date(), _date, 
            null, 
            {lat: userAuth.user.lat, lng: userAuth.user.lng}, 500, 
            null )
        await setEventList(response.data)
        setIsLoadingEvent(false)

    }
    return (
    <div>
        <AppBodyComponent activeMenuNumber={1}>
            <TabView>
                <TabPanel header='Events near me' className='flex w-12 justify-content-center'>
                    <div className="flex flex-column gap-2">
                        <Button size='small' className='w-max' outlined onClick={()=>navigate('/app/event_list')}>
                            Click here to look for more events.</Button>                    
                        <div className="grid">
                            {isLoadingEvent ? 'Loading events near you...' : 
                            eventList?.length && eventList.map((item, key)=>(
                                <EventItemListComponent key={key} data={item} />
                            ))
                            }
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header='Players near me' className='flex w-12 justify-content-center'>
                    <div className="flex flex-column gap-2">
                        <Button size='small' className='w-max' outlined onClick={()=>navigate('/app/player_list')}>
                            Click here to look for more players.</Button>
                        <div className="grid">
                            {isLoadingPlayer ? 'Loading players near you...' : 
                            playerList?.length && playerList.map((item, key)=>(
                                <PlayerItemListComponent key={key} data={item} />
                            ))}
                        </div>
                    </div>
                </TabPanel>
            </TabView>
        </AppBodyComponent>
    </div>
  )
}

export default Home