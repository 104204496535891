import React, { useState } from 'react'
import Navbar from '../../../components/Navbar/Navbar'
import Footer from '../../../components/Footer/Footer'
import './ForgotPassword.css'
import { useNavigate } from 'react-router-dom'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { forgotPassword } from '../../../services/UserService'

function ForgotPassword() {

    const navigate = useNavigate();
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (e) => {
        setIsLoading(true)
        e.preventDefault();
        await forgotPassword(email).then((response)=>{
            response && navigate('/login')
        })
        setIsLoading(false)
    }

    const handleEmail = (e) => {
        setEmail(e.target.value)
    }


    return (
    <div>
        <Navbar />
            <div className="forgotPassword--container">
                <div className="forgotPassword--content">
                    <div className="forgotPassword--form">
                        <div className="text--heading--h2" style={{paddingBottom: '12px'}}>Forgot Password</div>
                        <form onSubmit={handleSubmit} method="post" className='text--regular--normal'>
                            <div className="form--fieldset">
                                <label htmlFor="email">Email</label>
                                <InputText type="email" name="email" id="email" onChange={handleEmail} />
                            </div>
                            <div className="form--action_row">
                                <Button loading={isLoading} type="submit" label='Send' />
                            </div>
                            <div className="form--action_row">
                                <Button text severity='secondary' onClick={()=>navigate('/login')} type="button" label='Log in' />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        <Footer />
    </div>
  )
}

export default ForgotPassword