import React, { useContext, useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';        
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import StatisticsContext from '../StatisticsContext';

function TableHighlightsStatsComponent({tableStatsData}) {
    const {activePlayersContext} = useContext(StatisticsContext)
    const [statsHighlight, setStatsHighlight] = useState([{
        att: {
            name: "",
            total: "",
            perc: "",
        },
        rec: {
            name: "",
            total: "",
            perc: "",
        },
        ser: {
            name: "",
            total: "",
            perc: "",
        },
        blo: {
            name: "",
            total: "",
            perc: "",
        },
        dig: {
            name: "",
            total: "",
            perc: "",
        },
        set: {
            name: "",
            total: "",
            perc: "",
        },
    }])
    

    useEffect(()=>{
        activePlayersContext && tableStatsData && handleGetPracticeStats()
    },[tableStatsData, activePlayersContext])

    let line = 0
    const handleRowColor = (row, key) => {
        line += 1
        return  line === 1 ? "bg-green-500" : 
                line === 2 ? "bg-green-300" : 
                line === 3 ? "bg-green-100" : 
                line === 4 ? "bg-red-100" : 
                line === 5 ? "bg-red-300" : 
                line >= 6 ? "bg-red-500" : ''
        
    }

    const handleGetPracticeStats = () => {
        var atts = [...tableStatsData.filter(e=>activePlayersContext.findIndex(i => e.id === i) >= 0).sort((a,b)=>Number(b.att_perc) - Number(a.att_perc))]
        const firstsQtd = atts.length >=3 ? 3 : atts.length
        const lastsQTD = atts.length >=6 ? -3 : 3 - atts.length

        atts = atts.length >3 ? [...atts.slice(0,firstsQtd), ...atts.slice(lastsQTD)] : [...atts.slice(0,firstsQtd)]
        var recs = [...tableStatsData.filter(e=>activePlayersContext.findIndex(i => e.id === i) >= 0).sort((a,b)=>Number(b.rec_perc) - Number(a.rec_perc))]
        recs = atts.length >3 ? [...recs.slice(0,firstsQtd), ...recs.slice(lastsQTD)] : [...recs.slice(0,firstsQtd)]
        var sers = [...tableStatsData.filter(e=>activePlayersContext.findIndex(i => e.id === i) >= 0).sort((a,b)=>Number(b.ser_perc) - Number(a.ser_perc))]
        sers = atts.length >3 ? [...sers.slice(0,firstsQtd), ...sers.slice(lastsQTD)] : [...sers.slice(0,firstsQtd)]
        var blos = [...tableStatsData.filter(e=>activePlayersContext.findIndex(i => e.id === i) >= 0).sort((a,b)=>Number(b.blo_total) - Number(a.blo_total))]
        blos = atts.length >3 ? [...blos.slice(0,firstsQtd), ...blos.slice(lastsQTD)] : [...blos.slice(0,firstsQtd)]
        var digs = [...tableStatsData.filter(e=>activePlayersContext.findIndex(i => e.id === i) >= 0).sort((a,b)=>Number(b.dig_total) - Number(a.dig_total))]
        digs = atts.length >3 ? [...digs.slice(0,firstsQtd), ...digs.slice(lastsQTD)] : [...digs.slice(0,firstsQtd)]
        var sets = [...tableStatsData.filter(e=>activePlayersContext.findIndex(i => e.id === i) >= 0).sort((a,b)=>Number(b.set_total) - Number(a.set_total))]
        sets = atts.length >3 ? [...sets.slice(0,firstsQtd), ...sets.slice(lastsQTD)] : [...sets.slice(0,firstsQtd)]
        var _statsHighlight = []
        for (let index = 0; index < atts.length; index++) {
            
            const att = atts[index]
            const rec = recs[index]
            const ser = sers[index]
            const blo = blos[index]
            const dig = digs[index]
            const set = sets[index]
            _statsHighlight.push(
                {
                    att: {
                        number: att?.player_number,
                        name: att?.player_name,
                        total: att.att_total,
                        perc: att?.att_perc,
                    },
                    rec: {
                        number: rec?.player_number,
                        name: rec?.player_name,
                        total: rec.rec_total,
                        perc: rec?.rec_perc,
                    },
                    ser: {
                        number: ser?.player_number,
                        name: ser?.player_name,
                        total: ser.ser_total,
                        perc: ser?.ser_perc,
                    },
                    blo: {
                        number: blo?.player_number,
                        name: blo?.player_name,
                        total: blo?.blo_total,
                        perc: "-",
                    },
                    dig: {
                        number: dig?.player_number,
                        name: dig?.player_name,
                        total: dig?.dig_total,
                        perc: "-",
                    },
                    set: {
                        number: set?.player_number,
                        name: set?.player_name,
                        total: set?.set_total,
                        perc: "-",
                    },
                }
            )
            
        }

        setStatsHighlight(_statsHighlight)
    }

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column headerClassName='p-1 border-left-3 border-top-3 border-right-1 border-gray-900' header="ATT" align={'center'} colSpan={3} />
                <Column headerClassName='p-1 border-left-3 border-top-3 border-right-1 border-gray-900' header="REC" align={'center'} colSpan={3} />
                <Column headerClassName='p-1 border-left-3 border-top-3 border-right-1 border-gray-900' header="SER" align={'center'} colSpan={3} />
                <Column headerClassName='p-1 border-left-3 border-top-3 border-right-1 border-gray-900' header="DIG" align={'center'} colSpan={2} />
                <Column headerClassName='p-1 border-left-3 border-top-3 border-right-1 border-gray-900' header="BLO" align={'center'} colSpan={2} />
                <Column headerClassName='p-1 border-left-3 border-top-3 border-right-3 border-gray-900' header="SET" align={'center'} colSpan={2} />
            </Row>
            <Row>
                <Column headerClassName='border-left-3 border-right-1 border-gray-900' align={'center'} header="Player" />
                <Column headerClassName='border-right-1 border-gray-900' align={'center'} header="Tot" />
                <Column headerClassName='border-right-1 border-gray-900' align={'center'} header="%" />
                
                <Column headerClassName='border-left-3 border-right-1 border-gray-900' align={'center'} header="Player" />
                <Column headerClassName='border-right-1 border-gray-900' align={'center'} header="Tot" />
                <Column headerClassName='border-right-1 border-gray-900' align={'center'} header="%" />
                
                <Column headerClassName='border-left-3 border-right-1  border-gray-900' align={'center'} header="Player" />
                <Column headerClassName='border-right-1 border-gray-900' align={'center'} header="Tot" />
                <Column headerClassName='border-right-1 border-gray-900' align={'center'} header="%" />
                
                <Column headerClassName='border-left-3 border-right-1 border-gray-900' align={'center'} header="Player" />
                <Column headerClassName='border-right-1 border-gray-900' align={'center'} header="Tot" />
                
                <Column headerClassName='border-left-3 border-right-1 border-gray-900' align={'center'} header="Player" />
                <Column headerClassName='border-right-1 border-gray-900' align={'center'} header="Tot" />
                
                <Column headerClassName='border-left-3 border-right-1 border-gray-900' align={'center'} header="Player" />
                <Column headerClassName='border-right-3 border-gray-900' align={'center'} header="Tot" />
            </Row>
        </ColumnGroup>
    );

    return (
        <div className='flex flex-column gap-2'>
            <DataTable rowClassName={handleRowColor} showGridlines stripedRows value={statsHighlight} headerColumnGroup={headerGroup} tableStyle={{ minWidth: '10rem' }}>
                <Column footerClassName='border-bottom-3' className='text-gray-900 p-1 border-left-3 border-right-1 p-1 border-gray-900' align={'left'} field={(item)=><div className='font-bold w-5rem lg:w-10rem white-space-nowrap overflow-hidden text-overflow-ellipsis'>{`${item.att.number} ${item.att.name}`}</div>} />
                <Column footerClassName='border-bottom-3' className='text-gray-900 p-1 border-right-1 p-1 border-gray-900' align={'right'} field="att.total" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 p-1 border-right-1 p-1 border-gray-900' align={'right'} field="att.perc" />
                
                <Column footerClassName='border-bottom-3' className='text-gray-900 p-1 border-left-3 border-right-1 p-1 border-gray-900' align={'left'} field={(item)=><div className='font-bold w-5rem lg:w-10rem white-space-nowrap overflow-hidden text-overflow-ellipsis'>{`${item.rec.number} ${item.rec.name}`}</div>} />
                <Column footerClassName='border-bottom-3' className='text-gray-900 p-1 border-right-1 p-1 border-gray-900' align={'right'} field="rec.total" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 p-1 border-right-1 p-1 border-gray-900' align={'right'} field="rec.perc" />
                
                <Column footerClassName='border-bottom-3' className='text-gray-900 p-1 border-left-3 border-right-1 p-1 border-gray-900' align={'left'} field={(item)=><div className='font-bold w-5rem lg:w-10rem white-space-nowrap overflow-hidden text-overflow-ellipsis'>{`${item.ser.number} ${item.ser.name}`}</div>} />
                <Column footerClassName='border-bottom-3' className='text-gray-900 p-1 border-right-1 p-1 border-gray-900' align={'right'} field="ser.total" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 p-1 border-right-1 p-1 border-gray-900' align={'right'} field="ser.perc" />
                
                <Column footerClassName='border-bottom-3' className='text-gray-900 p-1 border-left-3 border-right-1 p-1 border-gray-900' align={'left'} field={(item)=><div className='font-bold w-5rem lg:w-10rem white-space-nowrap overflow-hidden text-overflow-ellipsis'>{`${item.dig.number} ${item.dig.name}`}</div>} />
                <Column footerClassName='border-bottom-3' className='text-gray-900 p-1 border-right-1 p-1 border-gray-900' align={'right'} field="dig.total" />
                
                <Column footerClassName='border-bottom-3' className='text-gray-900 p-1 border-left-3 border-right-1 p-1 border-gray-900' align={'left'} field={(item)=><div className='font-bold w-5rem lg:w-10rem white-space-nowrap overflow-hidden text-overflow-ellipsis'>{`${item.blo.number} ${item.blo.name}`}</div>} />
                <Column footerClassName='border-bottom-3' className='text-gray-900 p-1 border-right-1 p-1 border-gray-900' align={'right'} field="blo.total" />
                
                <Column footerClassName='border-bottom-3' className='text-gray-900 p-1 border-left-3 border-right-1 p-1 border-gray-900' align={'left'} field={(item)=><div className='font-bold w-5rem lg:w-10rem white-space-nowrap overflow-hidden text-overflow-ellipsis'>{`${item.dig.number} ${item.dig.name}`}</div>} />
                <Column footerClassName='border-bottom-3' className='text-gray-900 p-1 border-right-3 p-1 border-gray-900' align={'right'} field="set.total" />


            </DataTable>
        </div>
    )
}

export default TableHighlightsStatsComponent