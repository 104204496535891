import React, { useEffect, useState } from 'react'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'
import { Card } from 'primereact/card';
import { Avatar } from 'primereact/avatar';
import LeagueMediasList from './LeagueMediasList';
import { useNavigate, useParams } from 'react-router-dom';
import { TabPanel, TabView } from 'primereact/tabview';
import { getDetailsByAccessKey } from '../../../services/LeagueService';
import { PiArrowSquareOut, PiCalendar, PiCalendarBlank, PiCaretLeft, PiEnvelope, PiGlobe, PiTrophy, PiYoutubeLogo } from 'react-icons/pi';
import LeagueEventsList from './LeagueEventsList';
import { Button } from 'primereact/button';

function LeagueDetail() {
    const {access_key} = useParams()
    const [league, setLeague] = useState()
    const navigate = useNavigate()

    useEffect(()=>{ 
        handleGetLeagueDetails()
    },[access_key])

    const handleGetLeagueDetails = () => {
        access_key && getDetailsByAccessKey(access_key).then((response)=>{
            setLeague(response)
        })
    }

    return (
        <AppBodyComponent activeMenuNumber={2}>
            <div className='flex flex-column gap-4'>
                <div className="event_detail--actions">
                    <Button onClick={()=>navigate(-1)} outlined size='small'><PiCaretLeft /></Button>
                </div>
                <div className='flex flex-column align-items-center flex-wrap gap-4' >
                    <Card className='bg-blue-900 text-white w-full'>
                    <div className='flex flex-column align-items-center flex-wrap gap-4' >
                        <div className='font-bold text-2xl'>
                            {league?.name}
                        </div>
                        <div className='flex flex-column gap-4 align-items-center flex-wrap'>
                            {league?.url ? <a className='flex flex-row gap-1 align-items-center' href={league?.url} target='_blank' rel='noreferrer' ><PiGlobe /> {league?.url} <PiArrowSquareOut size={12} /> </a> : <label><PiGlobe /> -</label>}
                            <div className='flex flex-row gap-1 align-items-center'><PiEnvelope /> {league?.email}</div>
                        </div>
                        <div className='flex flex-row gap-4 align-content-center'>
                            <div className='flex flex-row gap-1 align-items-center'><PiTrophy /> {league?.events} events</div>
                            <div className='flex flex-row gap-1 align-items-center'><PiYoutubeLogo /> {league?.medias} videos</div>
                        </div>
                    </div>
                    </Card>
                    <TabView className='w-full'>
                        <TabPanel header='Events' headerClassName='flex w-full justify-content-center' className='p-0'>
                            <LeagueEventsList access_key={access_key} />
                        </TabPanel>                    
                        
                        <TabPanel header='Videos' headerClassName={'flex w-full justify-content-center'}>
                            <LeagueMediasList access_key={access_key} />
                        </TabPanel>                    
                    </TabView>
                </div>
            </div>
        </AppBodyComponent>
    )
}

export default LeagueDetail