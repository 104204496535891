import axios from 'axios'
import { toast } from 'react-toastify'

export const createUserMedia = async (user_access_key, league_access_key, event_access_key, date, title, url) =>{
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/user_media/create`, 
        {user_access_key, league_access_key, event_access_key, date, title, url})
        .then((response)=>{
            toast.success(`Media created!`)
            return response.data.access_key
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        console.log(error)
        toast.error('An error ocurred.')
    }
}

export const updateUserMedia = async (access_key, user_access_key, league_access_key, event_access_key, date, title, url) =>{
    try {
        return await axios.put(`${process.env.REACT_APP_API_URL}/api/user_media/update`, 
        {access_key, user_access_key, league_access_key, event_access_key, date, title, url})
        .then((response)=>{
            toast.success(`Media updated!`)
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        console.log(error)
        toast.error('An error ocurred.')
    }
}

export const deleteUserMedia = async (access_key) => {
    if (access_key){
        try {
            return await axios.delete(`${process.env.REACT_APP_API_URL}/api/user_media/delete/${access_key}`)
            .then((response)=>{
                response.data && toast.success('Media deleted.')
            }).catch((error)=>{
                toast.error(error.response.data.message)
                return false
            })
        } catch (error) {
            toast.error('An error ocurred.')
        }
    } else {
        return null;
    }
}

export const listUserMediasByUser = async (user_access_key) => {
    if (user_access_key){
        try {
            return await axios.get(`${process.env.REACT_APP_API_URL}/api/user_media/list_by_user/${user_access_key}`)
            .then((response)=>{
                return response.data
            }).catch((error)=>{
                toast.error(error.response.data.message)
                return false
            })
        } catch (error) {
            toast.error('An error ocurred.')
        }
    } else {
        return null;
    }
}

export const getUserMediaByAccessKey = async (access_key) => {
    if (access_key){
        try {
            return await axios.get(`${process.env.REACT_APP_API_URL}/api/user_media/get_by_access_key/${access_key}`)
            .then((response)=>{
                return response.data[0]
            }).catch((error)=>{
                toast.error(error.response.data.message)
                return false
            })
        } catch (error) {
            toast.error('An error ocurred.')
        }
    } else {
        return null;
    }
}


