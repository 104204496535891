import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import React, { useContext, useEffect, useState } from 'react'
import StatisticsContext from '../StatisticsContext';
import { qualitiesFive, qualitiesFour, qualitiesThree } from '../Utils/QualitiesUtils';

function RallyItemComponent({rallyItem, index}) {
    const {playersA, gridData, setCurrentRallyContext, currentRallyContext} = useContext(StatisticsContext)
    const [color, setColor] = useState('')
    const [visible, setVisible] = useState(false)
    const [selectedAction, setSelectedAction] = useState({action: rallyItem.action, action_number: rallyItem.action_number})
    const [selectedPlayer, setSelectedPlayer] = useState(rallyItem.player)
    const [selectedPositionPlayer, setSelectedPositionPlayer] = useState(rallyItem.position)
    const [selectedPositionAction, setSelectedPositionAction] = useState(rallyItem.position_action)
    const [selectedQuality, setSelectedQuality] = useState({quality: rallyItem.quality, quality_value: rallyItem.quality_value})
    const [selectedRotation, setSelectedRotation] = useState(rallyItem.rotation)

    const actions = [
        {
            action: 1,
            action_number: "ser"
        },
        {
            action: 2,
            action_number: "rec"
        },
        {
            action: 3,
            action_number: "set"
        },
        {
            action: 4,
            action_number: "att"
        },
        {
            action: 5,
            action_number: "blo"
        },
        {
            action: 6,
            action_number: "dig"
        },
    ]

    const qualities = [
        {
            quality: 5,
            quality_value: 4
        },
        {
            quality: 4,
            quality_value: 3
        },
        {
            quality: 3,
            quality_value: 2
        },
        {
            quality: 2,
            quality_value: 1
        },
        {
            quality: 1,
            quality_value: 0
        },
    ]

    const rotations = [1,2,3,4,5,6]

    const positions = [1,2,3,4,5,6]

    useEffect(()=>{
        handleSetColor();
    },[rallyItem])


    const handleSetColor = () => {
        if (rallyItem.action === 1){ //ser
            if (rallyItem.quality === 5) setColor(qualitiesFive.four.color) //green
            if (rallyItem.quality === 4) setColor(qualitiesFive.three.color) //light green
            if (rallyItem.quality === 3) setColor(qualitiesFive.two.color) //yellow
            if (rallyItem.quality === 2) setColor(qualitiesFive.one.color) //orange
            if (rallyItem.quality === 1) setColor(qualitiesFive.zero.color) //red
        }
        if (rallyItem.action === 2){ //rec
            if (rallyItem.quality === 4) setColor(qualitiesFour.three.color) //green
            if (rallyItem.quality === 3) setColor(qualitiesFour.two.color) //light green
            if (rallyItem.quality === 2) setColor(qualitiesFour.one.color) //orange
            if (rallyItem.quality === 1) setColor(qualitiesFour.zero.color) //red
        }
        if (rallyItem.action >= 3){ //set, att, blo, dig
            if (rallyItem.quality === 3) setColor(qualitiesThree.two.color) //green
            if (rallyItem.quality === 2) setColor(qualitiesThree.one.color) //yellow
            if (rallyItem.quality === 1) setColor(qualitiesThree.zero.color) //red
        }
    }

    const footer = () => {
        return (
            <div>
                <Button onClick={handleCancel}>Close</Button>
            </div>
        )
    }

    const removeAction = () => {
        var rally = [...currentRallyContext]
        rally = rally.filter((item, key)=> key !== index)
        setCurrentRallyContext(rally)
        setVisible(false)
    }

    const handleSave = () => {
        var rally = [...currentRallyContext]
        rally[index] = {
            ...rally[index],
            player_id: selectedPlayer?.player.player_id,
            playerName: selectedPlayer?.number, 
            action_number: selectedAction?.action_number, 
            action: selectedAction?.action, 
            position_action: selectedPositionAction && selectedPositionAction, 
            quality_value: selectedQuality?.quality_value, 
            quality: selectedQuality?.quality, 
            rotation: selectedRotation && selectedRotation, 
            position: selectedPositionPlayer && selectedPositionPlayer,
        }
        setCurrentRallyContext(rally)  
        setVisible(false)      
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const markPoint = () =>{
        setSelectedQuality({quality: 3, quality_value: 2})
    }

    const markError = () =>{
        setSelectedQuality({quality: 1, quality_value: 0})
    }

    useEffect(() => {
        handleSave()
    }, [selectedAction])
    
    useEffect(() => {
        handleSave()
    }, [selectedPlayer])
    
    useEffect(() => {
        handleSave()
    }, [selectedPositionAction])
    
    useEffect(() => {
        handleSave()
    }, [selectedPositionPlayer])
    
    useEffect(() => {
        handleSave()
    }, [selectedRotation])
    
    useEffect(() => {
        handleSave()
    }, [selectedQuality])
    
    
    
    
    

    return (
        <div>
            <Dialog header={`Edit Action ${index+1}`} visible={visible} onHide={()=>setVisible(false)} footer={footer}>
                <div className='flex flex-wrap gap-4 align-items-end'>
                    {gridData?.statisticsType !== 'player' && 
                        <div className='flex flex-column'>
                            <label htmlFor='player'>Player</label>
                            <Dropdown onChange={(e)=>setSelectedPlayer(e.value)} name="player" 
                            options={playersA} value={selectedPlayer} optionLabel='number' />
                        </div>
                    }
                    <div className='flex flex-column'>
                        <label htmlFor='action'>Action</label>
                        <Dropdown onChange={(e)=>setSelectedAction(e.value)} name="action" 
                        options={actions} value={selectedAction} optionLabel='action_number' />
                    </div>
                    <div className='flex flex-column'>
                        <label htmlFor='quality'>Quality</label>
                        <Dropdown onChange={(e)=>setSelectedQuality(e.value)} name="quality" 
                        options={qualities} value={selectedQuality} optionLabel='quality_value' />
                    </div>
                    <div className='flex flex-column'>
                        <label htmlFor='rotation'>Rotation</label>
                        <Dropdown onChange={(e)=>setSelectedRotation(e.value)} name="rotation" 
                        options={rotations} value={selectedRotation} />
                    </div>
                    <div className='flex flex-column'>
                        <label htmlFor='positionAction'>Action Pos</label>
                        <Dropdown onChange={(e)=>setSelectedPositionAction(e.value)} name="positionAction" 
                        options={positions} value={selectedPositionAction} />
                    </div>
                    <div className='flex flex-column'>
                        <label htmlFor='positionPlayer'>Player Pos</label>
                        <Dropdown onChange={(e)=>setSelectedPositionPlayer(e.value)} name="positionPlayer" 
                        options={positions} value={selectedPositionPlayer} />
                    </div>
                    <div className='flex flex-column'>
                        <Button onClick={removeAction} label='Remove' severity='danger' />
                    </div>
                </div>
            </Dialog>
            <div className='flex flex-column gap-1 mt-2'>
                {selectedAction?.action_number !== "ser" && selectedAction?.action_number !== "rec" ? 
                <div className={`${selectedQuality?.quality_value === 2 && 'bg-green-500'} 
                flex justify-content-center align-items-center h-3rem border-round border-green-500 border-1 cursor-pointer`}
                onClick={markPoint}>Point</div> : <div className='h-3rem'></div>}
                
                <div style={{backgroundColor: color, userSelect: 'none', WebkitUserSelect: 'none'}} className='border-round' onClick={()=>setVisible(true)}>
                    <div className="flex justify-content-center align-items-center text-s p-2 text-sm h-3rem font-bold">{index+1}: {gridData?.statisticsType !== 'player' && rallyItem?.playerName} {rallyItem?.action_number}</div>
                </div>
                
                {selectedAction?.action_number !== "ser" && selectedAction?.action_number !== "rec" ? 
                <div className={`${selectedQuality?.quality_value === 0 && 'bg-red-500'} 
                flex justify-content-center align-items-center h-3rem border-round border-red-500 border-1 cursor-pointer`}
                onClick={markError}>Error</div> : <div className='h-3rem'></div>}
            </div>
        </div>
    )
}

export default RallyItemComponent