import React, { useContext, useEffect, useState } from 'react'
import StatisticsContext from './StatisticsContext';
import FullStatisticsComponent from './Components/FullStatisticsComponent';
import PlayerStatisticsComponent from './Components/PlayerStatisticsComponent';
import GameStatisticsComponent from './Components/GameStatisticsComponent';
import { useParams } from 'react-router-dom';
import { loadMatchDataStatsAnalysis } from '../../../services/StatsService';
import PracticeStatisticsComponent from './Components/PracticeStatisticsComponent';
import GameGridStatisticsComponent from './Components/GameGridStatisticsComponent';
        

function Statistics() {

    const {gridData, setGridData} = useContext(StatisticsContext)
    const {access_key} = useParams()

    useEffect(()=>{
        access_key && loadMatchDataStatsAnalysis(access_key).then((res)=>{
            setGridData(res)
        })
    },[access_key])

    return (
        <div className='m-2' style={{
            WebkitUserSelect: 'none', /* Safari */        
            MozUserSelect: 'none', /* Firefox */
            msUserSelect: 'none', /* IE10+/Edge */
            userSelect: 'none', /* Standard */}}>
            {
                gridData ?
                gridData?.statisticsType === 'full' ? 
                <FullStatisticsComponent />
                : gridData?.statisticsType === 'practice' ? 
                <PracticeStatisticsComponent />
                : gridData?.statisticsType === 'player' ? 
                <PlayerStatisticsComponent />
                : gridData?.statisticsType === 'game' &&
                <GameGridStatisticsComponent /> : 
                <div>Loading ...</div>
            }        
        </div>
    )
}

export default Statistics