import React from 'react'
import Navbar from '../../../components/Navbar/Navbar'
import Footer from '../../../components/Footer/Footer'
import './PrivacyPolice.css'
import { useNavigate } from 'react-router-dom'

function PrivacyPolice() {

    const navigate = useNavigate();

  return (
    <div>
        <Navbar />
            <div className="privacyPolice--container">
                <div className="privacyPolice--content">
                    <div className="privacyPolice--form">
                        <div className="text--heading--h2" style={{paddingBottom: '12px'}}>Privacy Police</div>
                        <p className='c6 doc-content'>
                            <p className="c2">
                                <span className="c1">Privacy Policy for Leagr</span>
                            </p>
                            <p className="c2">
                                <span className="c1">Last updated: 11/21/2023</span>
                            </p>
                            <p className="c2">
                                <span className="c1">Introduction</span>
                            </p>
                            <p className="c2">
                                <span className="c1">Welcome to Leagr (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;). 
                                This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use our web app.
                                </span>
                            </p>
                            <p className="c2">
                                <span className="c1">Information We Collect</span>
                            </p>
                            <ul className="c3 lst-kix_w8115oe94vz0-0 start">
                                <li className="c0 li-bullet-0">
                                    <span className="c1">User Authentication: When you log in to Leagr using Google OAuth, we collect and store the information provided by Google, 
                                        including your name and email address.
                                    </span>
                                </li>
                                <li className="c0 li-bullet-0">
                                    <span className="c1">Usage Information: We may collect information about how you interact with our web app, such as pages visited, features used, 
                                    and other usage statistics.
                                    </span>
                                </li>
                            </ul>
                            <p className="c2">
                                <span className="c1">How We Use Your Information</span>
                            </p>
                            <p className="c2">
                                <span className="c1">We use the information collected for the following purposes:</span>
                            </p>
                            <ul className="c3 lst-kix_lwiih5pybr6a-0 start">
                                <li className="c0 li-bullet-0">
                                    <span className="c1">User Authentication: To authenticate and identify you when you log in to our web app using Google OAuth.</span>
                                </li>
                                <li className="c0 li-bullet-0">
                                    <span className="c1">Personalization: To provide personalized content and improve your experience on our web app.</span>
                                </li>
                                <li className="c0 li-bullet-0">
                                    <span className="c1">Analytics: To analyze usage patterns and improve our web app&#39;s functionality and user experience.</span>
                                </li>
                            </ul>
                            <p className="c2">
                                <span className="c1">Information Sharing</span>
                            </p>
                            <p className="c2">
                                <span className="c1">We do not sell, trade, or otherwise transfer your personal information to third parties. However, 
                                we may share aggregated and anonymized information for statistical analysis and to improve our services.
                                </span>
                            </p>
                            <p className="c2">
                                <span className="c1">Data Security</span>
                            </p>
                            <p className="c2">
                                <span className="c1">We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, 
                                and destruction.</span>
                            </p>
                            <p className="c2">
                                <span className="c1">Your Choices</span>
                            </p>
                            <p className="c2">
                                <span className="c1">You have the following choices regarding your personal information:</span>
                            </p>
                            <ul className="c3 lst-kix_e6s0nu96au8g-0 start">
                                <li className="c0 li-bullet-0">
                                    <span className="c1">Access: You can access and update your personal information through your account settings.</span>
                                </li>
                                <li className="c0 li-bullet-0">
                                    <span className="c1">Opt-Out: You can opt-out of non-essential communications by following the instructions provided in our communications.</span>
                                </li>
                            </ul>
                            <p className="c2">
                                <span className="c1">Cookies and Tracking Technologies</span>
                            </p>
                            <p className="c2">
                                <span className="c1">We use cookies and similar tracking technologies to enhance your experience on our web app. You can control cookies through your 
                                browser settings.
                                </span>
                            </p>
                            <p className="c2">
                                <span className="c1">Changes to This Privacy Policy</span>
                            </p>
                            <p className="c2">
                                <span className="c1">We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. 
                                We encourage you to review this Privacy Policy periodically.
                                </span>
                            </p>
                            <p className="c2">
                                <span className="c1">Contact Us</span>
                            </p>
                            <p className="c2">
                                <span className="c1">If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at info@leagr.net.</span>
                            </p>
                            <p className="c5">
                                <span className="c4"></span>
                            </p>
                        </p>
                    </div>
                </div>
            </div>
        <Footer />
    </div>
  )
}

export default PrivacyPolice