import React from 'react'
import CourtLayoutItemComponent from './CourtLayoutItemComponent'

function CourtLayoutComponent({menuButtons, court, courtPositions}) {
    return (
        <div className={`flex flex-column gap-2 w-full`}>
            <div className='flex gap-2'>
                {
                    court && courtPositions?.slice(0, courtPositions.length / 2).map((item, key)=>{
                        const index = court.findIndex((e)=>Number(e.courtPosition) === Number(item.number))
                        return (
                            <CourtLayoutItemComponent key={key} item={index >= 0 ? court[index] : null} />)
                        })
                }
            </div>
            <div className='flex gap-2'>
                {
                    court && courtPositions?.slice(courtPositions.length / 2, courtPositions.length).map((item, key)=>{
                        const index = court.findIndex((e)=>Number(e.courtPosition) === Number(item.number))
                        return (
                            <CourtLayoutItemComponent key={key} item={index >= 0 ? court[index] : null} />)
                        })
                }
            </div>
            {menuButtons}
        </div>
    )
}

export default CourtLayoutComponent