import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React, { useContext, useState } from 'react'
import { sendEmailEvents } from '../../../services/EventService'
import AuthContext from '../../../context/AuthContext/AuthContext'
import { Card } from 'primereact/card'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'

function AdminArea() {

    const {userAuth} = useContext(AuthContext)

    const [zipcodeStart, setZipCodeStart] = useState('')
    const [zipcodeEnd, setZipCodeEnd] = useState('')
    const [sendEmailLoading, setSendEmailLoading] = useState(false)

    const handleSendEmailEvents = async () => {
        setSendEmailLoading(true)
        await sendEmailEvents(userAuth.user.access_key, zipcodeStart, zipcodeEnd)
        setSendEmailLoading(false)
    }

    return (
        <AppBodyComponent activeMenuNumber={1}>
            <Card>
                <div className='flex gap-2'>
                    <InputText name='zipcodeStart' placeholder='Zipcode Start' value={zipcodeStart} onChange={(e)=>setZipCodeStart(e.target.value)} />
                    <InputText name='zipcodeEnd' placeholder='Zipcode End' value={zipcodeEnd} onChange={(e)=>setZipCodeEnd(e.target.value)} />
                    <Button label='Send Email Events' onClick={handleSendEmailEvents} loading={sendEmailLoading} />
                </div>
            </Card>
        </AppBodyComponent>
    )
}

export default AdminArea