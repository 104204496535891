import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthContext/AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "primereact/resources/themes/lara-light-pink/theme.css";
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactGA from 'react-ga4'

const root = ReactDOM.createRoot(document.getElementById('root'));
ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TRACKING_ID)

root.render(
  //it is commented to rich text area works properli
  // <React.StrictMode>
  <ErrorBoundary>
    <AuthProvider>
      <ToastContainer position='bottom-right' pauseOnFocusLoss={false} closeButton={false} theme='colored' autoClose={3000} />
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH}>
        <App />
      </GoogleOAuthProvider>
    </AuthProvider>
  </ErrorBoundary>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
