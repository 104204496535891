import React from 'react'
import { PiBaseball, PiBasketball, PiFootball, PiMedal, PiSoccerBall, PiVolleyball } from 'react-icons/pi'
import './SportsIconComponent.css'

function SportsIconComponent({size=12, sport='volleyball'}) {
    sport = sport.toLowerCase()
    
    return (
        sport === 'volleyball' ?  <PiVolleyball size={size} className='sports_icon--sport_icon' /> :
        sport === 'soccer' ? <PiSoccerBall size={size} className='sports_icon--sport_icon' /> :
        sport === 'football' ? <PiFootball size={size} className='sports_icon--sport_icon' /> :
        sport === 'baseball' ? <PiBaseball size={size} className='sports_icon--sport_icon' /> :
        sport === 'basketball' ? <PiBasketball size={size} className='sports_icon--sport_icon' /> :
        <PiMedal size={size} className='sports_icon--sport_icon' />
    )
}

export default SportsIconComponent