import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

import HomeLandingPage from './pages/landing_pages/HomeLandingPage/HomeLandingPage'
import Login from './pages/landing_pages/Login/Login';
import Register from './pages/landing_pages/Register/Register';
import Home from './pages/app_pages/Home/Home';
import Profile from './pages/app_pages/Profile/Profile';
import ProfileForm from './pages/app_pages/Profile/ProfileForm/ProfileForm';
import ProfileSkill from './pages/app_pages/Profile/ProfileSkill/ProfileSkill';
import ProfileSkillForm from './pages/app_pages/Profile/ProfileSkill/ProfileSkillForm';
import ProfileHistory from './pages/app_pages/Profile/ProfileHistory/ProfileHistory';
import ProfileHistoryForm from './pages/app_pages/Profile/ProfileHistory/ProfileHistoryForm';
import ProfileMediaForm from './pages/app_pages/Profile/ProfileMedia/ProfileMediaForm';
import ProfileMedia from './pages/app_pages/Profile/ProfileMedia/ProfileMedia';
import EventList from './pages/app_pages/Event/EventList/EventList';
import EventForm from './pages/app_pages/Event/EventForm/EventForm';
import EventDetail from './pages/app_pages/Event/EventDetail/EventDetail';
import PlayerList from './pages/app_pages/Player/PlayerList/PlayerList';
import PlayerDetail from './pages/app_pages/Player/PlayerDetail/PlayerDetail';
import PlayerDetailSkill from './pages/app_pages/Player/PlayerDetail/PlayerDetailSkill/PlayerDetailSkill';
import PlayerDetailHistory from './pages/app_pages/Player/PlayerDetail/PlayerDetailHistory/PlayerDetailHistory';
import PlayerDetailMedia from './pages/app_pages/Player/PlayerDetail/PlayerDetailMedia/PlayerDetailMedia';
import PlayerDetailAbout from './pages/app_pages/Player/PlayerDetail/PlayerDetailAbout/PlayerDetailAbout';
import ChatList from './pages/app_pages/Chat/ChatList';
import ChatDetail from './pages/app_pages/Chat/ChatDetail';
import Setting from './pages/app_pages/Setting/Setting';
import ForgotPassword from './pages/landing_pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/landing_pages/ResetPassword/ResetPassword';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import PrivacyPolice from './pages/landing_pages/PrivacyPolice/PrivacyPolice';
import Welcome from './pages/app_pages/Welcome/Welcome';
import LeagueDetail from './pages/app_pages/League/LeagueDetail';
import EmailUnsubscribe from './pages/landing_pages/EmailUnsubscribe/EmailUnsubscribe';
import AdminArea from './pages/app_pages/AdminArea/AdminArea';
import { HelmetProvider } from 'react-helmet-async';
import Statistics from './pages/app_pages/Statistics/Statistics';
import StartStats from './pages/app_pages/Statistics/StartStats';
import { StatisticsProvider } from './pages/app_pages/Statistics/StatisticsContext';
import MyStatsHome from './pages/app_pages/Statistics/MyStatsHome';
import ShowPlayerStats from './pages/app_pages/Statistics/ShowPlayerStats';
import ShowGameStats from './pages/app_pages/Statistics/ShowGameStats';
import ShowPracticeStats from './pages/app_pages/Statistics/ShowPracticeStats';
import ShowTeamStats from './pages/app_pages/Statistics/ShowTeamStats';

function App() {
  const helmetContext = {}

  return (
    <HelmetProvider context={helmetContext}>

      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<HomeLandingPage />} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/forgot_password' element={<ForgotPassword />} />
          <Route exact path='/reset_password/:code' element={<ResetPassword />} />
          <Route exact path='/register' element={<Register />} />
          <Route exact path='/privacy_police' element={<PrivacyPolice />} />
          <Route exact path='/email_unsubscribe/:code' element={<EmailUnsubscribe />} />
          
          <Route exact path='/app/admin_area' element={<ProtectedRoute><AdminArea /></ProtectedRoute>} />
          <Route exact path='/app/home' element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route exact path='/app/welcome' element={<ProtectedRoute><Welcome /></ProtectedRoute>} />

          <Route exact path='/app/event_list' element={<ProtectedRoute><EventList /></ProtectedRoute>} />
          <Route exact path='/app/event_form' element={<ProtectedRoute><EventForm /></ProtectedRoute>} />
          <Route exact path='/app/event_form/:access_key' element={<ProtectedRoute><EventForm /></ProtectedRoute>} />
          <Route exact path='/app/event_detail/:access_key' element={<ProtectedRoute><EventDetail /></ProtectedRoute>} />

          <Route exact path='/:access_key' element={<PlayerDetailAbout />} />
          <Route exact path='/app/player_list' element={<ProtectedRoute><PlayerList /></ProtectedRoute>} />
          <Route exact path='/app/player_detail_about/:access_key' element={<PlayerDetailAbout />} />
          <Route exact path='/app/player_detail_history/:access_key' element={<PlayerDetailHistory />} />
          <Route exact path='/app/player_detail_media/:access_key' element={<PlayerDetailMedia />} />
          <Route exact path='/app/player_detail_skill/:access_key' element={<PlayerDetailSkill />} />

          <Route exact path='/app/profile_view' element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route exact path='/app/profile_form' element={<ProtectedRoute><ProfileForm /></ProtectedRoute>} />
          <Route exact path='/app/profile_skill' element={<ProtectedRoute><ProfileSkill /></ProtectedRoute>} />
          <Route exact path='/app/profile_skill_form' element={<ProtectedRoute><ProfileSkillForm /></ProtectedRoute>} />
          <Route exact path='/app/profile_history' element={<ProtectedRoute><ProfileHistory /></ProtectedRoute>} />
          <Route exact path='/app/profile_history_form' element={<ProtectedRoute><ProfileHistoryForm /></ProtectedRoute>} />
          <Route exact path='/app/profile_history_form/:access_key' element={<ProtectedRoute><ProfileHistoryForm /></ProtectedRoute>} />
          <Route exact path='/app/profile_media' element={<ProtectedRoute><ProfileMedia /></ProtectedRoute>} />
          <Route exact path='/app/profile_media_form' element={<ProtectedRoute><ProfileMediaForm /></ProtectedRoute>} />
          <Route exact path='/app/profile_media_form/:access_key' element={<ProtectedRoute><ProfileMediaForm /></ProtectedRoute>} />

          <Route exact path='/app/chat_list' element={<ProtectedRoute><ChatList /></ProtectedRoute>} />
          <Route exact path='/app/chat_detail' element={<ProtectedRoute><ChatDetail /></ProtectedRoute>} />

          <Route exact path='/app/settings' element={<ProtectedRoute><Setting /></ProtectedRoute>} />

          <Route exact path='/app/league/:access_key' element={<LeagueDetail />} />

          <Route exact path='/app/statistics' element={<StatisticsProvider><Statistics /></StatisticsProvider>} />
          <Route exact path='/app/statistics/:access_key' element={<StatisticsProvider><Statistics /></StatisticsProvider>} />
          <Route exact path='/app/start_stats' element={<StatisticsProvider><StartStats /></StatisticsProvider>} />
          <Route exact path='/app/show_stats' element={<ShowPlayerStats />} />
          <Route exact path='/app/rotations_stats/:access_key' element={<StatisticsProvider><ShowGameStats /></StatisticsProvider>} />
          <Route exact path='/app/practice_stats/:access_key' element={<StatisticsProvider><ShowPracticeStats /></StatisticsProvider>} />
          <Route exact path='/app/show_team_stats/:access_key' element={<StatisticsProvider><ShowTeamStats /></StatisticsProvider>} />
          <Route exact path='/app/mystats' element={<MyStatsHome />} />

        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
