
export const courtPositionsUtil = {
    four: {
        number: 4,
        label: 4,
        courtPosition: 1,
        color: "#FF33A1",
    },
    three: {
        number: 3,
        label: 3,
        courtPosition: 2,
        color: "#3357FF",
    },
    two: {
        number: 2,
        label: 2,
        courtPosition: 3,
        color: "#33FF57",
    },
    five: {
        number: 5,
        label: 5,
        courtPosition: 4,
        color: "#FFBD33",
    },
    six: {
        number: 6,
        label: 6,
        courtPosition: 5,
        color: "#33FFF5",
    },
    one: {
        number: 1,
        label: 1,
        courtPosition: 6,
        color: "#FF5733",
    },
}