import React, { useContext, useState } from 'react'
import AppHead from '../AppHead/AppHead'
import AppNavbar from '../AppNavbar/AppNavbar'
import './AppBodyComponent.css'
import { SpeedDial } from 'primereact/speeddial'
import { PiEnvelopeBold, PiMedalBold, PiPlayBold, PiStarBold, PiTrophyBold } from 'react-icons/pi'
import AuthContext from '../../context/AuthContext/AuthContext'
import { Dialog } from 'primereact/dialog'
import { sendEmailNewMessage } from '../../services/UserService'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import { HelmetProvider } from 'react-helmet-async'

function AppBodyComponent({children, activeMenuNumber=1}) {
    const {userAuth} = useContext(AuthContext);
    const [message, setMessage] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const handleCancelMessage = () => {
        setMessage('')
        setModalIsOpen(false)
    }

    const openDialog = () => {
        setModalIsOpen(true)
    }

    const handleConfirmMessage = () => {
        setModalIsOpen(false)
        sendEmailNewMessage(
            message, userAuth?.user.access_key,
            `${userAuth?.user.first_name} ${userAuth?.user.last_name}`, 
            'info@leagr.net', 
            userAuth?.user.email)
    }

    return (
    <div>
        <AppHead activeMenuNumber={activeMenuNumber} />
        <div className="app--container">
            <AppNavbar activeMenuNumber={activeMenuNumber} />
            <div className="app--content">
                {children}
            </div>
        </div>
        {userAuth?.user && 
        <>
            <Dialog header='Contact us' visible={modalIsOpen} onHide={() => setModalIsOpen(false)}
            footer={
                <div>
                    <Button label="Cancel" icon="pi pi-times" onClick={handleCancelMessage} className="p-button-text" />
                    <Button label="Send" icon="pi pi-check" onClick={handleConfirmMessage} autoFocus />
                </div>
            }>
                <div className='flex flex-column'>
                    <label className='text--regular--normal'>Send your message to us.</label>
                    <InputTextarea value={message} onChange={(e) => setMessage(e.target.value)} rows={5} cols={30} />
                </div>
            </Dialog>
        
            <SpeedDial 
            radius={120}
            type='quarter-circle'
            direction='up-left'
            style={{position: 'fixed', bottom: 24, right: 24 }}
            model={[
            {
            label: 'Event',
            icon: <PiTrophyBold size={24} />,
            command: () => window.location.href = '/app/event_form'
            },
            {
            label: 'Media',
            icon: <PiPlayBold size={24} />,
            command: () => window.location.href = '/app/profile_media_form'
            },
            {
            label: 'History',
            icon: <PiMedalBold size={24} />,
            command: () => window.location.href = '/app/profile_history_form'
            },
            {
            label: 'Contact us',
            icon: <PiEnvelopeBold size={24} />,
            command: () => openDialog()
            },
            ]} />
        </>}
    </div>
)
}

export default AppBodyComponent