import React, { useEffect, useState } from 'react'
import ProfileHistoryItem from '../../../Profile/ProfileHistory/ProfileHistoryItem/ProfileHistoryItem'
import PlayerDetail from '../PlayerDetail'
import { useParams } from 'react-router-dom'
import { listUserHistorysByUser } from '../../../../../services/ProfileHistoryService'
import {Timeline} from 'primereact/timeline'
import { Avatar } from 'primereact/avatar'
import { Panel } from 'primereact/panel'

function PlayerDetailHistory() {

    const [playerHistories, setPlayerHistories] = useState()
    const {access_key} = useParams()

    const handleListPlayerHistories = async () => {
        setPlayerHistories(await listUserHistorysByUser(access_key))
    }

    useEffect(()=>{
        handleListPlayerHistories()
    },[])

    return (
        <PlayerDetail activeMenuNumber={3} access_key={access_key}>
            <div className="profile_media--content">
                <div style={{padding: '24px 0'}}>
                    <div className='text--regular--bold' style={{display: 'flex', justifyContent: 'center', gap: '24px', marginBottom: '24px'}}>
                        <label>Date</label>
                        <label>Position</label>
                        <label>Details</label>
                    </div>
                    
                    {playerHistories?.length ? 
                    <Timeline 
                    value={playerHistories} 
                    content={(item) => 
                        <div>
                            <div className='text--small--bold'>{item.event_name}</div>
                            <div className='text--xsmall--normal'>{item.league_name}</div>
                            <div className='text--small--normal'>{item.awards}</div>
                        </div>
                    }
                    opposite={(item) => 
                        <div className='text--small--bold'>{new Date(item.date).toLocaleDateString() || '-'}</div>
                    }
                    marker={(item) => <Avatar size='normal' shape='circle'>{item.position}</Avatar>} 
                    /> : <div style={{display: 'flex', justifyContent: 'center'}}>No history found.</div>}
                </div>
            </div>
        </PlayerDetail>
    )
}

export default PlayerDetailHistory