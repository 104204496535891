import axios from 'axios';
import { toast } from 'react-toastify';

export const create = async (user_access_key, sport_access_key, skill_access_key, sport_rating, skill_rating) =>{
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/user_sport_skill/create`, 
        {user_access_key, sport_access_key, skill_access_key, sport_rating, skill_rating})
        .then((response)=>{
            toast.success(`Skill created!`)
            return response.data.access_key
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        console.log(error)
        toast.error('An error ocurred.')
    }
}

export const listUserSportByUser = async (userAccessKey = '') =>{
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/user_sport/get_by_user/${userAccessKey}`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('An error ocurred.')
    }
}

export const getUserSportByUserSport = async (userAccessKey, sportAccessKey) =>{
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/user_sport/get_by_user_sport/${userAccessKey}/${sportAccessKey}`)
        .then((response)=>{
            return response.data[0]
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('An error ocurred.')
    }
}

export const update = async (access_key, rating) => {
    try {
        return await axios.put(`${process.env.REACT_APP_API_URL}/api/user_sport_skill/update`, 
        {access_key, rating})
        .then((response)=>{
            toast.success(`Skill updated!`)
            return true
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('An error ocurred.')
    }
}

