import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React, { useContext, useState } from 'react'
import { PiUsersThree } from 'react-icons/pi'
import StatisticsContext from '../StatisticsContext'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'

function ManageTeamComponent() {
    const [visible, setVisible] = useState(false)
    const {playersA, setPlayersA} = useContext(StatisticsContext)
    const footer = () => {
        return (
            <div>
                <Button onClick={handleCancel}>Close</Button>
            </div>
        )
    }

    const handleCancel = () => {
        setVisible(false)
    }
    
    const handleTeamPositions = (_position, _index) => {
        var _players = [...playersA]
        if (_position && Number(_position)){
            const indexPosition = _players.findIndex(e=>Number(e.position) === Number(_position))
            if (indexPosition >= 0) {
                _players[indexPosition].position = ''
                _players[indexPosition].courtPosition = ''
            }
            _players[_index].position = Number(_position)
            _players[_index].courtPosition = Number(_position)
            setPlayersA(_players)
        } else {
            _players[_index].position = ''
            _players[_index].courtPosition = ''
            setPlayersA(_players)
        }
    }

    return (
        <div>
            <Dialog header={`Team Positions`} visible={visible} onHide={()=>setVisible(false)} footer={footer}>
                {
                    playersA && 
                    <div className='flex flex-column gap-2 mt-4'>
                        {
                            playersA.map((element, key)=>(
                                <div style={{backgroundColor: element?.position ? '#86BBD8' : ''}} key={key} className='flex gap-2 align-items-center border-round'>
                                    <InputNumber inputStyle={{width: '3rem'}} allowEmpty={true} value={element?.position} onValueChange={(e)=>handleTeamPositions(e.target.value, key)} />
                                    <div className='text-lg font-bold'>{`${element.number} ${element.player.first_name} ${element.player.last_name}`}</div>
                                </div>
                            ))
                        }
                    </div>
                }
            </Dialog>
            <Button size='small' className={"w-max h-full p-2"} onClick={()=>setVisible(true)}><PiUsersThree size={18} /></Button>
        </div>
    )
}

export default ManageTeamComponent