import React, { useContext, useEffect, useState } from 'react'
import LeagueAutocompleteComponent from '../LeagueAutocompleteComponent/LeagueAutocompleteComponent'
import { RequestType, geocode, setDefaults } from 'react-geocode'
import AuthContext from '../../context/AuthContext/AuthContext'
import { useForm } from 'react-hook-form'
import { usePlacesWidget } from 'react-google-autocomplete'
import { toast } from 'react-toastify'
import { getEventByAccessKey, newEventDetails, updateEventDetails } from '../../services/EventService'
import SportAutocompleteComponent from '../SportAutocompleteComponent/SportAutocompleteComponent'
import {InputText} from 'primereact/inputtext'
import { Editor } from 'primereact/editor';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button'
import { Tag } from 'primereact/tag';
import { useNavigate, useParams } from 'react-router-dom'
import { ConfirmDialog } from 'primereact/confirmdialog'
        

function EventFormComponent({access_key}) {
    const {userAuth} = useContext(AuthContext)

    const {ref, autocompleteRef} = usePlacesWidget({
        // googleMapsScriptBaseUrl: 'https://maps.googleapis.com/maps/api/js?loading=async&key='+process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        onPlaceSelected: (place) => {
            setValue("address", place.formatted_address)
        },
        options: {types: [] },
    })

    const [sport, setSport] = useState();
    const daysInitials = [
        {label: 'Mon', checked: false},
        {label: 'Tue', checked: false},
        {label: 'Wed', checked: false},
        {label: 'Thu', checked: false},
        {label: 'Fri', checked: false},
        {label: 'Sat', checked: false},
        {label: 'Sun', checked: false},
    ]

    const [days, setDays] = useState(daysInitials);
    const [league, setLeague] = useState();
    const {setValue, register, onChange, handleSubmit, clearErrors, getValues, watch, formState: {errors}, reset, control} = useForm();
    const navigate = useNavigate()
    const [isButtonLoading, setIsButtonLoading] = useState(false)

    register('sport', {required: 'Required',});
    register('league', {required: 'Required',});
    register('name', {required: 'Required',});
    register('address', {required: 'Select a valid address',});
    register('marker');
    register('days', {required: 'Required',});
    register('startDate', {required: 'Required',});
    register('endDate', {required: 'Required',});
    register('description', {required: 'Required',});

    useEffect(()=>{
        handleGetEventByAccessKey();
    },[access_key])

    const handleGetEventByAccessKey = async () => {
        if (access_key) {
            await getEventByAccessKey(access_key).then((_event)=>{
                setValue("name", _event.name)
                setValue("address", _event.address)
                setValue("marker", {lat: parseFloat(_event.lat), lng: parseFloat(_event.lng)})
                setValue("startDate", _event.start_date ? new Date(_event.start_date) : '')
                setValue("endDate",  _event.end_date ? new Date(_event.end_date) : '')
                setValue("description", _event.description)
                setValue("sport", {name: _event.sport_name, access_key: _event.sport_access_key})
                setValue("league", {name: _event.league_name, access_key: _event.league_access_key})
                setValue("days", _event.days)
                document.getElementById('address').value = _event.address ? _event.address : ""
            })
        }
    }

    const onChangeDays = (e) => {
        let _days = [...days]

        const idx = _days.map(e=>e.label).indexOf(e.value)
        _days[idx].checked = e.checked
        let _checkedDays = []
        _days.forEach(item=>item.checked && _checkedDays.push(item.label))

        setDays(_days);
        setValue("days", _checkedDays.join(', '));
    }

    const handleSetLeague = (value) => {
        setLeague(value)
        setValue("league", value)
    }

    const handleSetSport = (value) => {
        setSport(value)
        setValue("sport", value)
    }

    setDefaults({
        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // Your API key here.
        language: "en", // Default language for responses.
        region: "us", // Default region for responses.
    });

    useEffect(()=>{
        reset({
            sport: getValues("sport"),
            league: getValues("league"),
            name: getValues("name"),
            address: getValues("address"),
            marker: getValues("marker"),
            startDate: getValues("startDate"),
            endDate: getValues("endDate"),
            days: getValues("days"),
            description: getValues("description"),
        })
        document.getElementById('address').value = getValues("address") ? getValues("address") : ""
    },[userAuth, reset])

    const handleCancel = () => {
        navigate('/app/event_list')
    }

    const onSubmit = async (data) => {
        setIsButtonLoading(true)
        await geocode(RequestType.ADDRESS, data.address)
        .then(async ({ results }) => {
            const { lat, lng } = results[0].geometry.location;
            setValue('marker', {lat: lat, lng: lng});
            if (access_key){
                await updateEventDetails(getValues(), access_key, userAuth.user.access_key)
            } else {
                await newEventDetails(getValues(), userAuth.user.access_key)
            }
            navigate('/app/event_list')
        })
        .catch((error) => {
            toast.error('Sorry. An error ocurred.')
        });
        setIsButtonLoading(false)
    }

    const onError = (errors) => {

    }

    const handleSetDescription = (value) =>{
        setValue("description", value)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <ConfirmDialog />
            <div className="form--row">
                <div className="form--fieldset">
                    <label htmlFor='name'>Event Name</label>
                    <InputText type="text" name="name" id="name" onChange={(e)=>setValue("name", e.target.value)} value={watch("name") ? watch("name") : ''}
                    className={errors.name && 'p-invalid'} aria-invalid={errors.name?"true":"false"} />
                    {errors.name && <span className='error-text'>{errors.name?.message}</span>}
                </div>
                <div className="form--fieldset">
                    <label htmlFor='address'>Address</label>
                    <InputText ref={ref} type="text" name="address" id="address" onChange={e=>setValue("address", e.target.value)}
                     style={errors.address && {border: "#DF1B3F 1px solid"}} aria-invalid={errors.address?"true":"false"} />
                     {errors.address && <span className='error-text'>{errors.address?.message}</span>}
                </div>
            </div>
            <div className="form--row">
                <div className="form--fieldset">
                    <label htmlFor='sport'>Sport</label>
                    <div style={errors.sport && {border: "#DF1B3F 1px solid"}} aria-invalid={errors.sport?"true":"false"}>
                        <SportAutocompleteComponent sport={watch("sport") ? watch("sport") : ''} setSport={handleSetSport} />
                    </div>
                    {errors.sport && <span className='error-text'>{errors.sport?.message}</span>}
                </div>
                <div className="form--fieldset">
                    <label htmlFor='league'>League</label>
                    <div style={errors.league && {border: "#DF1B3F 1px solid"}} aria-invalid={errors.league?"true":"false"}>
                        <LeagueAutocompleteComponent league={watch("league") ? watch("league") : ''} setLeague={handleSetLeague} />
                    </div>
                    {errors.league && <span className='error-text'>{errors.league?.message}</span>}
                </div>
            </div>
            <div className="form--row">
                <div className="form--row">
                    <div className="form--fieldset">
                        <label htmlFor='startDate'>Start Date</label>
                        <Calendar name="startDate" id="startDate"  onChange={(e)=>setValue("startDate", e.target.value)} 
                        value={watch("startDate") ? watch("startDate") : ''}
                        className={errors.startDate && 'p-invalid'} aria-invalid={errors.startDate?"true":"false"} />
                        {errors.startDate && <span className='error-text'>{errors.startDate?.message}</span>}
                    </div>
                    <div className="form--fieldset">
                        <label htmlFor='endDate'>End Date</label>
                        <Calendar name="endDate" id="endDate" onChange={(e)=>setValue("endDate", e.target.value)} value={watch("endDate") ? watch("endDate") : ''}
                        className={errors.endDate && 'p-invalid'} aria-invalid={errors.endDate ? "true" : "false"} />
                        {errors.endDate && <span className='error-text'>{errors.endDate?.message}</span>}
                    </div>
                </div>
                <div className="form--row">
                    <div className="form--fieldset">
                        <label htmlFor='days'>Days</label>
                        <div className="form--fieldset--checkbox" aria-invalid={errors.days?"true":"false"}>
                            {
                                daysInitials.map((day, key)=>(
                                    <div key={key} className="form--fieldset--checkbox">
                                        <Checkbox className={errors.days && 'p-invalid'} name="days" value={day.label} 
                                        onChange={onChangeDays} checked={getValues("days") ? getValues("days").includes(day.label) : false}
                                        /> 
                                        {day.label}
                                    </div>
                                ))
                            }
                        </div>
                        {errors.days && <span className='error-text'>{errors.days?.message}</span>}
                    </div>
                </div>
            </div>
            <div className="form--row">
                <div className="form--fieldset">
                    <label htmlFor='description'>Rules and Description</label>
                    <Editor value={getValues('description')} onTextChange={(e)=>handleSetDescription(e.htmlValue)} style={{height: '220px'}} />
                    <div>{errors.description && <Tag severity={'danger'} value={errors.description?.message}></Tag> }</div>
                </div>
            </div>
            <div className="form--action_row">
                <Button type="submit" label='Save' loading={isButtonLoading} />
                <Button severity='secondary' onClick={handleCancel} type="button">Cancel</Button>
            </div>
        </form>
    )
}

export default EventFormComponent