import React, { useContext, useEffect, useRef, useState } from 'react'
import StatisticsContext from '../StatisticsContext'
import { createRally } from '../../../../services/StatsService'
import AuthContext from '../../../../context/AuthContext/AuthContext'
import CourtLayoutComponent from './CourtLayoutComponent'
import SideLayoutComponent from './SideLayoutComponent'
import MenuButtonsComponent from './MenuButtonsComponent'
import { qualitiesFive, qualitiesFour, qualitiesThree } from '../Utils/QualitiesUtils'
import { courtPositionsUtil } from '../Utils/CourtPositionsUtils'
import { actionsUtils } from '../Utils/ActionsUtils'
        

function FullStatisticsComponent() {

    const {gridData, playersA, setPlayersA, playersB, setPlayersB, rotationA, rotationB, activeTeam, setActiveTeam, 
        currentRallyContext, setCurrentRallyContext, handleRotationA, handleRotationB} = useContext(StatisticsContext)
    const {userAuth} = useContext(AuthContext)
    const [gridLayout, setGridLayout] = useState(false)

    var handlePlayerClick = (player) => {
        setCourt({court: actions, type: 'Action'})
        setCurrentPlayer(player)
    }

    useEffect(()=>{
        gridData && handleInitialData()
    },[gridData])

    const handleInitialData = () => {
        setPlayersA(gridData?.playersTeamA?.map((e)=>{
            var color = '';
            if (e.position == 4) color = "#0dc61d"
            if (e.position == 1) color = "#3af25b"
            if (e.position == 3) color = "#8398c4"
            if (e.position == 6) color = "#323035"
            if (e.position == 2) color = "#87663b"
            if (e.position == 5) color = "#b0dee5"
            return (
                {
                    ...e,
                    action: ()=>handlePlayerClick(e),
                    color,
                    courtPosition: e.position,
                }
            )
        }))
        setPlayersB(gridData?.playersTeamB?.map((e)=>{
            var color = '';
            if (e.position == 4) color = "#0dc61d"
            if (e.position == 1) color = "#3af25b"
            if (e.position == 3) color = "#8398c4"
            if (e.position == 6) color = "#323035"
            if (e.position == 2) color = "#87663b"
            if (e.position == 5) color = "#b0dee5"
            return (
                {
                    ...e,
                    action: ()=>handlePlayerClick(e),
                    color,
                    courtPosition: e.position,
                }
            )
        }))
    }

    const handleServeClick = () => {
        setCurrentAction(actions[5])
        setCourt(courtPositions)
    }

    const handleReceptionClick = () => {
        setCurrentAction(actions[3])
        setCourt(qualities_four)
    }

    const handleSetClick = () => {
        setCurrentAction(actions[2])
        setCourt(courtPositions)
    }

    const handleAttackClick = () => {
        setCurrentAction(actions[0])
        setCourt(courtPositions)
    }

    const handleDigClick = () => {
        setCurrentAction(actions[4])
        setCourt(qualities_three)
    }

    const handleBlockClick = () => {
        setCurrentAction(actions[1])
        setCourt(qualities_three)
    }

    const handleQualityClick = (quality) => {
        const idx = qualities_five.findIndex((e)=>e.number == quality)
        setCurrentQuality(qualities_five[idx])
    }

    useEffect(()=>{
        playersA ? setCourt({court: playersA, type: 'Player'}) : playersB && setCourt({court: playersB, type: 'Player'})
    },[playersA, playersB])

    const actions = [
        {
            ...actionsUtils.attack,
            action: ()=>{},
        },
        {
            ...actionsUtils.block,
            action: handleBlockClick,
        },
        {
            ...actionsUtils.set,
            action: ()=>{},
        },
        {
            ...actionsUtils.reception,
            action: ()=>{},
        },
        {
            ...actionsUtils.dig,
            action: handleDigClick,
        },
        {
            ...actionsUtils.serve,
            action: ()=>{},
        },
    ]
        
    const [court, setCourt] = useState()
    const [currentCourtPosition, setCurrentCourtPosition] = useState()
    const [currentAction, setCurrentAction] = useState();
    const [currentQuality, setCurrentQuality] = useState();
    const [currentPlayer, setCurrentPlayer] = useState('');
    const [currentPlayerAction, setCurrentPlayerAction] = useState();
    let [currentRally,setCurrentRally] = useState();
    const [selectedPosition, setSelectedPosition] = useState()
    const activeTeamRef = useRef(activeTeam)
    const rotationARef = useRef(rotationA)
    const rotationBRef = useRef(rotationB)
    const [teamServing, setTeamServing] = useState()

    useEffect(()=>{
        currentQuality?.number >= 0 && finishAction()
    },[currentQuality])

    const finishAction = () => {
        currentPlayerAction = {
            player: null, 
            player_id: null, 
            playerName: '', 
            action_number: currentAction?.number, 
            action: currentAction?.id, 
            position_action: currentCourtPosition ? currentCourtPosition : null, 
            quality_value: currentQuality ? currentQuality.number : null, 
            quality: currentQuality ? currentQuality.id : null, 
            rotation: activeTeamRef.current === 1 ? rotationARef.current : rotationBRef.current, 
            position: null,  
            team: activeTeamRef.current === 1 ? gridData.teamA : gridData.teamB, 
        }
        currentRally.push(currentPlayerAction)
        setCurrentRallyContext(currentRally)
    }

    useEffect(()=>{
        if (currentPlayerAction) {
            setCurrentRally([...currentRally, currentPlayerAction])
            setCurrentPlayerAction()
        }
    },[currentPlayerAction])

    const finishRally = () => {
        createRally({match_id: gridData.matchId, created_by: userAuth.user.access_key, rally: currentRally}).then((res)=>{
            res && setCurrentRally([])
        }).catch((error)=>{
            
        })
    }

    const handlePositionClick = (action) => {
        switch (action.number) {
            case 'ser':
                setCourt(qualities_five)
                break;
        
            case 'att':
                setCourt(qualities_three)
                break;
        
            case 'set':
                setCourt(qualities_three)
                break;
            
            default:
                break;
        }
    }

    useEffect(()=>{
        currentCourtPosition > 0 && handlePositionClick(currentAction)
    },[currentCourtPosition])

    const qualities_three = [
        {
            ...qualitiesThree.two,
            action: () => handleQualityClick(2),
        },
        {
            ...qualitiesThree.one,
            action: () => handleQualityClick(1),
        },
        {
            ...qualitiesThree.zero,
            action: () => handleQualityClick(0),
        },
    ]

    const qualities_four = [
        {
            ...qualitiesFour.three,
            action: () => handleQualityClick(3),
        },
        {
            ...qualitiesFour.two,
            action: () => handleQualityClick(2),
        },
        {
            ...qualitiesFour.one,
            action: () => handleQualityClick(1),
        },
        {
            ...qualitiesFour.zero,
            action: () => handleQualityClick(0),
        },
    ]

    const qualities_five = [
        {
            ...qualitiesFive.four,
            action: () => handleQualityClick(4),
        },
        {
            ...qualitiesFive.three,
            action: () => handleQualityClick(3),
        },
        {
            ...qualitiesFive.two,
            action: () => handleQualityClick(2),
        },
        {
            ...qualitiesFive.one,
            action: () => handleQualityClick(1),
        },
        {
            ...qualitiesFive.zero,
            action: () => handleQualityClick(0),
        },
    ]

    //need to be same order as rotations
    const courtPositions = [
        {
            ...courtPositionsUtil.four,
            action: () => handlePositionClick(4),
        },
        {
            ...courtPositionsUtil.three,
            action: () => handlePositionClick(3),
        },
        {
            ...courtPositionsUtil.two,
            action: () => handlePositionClick(2),
        },
        {
            ...courtPositionsUtil.five,
            action: () => handlePositionClick(5),
        },
        {
            ...courtPositionsUtil.six,
            action: () => handlePositionClick(6),
        },
        {
            ...courtPositionsUtil.one,
            action: () => handlePositionClick(1),
        },
    ]

    const handleChangePosition = (e) => {
        setSelectedPosition(e.value)
        changePositionA(e.value)
    }

    const changePositionA = (position) => {
        var playersList = [...playersA]
        playersList?.forEach((item) => {
            item.position = item.number === item.number ? position : item.position
        });
        setPlayersA(playersList)
    }

    const changePlayerPosition = (_pos) => {
        var newPosition = _pos - 1
        newPosition = newPosition === 0 ? 6 : newPosition
        return newPosition
    }

    useEffect(()=>{
        if (playersA) {
            var tempPlayers = [...playersA]
            setPlayersA(tempPlayers.map((e)=>{
                return (
                    {
                        ...e,
                        position: changePlayerPosition(e.position),
                    }
                )
            }))
        }
    },[rotationA])

    useEffect(()=>{
        if (playersB){
            var tempPlayers = [...playersB]
            setPlayersB(tempPlayers.map((e)=>{
                return (
                    {
                        ...e,
                        position: changePlayerPosition(e.position),
                    }
                )
            }))
        }
    },[rotationB])

    return (
        <div>
            {gridLayout ? 
                <CourtLayoutComponent menuButtons={
                    <MenuButtonsComponent 
                    gridLayout={gridLayout} 
                    setGridLayout={setGridLayout} 
                    currentPlayer={currentPlayer} 
                    selectedPosition={selectedPosition} 
                    handleChangePosition={handleChangePosition} 
                    currentAction={currentAction} 
                    setTeamServing={setTeamServing}
                    courtType={court?.type}  
                    />
                } 
                court={court?.court} 
                courtPositions={courtPositions} 
                currentRally={currentRally} 
                setCurrentRally={setCurrentRally} 
                gridLayout={gridLayout} 
                finishRally={finishRally} />
            :
                <SideLayoutComponent  menuButtons={
                    <MenuButtonsComponent 
                    gridLayout={gridLayout} 
                    setGridLayout={setGridLayout} 
                    currentPlayer={currentPlayer} 
                    selectedPosition={selectedPosition} 
                    handleChangePosition={handleChangePosition} 
                    currentAction={currentAction} 
                    setTeamServing={setTeamServing}
                    courtType={court?.type}  
                    />
                } 
                court={court?.court} 
                courtPositions={courtPositions} 
                currentRally={currentRally} 
                setCurrentRally={setCurrentRally} 
                gridLayout={gridLayout} 
                finishRally={finishRally} />
            }
        </div>
    )

}

export default FullStatisticsComponent