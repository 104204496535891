import React from 'react'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'
import ChatListItemComponent from './ChatListItemComponent/ChatListItemComponent'
import './ChatList.css';
import { Search } from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { useNavigate } from 'react-router-dom';

function ChatList() {

    const data = [
        {title: 'Ryan Reynolds', last_message: 'Thank you for invited me!', last_message_date_time: '08/25/2024 08:45 PM'},
        {title: 'Ryan Reynolds', last_message: 'Thank you!', last_message_date_time: '08/25/2024 08:45 PM'},
        {title: 'Ryan Reynolds', last_message: 'Thank you for invited me!', last_message_date_time: '08/25/2024 08:45 PM'},
        {title: 'Ryan Reynolds', last_message: 'Thank you for invited me!', last_message_date_time: '08/25/2024 08:45 PM'},
        {title: 'Ryan Reynolds', last_message: 'Thank you for invited me!', last_message_date_time: '08/25/2024 08:45 PM'},
        {title: 'Ryan Reynolds', last_message: 'Thank you', last_message_date_time: '08/25/2024 08:45 PM'},
        {title: 'Ryan Reynolds', last_message: 'Thank you for invited me!', last_message_date_time: '08/25/2024 08:45 PM'},
        {title: 'Ryan Reynolds', last_message: 'Thanks', last_message_date_time: '08/25/2024 08:45 PM'},
        {title: 'Ryan Reynolds', last_message: 'Thank you for invited me!', last_message_date_time: '08/25/2024 08:45 PM'},
        {title: 'Ryan Reynolds', last_message: 'Thank you for invited me!', last_message_date_time: '08/25/2024 08:45 PM'},
        {title: 'Ryan Reynolds', last_message: 'Thank you for invited me!', last_message_date_time: '08/25/2024 08:45 PM'},
    ]

    const navigate = useNavigate()

    const handleClickChat = () => {
        navigate('/app/chat_detail')
    }

    return (
        <AppBodyComponent activeMenuNumber={4}>
            <div className="chat_list--container">
                <Search placeholder="Search..." />
                {data.map(item=>(
                    <ChatListItemComponent data={item} />
                ))}
            </div>
        </AppBodyComponent>
    )
}

export default ChatList