import React, { useState } from 'react'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'
import { Avatar, ChatContainer, Conversation, ConversationHeader, ConversationList, ExpansionPanel, InfoButton, MainContainer, Message, MessageInput, MessageList, MessageSeparator, Search, Sidebar, TypingIndicator, VoiceCallButton } from '@chatscope/chat-ui-kit-react'
import UserPicture from '../../../images/user_image_medium.png'
import { useNavigate } from 'react-router-dom';

function ChatDetail({data}) {
    const [messageInputValue, setMessageInputValue] = useState("");
    const navigate = useNavigate()
    
    const handleBackButton = () => {
        navigate('/app/chat_list')
    }

    return (
        <AppBodyComponent activeMenuNumber={4}>
            <MainContainer>                                   
                <ChatContainer>
                  <ConversationHeader>
                    <ConversationHeader.Back onClick={handleBackButton} hidden={false} />
                    <Avatar src={UserPicture} name="Zoe" />
                    <ConversationHeader.Content userName="Zoe" info="Active 10 mins ago" />
                    <ConversationHeader.Actions>
                      <InfoButton />
                    </ConversationHeader.Actions>          
                  </ConversationHeader>
                  <MessageList typingIndicator={<TypingIndicator content="Zoe is typing" />}>
                    
                    <MessageSeparator content="Saturday, 30 November 2019" />
                    
                    <Message model={{
                        message: "Hello my friend",
                        sentTime: "15 mins ago",
                        sender: "Zoe",
                        direction: "incoming",
                        position: "single"
                    }}>
                        <Avatar src={UserPicture} name="Zoe" />
                    </Message>
                    
                    <Message model={{
                        message: "Hello my friend",
                        sentTime: "15 mins ago",
                        sender: "Patrik",
                        direction: "outgoing",
                        position: "single"
                    }} />
                    
                    <Message model={{
                        message: "Hello my friend",
                        sentTime: "15 mins ago",
                        sender: "Zoe",
                        direction: "incoming",
                        position: "first"
                    }} avatarSpacer />
                    
                    <Message model={{
                        message: "Hello my friend",
                        sentTime: "15 mins ago",
                        sender: "Zoe",
                        direction: "incoming",
                        position: "normal"
                    }} avatarSpacer />
                    
                    <Message model={{
                        message: "Hello my friend",
                        sentTime: "15 mins ago",
                        sender: "Zoe",
                        direction: "incoming",
                        position: "normal"
                    }} avatarSpacer />
                    
                    <Message model={{
                        message: "Hello my friend",
                        sentTime: "15 mins ago",
                        sender: "Zoe",
                        direction: "incoming",
                        position: "last"
                    }}>
                        <Avatar src={UserPicture} name="Zoe" />
                    </Message>
                    
                    <Message model={{
                        message: "Hello my friend",
                        sentTime: "15 mins ago",
                        sender: "Patrik",
                        direction: "outgoing",
                        position: "first"
                    }} />
                    
                    <Message model={{
                        message: "Hello my friend",
                        sentTime: "15 mins ago",
                        sender: "Patrik",
                        direction: "outgoing",
                        position: "normal"
                    }} />
                    
                    <Message model={{
                        message: "Hello my friend",
                        sentTime: "15 mins ago",
                        sender: "Patrik",
                        direction: "outgoing",
                        position: "normal"
                    }} />
                    
                    <Message model={{
                        message: "Hello my friend",
                        sentTime: "15 mins ago",
                        sender: "Patrik",
                        direction: "outgoing",
                        position: "last"
                    }} />
                                
                    <Message model={{
                        message: "Hello my friend",
                        sentTime: "15 mins ago",
                        sender: "Zoe",
                        direction: "incoming",
                        position: "first"
                    }} avatarSpacer />                                          
                    
                    <Message model={{
                        message: "Hello my friend",
                        sentTime: "15 mins ago",
                        sender: "Zoe",
                        direction: "incoming",
                        position: "last"
                    }}>
                      <Avatar src={UserPicture} name="Zoe" />
                    </Message>
                  </MessageList>
                  
                  <MessageInput placeholder="Type message here" value={messageInputValue} onChange={val => setMessageInputValue(val)} onSend={() => setMessageInputValue("")} />
                </ChatContainer>
              </MainContainer>
        </AppBodyComponent>
    )
}

export default ChatDetail