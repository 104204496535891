import { Dropdown } from 'primereact/dropdown'
import React, { useContext, useEffect, useState } from 'react'
import StatisticsContext from '../StatisticsContext'

function CourtLayoutItemComponent({item, positions}) {
    const {showSelectPosition, players, setPlayers, rotationA, currentActionRefContext} = useContext(StatisticsContext)
    const [selectedPosition, setSelectedPosition] = useState(item?.position)
    const [currentRotationA, setCurrentRotationA] = useState(rotationA)
    const [itemColor, setItemColor] = useState(item?.color)

    useEffect(()=>{
        item && setSelectedPosition(item.position)
        handleItemColor()
    },[item])

    useEffect(()=>{
        handleItemColor()
    },[currentActionRefContext?.current])

    const handleItemColor = () => {
        typeof(item?.label) === 'number' ? setItemColor(currentActionRefContext?.current?.color) : setItemColor(item?.color) 
    }

    const handleChangePosition = (e) => {
        setSelectedPosition(e.value)
        changePosition(e.value)
    }

    const changePosition = (position) => {
        var playersList = [...players]
        playersList?.forEach((item) => {
            item.position = item.number === item.number ? position : item.position
        });
        setPlayers(playersList)
    }

    const changePlayerPositionA = () => {
        const index = positions.findIndex((e)=>e.number === item.position)
        var step = rotationA - currentRotationA > 0 ? 1 : -1
        step = rotationA === 1 && currentRotationA === 6 ? 1 : step
        var newIndex = step+index
        if (newIndex >= positions.length) newIndex = 0
        if (newIndex < 0) newIndex = positions.length-1
        const newPosition = positions[newIndex].number
        setCurrentRotationA(rotationA)
        return newPosition
    }

    useEffect(()=>{
        if (rotationA !== currentRotationA && item?.position){
            const index = players.findIndex((e)=>e.number === item.number)
            players[index].position = changePlayerPositionA()
            setSelectedPosition(players[index].position)
        }
    },[rotationA])

    return (
        item ? <div style={{backgroundColor: itemColor, userSelect: 'none', WebkitUserSelect: 'none'}}
        className={`fadein flex flex-column w-full h-8rem border-1 border-round border-white justify-content-center align-items-center`} 
        onClick={!showSelectPosition && item.action ? item.action : ()=>{}}>
            {item?.position && 
            !showSelectPosition ? 
            selectedPosition && <div className="text-l border-1 py-1 px-2">{selectedPosition}</div> : item?.position &&
            <Dropdown value={selectedPosition} onChange={handleChangePosition} 
            options={positions} optionLabel='number' optionValue='number' placeholder='Position' />}
            
            <div className="text-6xl">{item.label}</div>
        </div> : 
        <div className={`flex w-full h-8rem border-1 border-round border-white justify-content-center align-items-center`}  ></div>
    )
}

export default CourtLayoutItemComponent