import React, { useEffect, useState } from 'react'
import PlayerDetail from '../PlayerDetail'
import ProfileMediaItem from '../../../Profile/ProfileMedia/ProfileMediaItem/ProfileMediaItem'
import { listUserMediasByUser } from '../../../../../services/ProfileMediaService'
import { useParams } from 'react-router-dom'
import { Timeline } from 'primereact/timeline'
import { Avatar } from 'primereact/avatar'
import { PiPlayCircleBold } from 'react-icons/pi'

function PlayerDetailMedia() {
    const [userMedias, setUserMedias] = useState()
    const {access_key} = useParams()

    const handleListUserMedias = async () => {
        setUserMedias(await listUserMediasByUser(access_key))
    }

    useEffect(()=>{
        handleListUserMedias()
    },[])


    return (
        <PlayerDetail activeMenuNumber={4} access_key={access_key}>
            <div className="profile_media--content">

                <div className="profile_media--content">
                    <div style={{padding: '24px 0'}}>
                        <div className='text--regular--bold' style={{display: 'flex', justifyContent: 'center', gap: '24px', marginBottom: '24px'}}>
                            <label>Date</label>
                            <label>Play</label>
                            <label>Details</label>
                        </div>

                        {userMedias?.length ? 
                        <Timeline 
                        value={userMedias} 
                        content={(item) => 
                            <div>
                                <div className='text--small--bold'>{item.title}</div>
                                <div className='text--small--normal'>{item.event_name}</div>
                                <div className='text--xsmall--normal'>{item.league_name}</div>
                            </div>
                        }
                        opposite={(item) => 
                            <div className='text--small--bold'>{item.date ? new Date(item.date).toLocaleDateString() : '-'}</div>
                        }
                        marker={(item) => 
                            <Avatar size='normal' shape='circle'>
                                <a className='icon_button' role='button' href={item.url} target="_blank" rel="noopener noreferrer">
                                    <PiPlayCircleBold role='button' style={{border: 'none'}} />
                                </a>
                            </Avatar>} 
                        /> : <div style={{display: 'flex', justifyContent: 'center'}}>No media found.</div>}
                    </div>
                </div>
            </div>
        </PlayerDetail>
    )
}

export default PlayerDetailMedia