import React from 'react'
import './PlayerItemListComponent.css'
import { Avatar } from 'primereact/avatar'
import { Panel } from 'primereact/panel';

function PlayerItemListComponent({data}) {

    const header = () => {
        return (
        <div className="flex flex-row gap-2 align-items-center">
            <Avatar label={data.first_name[0]+data.last_name[0]} image={data.picture_url || ''} shape='circle' />
            <div className="">
                <div className='text-lg'>{data.first_name} {data.last_name}</div>
                <div className='text-sm font-light'>{data.city}, {data.state}</div>
            </div>
        </div>)        
    }
    
    return (
        data &&
        <div className='col-12 md:col-6 xl:col-4'>
            <a href={'/app/player_detail_about/'+data.username} style={{textDecoration: 'none',}}>
                <Panel header={header}>
                    {
                        data.sports?.map((item, key)=>(
                            <div key={key} className="text-base font-medium">
                                {item.sport_name} ({item.sport_rating}) 
                                <div className="text-sm font-light">
                                    {item.skills?.map(subitem=>subitem.skill_name+': '+subitem.skill_rating).join(', ')}
                                </div>
                            </div>
                        ))                            
                    }
                </Panel>
            </a>
        </div>
    )
}

export default PlayerItemListComponent