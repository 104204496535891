import React, { useContext, useEffect, useState } from 'react'
import { PiCaretDown, PiCaretUp, PiStar, PiStarFill, PiTrashBold } from 'react-icons/pi'
import { Rating } from 'primereact/rating';
import './ProfileSkillItem.css'
import { update } from '../../../../../services/UserSportSkillService'
import AuthContext from '../../../../../context/AuthContext/AuthContext'
import RatingComponent from '../../../../../components/RatingComponent/RatingComponent';

function ProfileSkillItem({showContent=false, sportSkills, canDelete=false, deleteSkill, isLoading=false, isEditable = false}) {
    const [isExpanded, setIsExpanded] = useState(showContent)
    
    useEffect(()=>{
        setIsExpanded(showContent)
    },[showContent])

    const handleContentExpand = () => {
        setIsExpanded(!isExpanded);
    } 

    const handleDelete = (id, name) => {
        deleteSkill(id, name)
    }

    const handleChangeRating = (rating, access_key) => {
        if (rating && access_key) {
            update(access_key, rating)
        }
    }

    return (
        isLoading ? <span>Loading...</span> :
        sportSkills ? 
        <div className='profile_skill_item--container'>
            <div onClick={handleContentExpand} className="profile_skill_item--head">
                <div className="text--medium--normal">{sportSkills.name} ({sportSkills.rating})</div>
                <div className="profile_skill_item--head--icon">
                    {isExpanded ? 
                    <PiCaretUp /> : 
                    <PiCaretDown />}
                </div>
            </div>
            {isExpanded &&
                <table className="profile_skill_item--content">
                    <tbody>
                        {sportSkills.skills?.map((element, key) => (
                            <tr className="" key={key}>
                                <td className="text--small--normal">{element.skill_name}</td>
                                <td className="profile_skill_item--rating">
                                    <RatingComponent isEditable={isEditable} value={element.skill_rating} access_key={element.access_key} onChangeRating={handleChangeRating} />
                                </td>
                                {canDelete &&
                                <td className='icon_button' onClick={()=>handleDelete(element.access_key, element.skill_name)}>
                                    <PiTrashBold />
                                </td>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        </div>
        : 'No skills added.'
    )
}

export default ProfileSkillItem