import axios from 'axios'
import { toast } from 'react-toastify'

export const deleteUserSkill = async (access_key) => {
    if (access_key){
        try {
            return await axios.delete(`${process.env.REACT_APP_API_URL}/api/user_sport_skill/delete/${access_key}`)
            .then((response)=>{
                response.data && toast.success('Skill deleted.')
            }).catch((error)=>{
                toast.error(error.response.data.message)
                return false
            })
        } catch (error) {
            toast.error('An error ocurred.')
        }
    } else {
        return null;
    }
}

export const listUserSkillsByUser = async (access_key) => {
    if (access_key){
        try {
            return await axios.get(`${process.env.REACT_APP_API_URL}/api/user_sport_skill/list_by_user/${access_key}`)
            .then((response)=>{
                return response.data
            }).catch((error)=>{
                toast.error(error.response.data.message)
                return false
            })
        } catch (error) {
            toast.error('An error ocurred.')
        }
    } else {
        return null;
    }
}


