import React, { useState } from 'react'
import Navbar from '../../../components/Navbar/Navbar'
import Footer from '../../../components/Footer/Footer'
import './ResetPassword.css'
import { useNavigate, useParams } from 'react-router-dom'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { toast } from 'react-toastify'
import { resetPassword } from '../../../services/UserService'

function ResetPassword() {

    const {code} = useParams()
    const navigate = useNavigate();
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (e) => {
        setIsLoading(true)
        e.preventDefault();
        if (confirmPassword !== password){
            toast.error(`Password and Confirm Password don't match`)
        } else {
            await resetPassword(code, password).then((response)=>{
                response && navigate('/login')
            })
        }
        setIsLoading(false)
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const handleConfirmPassword = (e) => {
        setConfirmPassword(e.target.value)
    }

  return (
    <div>
        <Navbar />
            <div className="resetPassword--container">
                <div className="resetPassword--content">
                    <div className="resetPassword--form">
                        <div className="text--heading--h2" style={{paddingBottom: '12px'}}>Reset Password</div>
                        <form onSubmit={handleSubmit} method="post" className='text--regular--normal'>
                            <div className="form--fieldset">
                                <label htmlFor="password">New Password</label>
                                <InputText type="password" name="password" id="password" onChange={handlePassword} />
                            </div>
                            <div className="form--fieldset">
                                <label htmlFor="password">Confirm New Password</label>
                                <InputText type="password" name="confirm_password" id="confirm_password" onChange={handleConfirmPassword} />
                            </div>
                            <div className="form--action_row">
                                <Button loading={isLoading} type="submit" label='Send' />
                            </div>
                            <div className="form--action_row">
                                <Button text severity='secondary' onClick={()=>navigate('/forgot_password')} type="button" label='Forgot password?' />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        <Footer />
    </div>
  )
}

export default ResetPassword