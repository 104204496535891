import { Button } from 'primereact/button'
import { Panel } from 'primereact/panel'
import React, { useContext } from 'react'
import { PiCaretRightBold } from 'react-icons/pi'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../../context/AuthContext/AuthContext'

function Welcome() {
    const navigate = useNavigate();
    const {userAuth} = useContext(AuthContext)
    return (
        <AppBodyComponent activeMenuNumber={1}>
            <Panel unstyled header={
                <div className='flex flex-column align-items-center gap-4 w-full p-4 border-round-top bg-blue-900 text-white'>
                    <div className='text-4xl font-semibold'>
                        Welcome {userAuth?.user?.first_name}! 
                    </div>
                    <div className='text-lg'>
                        What is your next step?
                    </div>
            </div>
            }>
                <div className='flex flex-column gap-4 p-4 w-full border-1 border-solid border-gray-300 align-content-center flex-wrap'>
                    <Button className='sm:w-20rem md:w-24rem text-left' outlined severity='info' iconPos='right' 
                    label={'Showcase your skills'} icon='pi pi-chevron-right' 
                    onClick={()=>navigate("/app/profile_skill_form")} />
                
                    <Button className='sm:w-20rem md:w-24rem text-left' outlined severity='info' iconPos='right' 
                    label={'Find and connect with players'} icon='pi pi-chevron-right' 
                    onClick={()=>navigate("/app/player_list")} />
                
                    <Button className='sm:w-20rem md:w-24rem text-left' outlined severity='info' iconPos='right' 
                    label={'Find leagues and tournaments'} icon='pi pi-chevron-right' 
                    onClick={()=>navigate("/app/event_list")} />
                
                    <Button className='sm:w-20rem md:w-24rem text-left' outlined severity='info' iconPos='right' 
                    label={'Publish your leagues and tournaments'} icon='pi pi-chevron-right' 
                    onClick={()=>navigate("/app/event_form")} />
                </div>
            </Panel>
        </AppBodyComponent>
    )
}

export default Welcome