import React, { useContext, useState } from 'react'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'
import { InputSwitch } from 'primereact/inputswitch';
import AuthContext from '../../../context/AuthContext/AuthContext';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { toast } from 'react-toastify';
import { PiInfoBold, PiWarningCircle } from 'react-icons/pi';
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog'
import { useNavigate } from 'react-router-dom';
        

function Setting() {

    const {userAuth, userUpdateSetting, deleteUserProfile} = useContext(AuthContext)
    const navigate = useNavigate()

    const [receiveEmail, setReceiveEmail] = useState(!!userAuth?.user?.setting_receive_email || '')
    const [milesDistance, setMilesDistance] = useState(userAuth?.user?.setting_miles_distance || '')

    const handleSubmit = (e) => {
        e.preventDefault();
        userUpdateSetting({
            access_key: userAuth.user.access_key,
            miles_distance: milesDistance,
            receive_email: receiveEmail,
        })
    }

    const confirmDeleteProfile = () => {
        confirmDialog({
            message: `Do you want to delete your profile?`,
            header: 'Confirm?',
            icon: <PiWarningCircle />,
            defaultFocus: 'reject',
            accept,
            reject
        });        
    }


    const reject = async () => {
        
    }

    const accept = async () => {
        deleteUserProfile(userAuth?.user.access_key).then((response) => {
            if (response){
                navigate("/")
            }
        });
    }


    return (
        <AppBodyComponent activeMenuNumber={6}>            
            <ConfirmDialog />
            <div>
                <div className='text--heading--h3' style={{marginBottom: '24px'}}>Settings</div>
                <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
                    <div className="form--row">
                        <div className="form--fieldset">
                            <label htmlFor='receive_email' className='text--large--normal' style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                <InputSwitch onChange={()=>setReceiveEmail(!receiveEmail)} type="checkbox" name="receive_email" id="receive_email" checked={receiveEmail} />
                                Receive email notifications
                            </label>
                        </div>
                    </div>
                    <div className="form--row">
                        <div className="form--fieldset">
                            <label className='text--large--normal' style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                See events and players 
                            </label>
                            <label className='text--large--normal' style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                <InputText className='p-inputtext-sm w-5rem' type="number" name="miles_distance" id="miles_distance" 
                                onChange={(e)=>setMilesDistance(e.target.value)} value={milesDistance} />
                                miles from my zipcode
                            </label>
                        </div>
                    </div>
                    <Button className='w-max' type="submit">Save</Button>
                    <div className="flex gap-3 flex-column justify-content-center">
                        <div className='flex gap-2 align-content-center'>
                            <PiInfoBold color='grey' size={'24px'} />
                            <label style={{textAlign: 'initial'}}> Deleting your profile you will lose all your data.</label>
                        </div>
                        <Button className='w-max' type="button" outlined severity='danger' onClick={confirmDeleteProfile}>Delete my profile</Button>
                    </div>
                </form>
            </div>
        </AppBodyComponent>
    )
}

export default Setting