import React, { useContext, useEffect, useState } from 'react'
import {confirmDialog} from 'primereact/confirmdialog'
import AuthContext from '../../context/AuthContext/AuthContext'
import { listSkillByNameSport, newSkill } from '../../services/SkillService'
import { AutoComplete } from "primereact/autocomplete";
import { PiWarningCircle } from 'react-icons/pi';

function SkillAutocompleteComponent({skill, setSkill, size='', sportAccessKey}) {

    const {userAuth} = useContext(AuthContext)
    const [skills, setSkills] = useState([])
    const [skillSelected, setSkillSelected] = useState(null)
    const [filteredSkills, setFilteredSkills] = useState(null)
    const [name, setName] = useState('')

    useEffect(() => {
        handleListSkillsByName(name);
    }, [name]);

    useEffect(()=>{
        setSkillSelected(skill?.name)
    },[skill])

    useEffect(()=>{
        if (skills?.length){
            setFilteredSkills(skills);
        } else {
            setFilteredSkills([{
                access_key: 0,
                name: 'Create '+ name
            }])
        }
    },[skills])
    
    const handleListSkillsByName = async (_name = '') => {
        setSkills(await listSkillByNameSport(_name, sportAccessKey))
    }

    const search = async (event) => {
        var _name = event.query.charAt(0).toUpperCase() + event.query.slice(1)
        await setName(_name)
    }

    const handleSkillOnChange = async (e) => {
        if (typeof e.value === "object"){
            if (e.value.access_key === 0){
                confirmDialog({
                    message: `Do you want to create the skill ${name}?`,
                    header: 'Confirm?',
                    icon: <PiWarningCircle />,
                    defaultFocus: 'accept',
                    accept,
                    reject
                });
            } else {
                await setSkillSelected(e.value.name)
                await setSkill(e.value)
            }
        } else {
            await setSkillSelected(e.value)
        }
    }
      
    const accept = () => {
        if (name && userAuth.user.access_key && sportAccessKey){
            newSkill(name, userAuth.user.access_key, sportAccessKey).then(async(access_key)=>{
                if (access_key){
                    await setSkill({access_key, name})
                    await setSkillSelected(name)
                }
            });
        }
    }

    const reject = async () => {
        
    }

    const itemTemplate = (item) => {
        return(item.name)
    }

    return (
        <>
            <AutoComplete 
                className={`flex ${size}`}
                placeholder={sportAccessKey ? "Enter the skill name..." : "Select a sport first"}
                inputStyle={{width: '100%'}} style={{width: '100%'}}
                minLength={3}
                field="name" 
                value={skillSelected} 
                suggestions={filteredSkills} 
                completeMethod={search} 
                onChange={handleSkillOnChange} 
                itemTemplate={itemTemplate}
            />
        </>
    )
}

export default SkillAutocompleteComponent