import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import React, { useContext, useEffect, useState } from 'react'
import StatisticsContext from '../StatisticsContext';
import { qualitiesFive, qualitiesFour, qualitiesThree } from '../Utils/QualitiesUtils';
import { deleteActionPlayer, updateActionPlayer } from '../../../../services/StatsService';
import { toast } from 'react-toastify';

function RallyActionsItemComponent({rallyItem, refreshData}) {
    const {playersA, gridData} = useContext(StatisticsContext)
    const [playersAState, setPlayersAState] = useState(playersA.map((e)=>({number: e.number, player_id: e.player.id})))
    const [color, setColor] = useState('')
    const [visible, setVisible] = useState(false)
    const [selectedAction, setSelectedAction] = useState({action: rallyItem.action, action_number: rallyItem.action_number})
    const [selectedPlayer, setSelectedPlayer] = useState({number: rallyItem.number, player_id: rallyItem.player_id})
    const [selectedQuality, setSelectedQuality] = useState({quality: rallyItem.quality, quality_value: Number(rallyItem.quality_value)})

    const actions = [
        {
            action: 1,
            action_number: "ser"
        },
        {
            action: 2,
            action_number: "rec"
        },
        {
            action: 3,
            action_number: "set"
        },
        {
            action: 4,
            action_number: "att"
        },
        {
            action: 5,
            action_number: "blo"
        },
        {
            action: 6,
            action_number: "dig"
        },
    ]

    const qualities = [
        {
            quality: 5,
            quality_value: 4
        },
        {
            quality: 4,
            quality_value: 3
        },
        {
            quality: 3,
            quality_value: 2
        },
        {
            quality: 2,
            quality_value: 1
        },
        {
            quality: 1,
            quality_value: 0
        },
    ]

    useEffect(()=>{
        handleSetColor();
    },[rallyItem])


    const handleSetColor = () => {
        if (rallyItem.action === 1){ //ser
            if (rallyItem.quality === 5) setColor(qualitiesFive.four.color) //green
            if (rallyItem.quality === 4) setColor(qualitiesFive.three.color) //light green
            if (rallyItem.quality === 3) setColor(qualitiesFive.two.color) //yellow
            if (rallyItem.quality === 2) setColor(qualitiesFive.one.color) //orange
            if (rallyItem.quality === 1) setColor(qualitiesFive.zero.color) //red
        }
        if (rallyItem.action === 2){ //rec
            if (rallyItem.quality === 5) setColor(qualitiesFour.three.color) //green
            if (rallyItem.quality === 4) setColor(qualitiesFour.three.color) //green
            if (rallyItem.quality === 3) setColor(qualitiesFour.two.color) //light green
            if (rallyItem.quality === 2) setColor(qualitiesFour.one.color) //orange
            if (rallyItem.quality === 1) setColor(qualitiesFour.zero.color) //red
        }
        if (rallyItem.action >= 3){ //set, att, blo, dig
            if (rallyItem.quality === 3) setColor(qualitiesThree.two.color) //green
            if (rallyItem.quality === 2) setColor(qualitiesThree.one.color) //yellow
            if (rallyItem.quality === 1) setColor(qualitiesThree.zero.color) //red
        }
    }

    const footer = () => {
        return (
            <div className='flex gap-2 justify-content-end'>
                <Button severity='danger' onClick={()=>handleCancel()}>Close</Button>
                <Button severity='success' onClick={()=>handleSave()}>Save</Button>
            </div>
        )
    }

    const removeAction = async () => {
        deleteActionPlayer(rallyItem.id).then((isSuccess)=>{
            if (isSuccess) {
                toast.success("Action deleted!") 
                refreshData()
            } else {
                toast.error("Action not deleted!")
            }
        })
        handleCloseDialog()
    }

    const handleCloseDialog = () => {
        setVisible(false)
    }

    const handleSave = async () => {
        updateActionPlayer(rallyItem.id, selectedAction.action, selectedPlayer.player_id, selectedQuality.quality).then((isSuccess)=>{
            if (isSuccess) {
                toast.success("Action updated!") 
                refreshData()
            } else {
                toast.error("Action not updated!")
            }
        })
        handleCloseDialog()
    }

    const handleCancel = () => {
        handleCloseDialog()
    }
    
    return (
        <div>
            <Dialog header={`Edit: ${selectedPlayer?.number} ${selectedAction?.action_number}`} visible={visible} onHide={()=>setVisible(false)} footer={footer}>
                <div className='flex flex-wrap gap-4 align-items-end'>
                    {gridData?.statisticsType !== 'player' && 
                        <div className='flex flex-column'>
                            <label htmlFor='player'>Player</label>
                            <Dropdown onChange={(e)=>setSelectedPlayer(e.value)} name="player" 
                            options={playersAState} value={selectedPlayer} optionLabel='number' />
                        </div>
                    }
                    <div className='flex flex-column'>
                        <label htmlFor='action'>Action</label>
                        <Dropdown onChange={(e)=>setSelectedAction(e.value)} name="action" 
                        options={actions} value={selectedAction} optionLabel='action_number' />
                    </div>
                    <div className='flex flex-column'>
                        <label htmlFor='quality'>Quality</label>
                        <Dropdown onChange={(e)=>setSelectedQuality(e.value)} name="quality" 
                        options={qualities} value={selectedQuality} optionLabel='quality_value' />
                    </div>
                    <div className='flex flex-column'>
                        <Button onClick={removeAction} label='Delete' severity='danger' />
                    </div>
                </div>
            </Dialog>
            <div className='flex flex-column gap-1 mt-2'>
                
                <div style={{backgroundColor: color, userSelect: 'none', WebkitUserSelect: 'none'}} className='border-round' onClick={()=>setVisible(true)}>
                    <div className="flex justify-content-center align-items-center text-s p-2 text-sm h-3rem font-bold">{rallyItem?.number}: {rallyItem?.action_number}</div>
                </div>
                
            </div>
        </div>
    )
}

export default RallyActionsItemComponent