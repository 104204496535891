import React, { useEffect, useState } from 'react'
import Navbar from '../../../components/Navbar/Navbar'
import Footer from '../../../components/Footer/Footer'
import './HomeLandingPage.css'
import MapConnections from '../../../images/map_connections.png'
import HappyTeamImage from '../../../images/happy_team.jpg'
import FeaturesImage from '../../../images/features.png'
import ConnectPlayersImage from '../../../images/connect_players.jpg'
import { PiChatsBold, PiMagnifyingGlassBold, PiSparkleBold } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import ReactGA from 'react-ga4'
import { listUsersByFilters } from '../../../services/UserService'
import { listEvents } from '../../../services/EventService'
import IPData from 'ipdata'
import { RequestType, geocode, setDefaults } from 'react-geocode'
import EventItemListComponent from '../../app_pages/Event/EventComponent/EventItemListComponent/EventItemListComponent'
import PlayerItemListComponent from '../../app_pages/Player/PlayerComponent/PlayerItemListComponent/PlayerItemListComponent'
import { TabPanel, TabView } from 'primereact/tabview'
import { toast } from 'react-toastify'

function LandingPage() {
  ReactGA.send({ hitType: "pageview", page: "/", title: "Home Landing" });
  const [isLoadingEvent, setIsLoadingEvent] = useState(false)
  const [isLoadingPlayer, setIsLoadingPlayer] = useState(false)
  const [eventList, setEventList] = useState()
  const [playerList, setPlayerList] = useState()
  const navigate = useNavigate()
  const [lat, setLat] = useState()
  const [lng, setLng] = useState()

  setDefaults({
    key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // Your API key here.
    language: "en", // Default language for responses.
    region: "us", // Default region for responses.
  });

  useEffect(()=>{
    getLatLng()
  },[])

  useEffect(()=>{
    lat && lng && handleListEvents()
    lat && lng && handleListPlayers()
  },[lat, lng])

  const getLatLng = async () => {
    const ipdata = new IPData(process.env.REACT_APP_IPDATA)
    var zipcode = ''
    await ipdata.lookup().then((info)=>{
        zipcode = info.postal
    })
    zipcode = zipcode || '94804, US' 
    await geocode(RequestType.ADDRESS, zipcode)
      .then(async ({ results }) => {
          const { lat, lng } = results[0].geometry.location;
          setLat(lat)
          setLng(lng)
      })
      .catch((error) => {
          toast.error('Sorry. An error ocurred.')
      });
  }


  const handleListPlayers = async () => {
    setIsLoadingPlayer(true)
    await listUsersByFilters(
        '', 
        null,
        {lat: lat, lng:lng}, 3000, 
        null, null, null, 
        1).then(async (response)=>{
          await setPlayerList(response?.data.slice(0,3))
        })
    setIsLoadingPlayer(false)
  }

  const handleListEvents = async () => {
    setIsLoadingEvent(true)
    var _date = new Date()
    _date.setDate(_date.getDate() + 30)
    const response = await listEvents(
        '', 
        new Date(), _date, 
        null, 
        {lat: lat, lng: lng}, 3000, 
        null )
    await setEventList(response?.data.slice(0,3))
    setIsLoadingEvent(false)

  }

  return (
    <>
    <Navbar />
    <div className="hero">
      <div className="hero--texts">
        <div className="flex flex-wrap md:flex-nowrap lg:flex-wrap gap-4 flex-column">
          <div className="text--heading--h2">
            Connect with players and join the ultimate sports community
          </div>
          <div className="text--regular--normal">
            Discover new competitions, showcase your skills, and connect with other players on Leagr. Join us today and take your sports journey to the next level.
          </div>
          <Button label='Register to access the full list' onClick={()=>navigate('/register')} className='w-max align-self-center' />
          <div className="">
            <TabView className='w-full'>
                <TabPanel header='Nearest Events' className='flex w-12 justify-content-center'>
                    <div className="flex flex-column gap-2">
                        <div className="grid">
                            {isLoadingEvent ? 'Loading nearest events...' : 
                            eventList?.length && eventList.map((item, key)=>(
                                <EventItemListComponent key={key} data={item} />
                            ))
                            }
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header='Nearest Players' className='flex w-full justify-content-center'>
                    <div className="flex flex-column gap-2 w-full">
                        <div className="grid">
                            {isLoadingPlayer ? 'Loading nearest players...' : 
                            playerList?.length && playerList.map((item, key)=>(
                                <PlayerItemListComponent key={key} data={item} />
                            ))}
                        </div>
                    </div>
                </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="benefits--content">
        <div className="benefits--texts">
          <div className='text--heading--h3'>Connecting Players and Competitions: Find Your Perfect Match on Leagr</div>
          <div className='text--medium--normal'>Leagr is the ultimate marketplace for players to connect with each other and discover exciting competitions. Whether you're looking for a team or searching for the perfect opponent, Leagr has got you covered. Join our community today and take your sports journey to the next level.</div>
        </div>
        <iframe className='w-19rem lg:w-30rem h-19rem lg:h-30rem border-round'
          src="https://youtube.com/embed/eX05rgDglK0" 
          title="LEAGR Highlights" frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media;
          gyroscope;
          web-share"></iframe>
      </div>
    </div>

    <div className="container" style={{backgroundColor: '#CCD9E5'}}>
      <div className="features--content">
        <img src={FeaturesImage} alt="happy team" className='image--resizable' style={{borderRadius: '0px'}} />
        <div className="features--texts">
          <div className="text--heading--h3">
            Find players and events, showcase your skills
          </div>
          <div className="text--medium--normal">
            Leagr is the ultimate platform for players to find teams, showcase their skills, and participate in exciting competitions. With Leagr, you can connect with other players, create a profile to share your achievements, photos, and videos, and register your interest in playing in a competition. Join Leagr today and take your game to the next level.
          </div>
          <Button onClick={()=>navigate('/register')}>CREATE YOUR PROFILE</Button>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="steps--content">
        <div className="steps--title text--heading--h3">
          Discover and connect with players through detailed profiles
        </div>
        <div className="steps--texts">
          <div className="steps--step">
            <PiMagnifyingGlassBold className='icons'/>
            <div className="steps--step--title text--heading--h5">Find competitions and tournaments that match your interests</div>
            <div className="steps--step--text text--regular--normal">Discover and participate in a wide variety of sports competitions that are located near you.</div>
          </div>
          <div className="steps--step">
            <PiChatsBold className='icons'/>
            <div className="steps--step--title text--heading--h5">Connect and chat with other players to form teams</div>
            <div className="steps--step--text text--regular--normal">Create connections with people, form friendships, and most importantly, enjoy yourself during this enriching experience.</div>
          </div>
          <div className="steps--step">
            <PiSparkleBold className='icons'/>
            <div className="steps--step--title text--heading--h5">Show off your skills and achievements on your player profile</div>
            <div className="steps--step--text text--regular--normal">Create your player profile to showcase your skills, achievements, and more.</div>
          </div>
        </div>
      </div>
    </div>

    <div className="container" style={{backgroundColor: '#D6D6D6'}}>
      <div className="connect--content">
        <div className="connect--texts">
          <div className="text--heading--h2">Connect with other players today</div>
          <div className="text--medium--normal">Create a player profile and join the vibrant sports community</div>
          <Button className='w-max' onClick={()=>navigate('/register')}>CREATE YOUR PROFILE</Button>
        </div>
        <img src={ConnectPlayersImage} alt="players chatting" className='image--resizable' />
      </div>
    </div>
    <Footer />
    </>
  )
}

export default LandingPage