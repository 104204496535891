import React from 'react'
import './InfoWindowMapPlayer.css'
import SportsIconComponent from '../SportsIconComponent/SportsIconComponent'
import { PiChats } from 'react-icons/pi'

function InfoWindowMapPlayer({data}) {
    return (
        data && 
        <div className="info_window_map_player--container">
            <div className="info_window_map_player--head">
                <div className="player_item_list--head--left">
                    <div className="text--large--bold">{data.first_name} {data.last_name}</div>
                    <div className="text--regular--normal">Lives in {data.city}, {data.state}</div>
                </div>
                <div className="player_item_list--head--right">
                    <a href={'/app/player_detail_about/'+data.access_key} className="info_window_map_player--head--icon_frame">
                        <div className="text--xsmall--normal" style={{color: '#FFF'}}>More</div>
                    </a>
                </div>
            </div>
            <div className="player_item_list--content">
                    <div className="player_item_list--set">
                        <div className="text--regular--normal">Sport (Rating):</div>
                        {
                            data && data?.sports?.map((item, key)=>(
                                <div key={key} className="text--regular--bold">
                                    {item.sport_name} ({item.sport_rating+'): '} 
                                    <span className="text--regular--normal">
                                        {item.skills.map(subitem=>subitem.skill_name+': '+subitem.skill_rating).join(', ')}
                                    </span>
                                </div>
                            ))                            
                        }
                    </div>
                </div>
        </div>
    )
}

export default InfoWindowMapPlayer