
export const qualitiesFive = {
    four: {
        id: 5,
        number: 4,
        courtPosition: 1,
        label: "A",
        color:"#32CD32",
    },
    three: {
        id: 4,
        number: 3,
        courtPosition: 2,
        label: "B",
        color:"#ADFF2F",
    },
    two: {
        id: 3,
        number: 2,
        courtPosition: 3,
        label: "C",
        color:"#FFFF00",
    },
    one: {
        id: 2,
        number: 1,
        courtPosition: 4,
        label: "D",
        color:"#FFA500",
    },
    zero: {
        id: 1,
        number: 0,
        courtPosition: 5,
        label: "E",
        color:"#FF4C4C",
    },
}

export const qualitiesFour = {
    three: {
        id: 4,
        number: 3,
        label: "A",
        courtPosition: 1,
        color:"#32CD32",
    },
    two: {
        id: 3,
        number: 2,
        label: "B",
        courtPosition: 2,
        color:"#ADFF2F",
    },
    one: {
        id: 2,
        number: 1,
        courtPosition: 3,
        label: "C",
        color:"#FFA500",
    },
    zero: {
        id: 1,
        number: 0,
        courtPosition: 4,
        label: "D",
        color:"#FF4C4C",
    },
}

export const qualitiesThree = {
    two: {
        id: 3,
        number: 2,
        label: "A",
        courtPosition: 1,
        color:"#32CD32",
    },
    one: {
        id: 2,
        number: 1,
        courtPosition: 2,
        label: "B",
        color:"#FFFF00",
    },
    zero: {
        id: 1,
        number: 0,
        courtPosition: 3,
        label: "C",
        color:"#FF4C4C",
    }
}

export const qualitiesServe = {
    point: {
        id: 5,
        number: 4,
        courtPosition: 5,
        label: "Ace/Point",
        color:"#2f4858",
    },
    freeBall: {
        id: 4,
        number: 3,
        courtPosition: 3,
        label: "Free return",
        color:"#33658a",
    },
    setPin: {
        id: 3,
        number: 2,
        courtPosition: 2,
        label: "Fair",
        color:"#86bbd8",
    },
    setMiddle: {
        id: 2,
        number: 1,
        courtPosition: 1,
        label: "Poor",
        color:"#f6ae2d",
    },
    error: {
        id: 1,
        number: 0,
        courtPosition: 4,
        label: "Error",
        color:"#f26419",
    },
}

export const qualitiesReception = {
    four: {
        id: 5,
        number: 4,
        label: "Perfect",
        courtPosition: 1,
        color:"#32CD32",
    },
    three: {
        id: 4,
        number: 3,
        label: "Set middle",
        courtPosition: 2,
        color:"#ADFF2F",
    },
    two: {
        id: 3,
        number: 2,
        label: "Set pin",
        courtPosition: 3,
        color:"",
    },
    one: {
        id: 2,
        courtPosition: 4,
        number: 1,
        label: "Free ball",
        color:"#FFA500",
    },
    zero: {
        id: 1,
        courtPosition: 5,
        number: 0,
        label: "Error",
        color:"#FF4C4C",
    },
}

export const qualitiesBlock = {
    two: {
        id: 3,
        label: "Point",
        number: 2,
        courtPosition: 1,
        color:"#32CD32",
    },
    one: {
        id: 2,
        label: "Touch",
        number: 1,
        courtPosition: 2,
        color:"#FFFF00",
    },
    zero: {
        id: 1,
        label: "Error",
        number: 0,
        courtPosition: 3,
        color:"#FF4C4C",
    }
}

export const qualitiesAttack = {
    two: {
        id: 3,
        number: 2,
        label: "Point",
        courtPosition: 1,
        color:"#32CD32",
    },
    one: {
        id: 2,
        number: 1,
        label: "Rally",
        courtPosition: 2,
        color:"#FFFF00",
    },
    zero: {
        id: 1,
        number: 0,
        label: "Error",
        courtPosition: 3,
        color:"#FF4C4C",
    }
}

export const qualitiesDig = {
    two: {
        id: 3,
        label: "Can set",
        courtPosition: 1,
        color:"#32CD32",
    },
    one: {
        id: 2,
        label: "Free ball",
        courtPosition: 2,
        color:"#FFFF00",
    },
    zero: {
        id: 1,
        label: "Error",
        courtPosition: 3,
        color:"#FF4C4C",
    }
}

export const qualitiesSet = {
    two: {
        id: 3,
        label: "0/1 block",
        courtPosition: 1,
        color:"#32CD32",
    },
    one: {
        id: 2,
        label: "2/3 block",
        courtPosition: 2,
        color:"#FFFF00",
    },
    zero: {
        id: 1,
        label: "Error",
        courtPosition: 3,
        color:"#FF4C4C",
    }
}

