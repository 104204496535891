import React, { useContext, useEffect, useState } from 'react'
import {confirmDialog} from 'primereact/confirmdialog'
import AuthContext from '../../context/AuthContext/AuthContext'
import { listLeagueByName, newLeague } from '../../services/LeagueService'
import { AutoComplete } from "primereact/autocomplete";
import { PiWarningCircle } from 'react-icons/pi';

function LeagueAutocompleteComponent({league, setLeague, size=''}) {

    const {userAuth} = useContext(AuthContext)
    const [leagues, setLeagues] = useState([])
    const [leagueSelected, setLeagueSelected] = useState(null)
    const [filteredLeagues, setFilteredLeagues] = useState(null)
    const [name, setName] = useState('')

    useEffect(() => {
        handleListLeaguesByName(name);
    }, [name]);

    useEffect(()=>{
        setLeagueSelected(league?.name)
    },[league])

    useEffect(()=>{
        if (leagues.length){
            setFilteredLeagues(leagues);
        } else {
            setFilteredLeagues([{
                access_key: 0,
                name: 'Create '+ name
            }])
        }
    },[leagues])
    
    const handleListLeaguesByName = async (_name = '') => {
        setLeagues(await listLeagueByName(name))
    }

    const search = async (event) => {
        var _name = event.query.charAt(0).toUpperCase() + event.query.slice(1)
        await setName(_name)
    }

    const handleLeagueOnChange = async (e) => {
        if (typeof e.value === "object"){
            if (e.value.access_key === 0){
                confirmDialog({
                    message: `Do you want to create the league ${name}?`,
                    header: 'Confirm?',
                    icon: <PiWarningCircle />,
                    defaultFocus: 'accept',
                    accept,
                    reject
                });
            } else {
                await setLeagueSelected(e.value.name)
                await setLeague(e.value)
            }
        } else {
            await setLeagueSelected(e.value)
        }
    }
      
    const accept = () => {
        if (name && userAuth.user.access_key){
            newLeague(name, userAuth.user.access_key).then(async(access_key)=>{
                if (access_key){
                    await setLeague({access_key, name})
                    await setLeagueSelected(name)
                }
            });
        }
    }

    const reject = async () => {
        
    }

    const itemTemplate = (item) => {
        return(item.name)
    }

    return (
        <>
            <AutoComplete 
                className={`flex ${size}`}
                placeholder="Enter the league name..."
                inputStyle={{width: '100%'}} style={{width: '100%'}}
                minLength={3}
                field="name" 
                value={leagueSelected} 
                suggestions={filteredLeagues} 
                completeMethod={search} 
                onChange={handleLeagueOnChange} 
                itemTemplate={itemTemplate}
            />
        </>
    )
}

export default LeagueAutocompleteComponent