import axios from 'axios';
import { toast } from 'react-toastify';

export const newSkill = async (name, user_access_key, sport_access_key) =>{
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/skill/create`, {name, user_access_key, sport_access_key})
        .then((response)=>{
            toast.success(`Skill ${name} created!`)
            return response.data.access_key
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('An error ocurred.')
    }
}

export const listSkillByNameSport = async (name = '', sportAccessKey = '') =>{
    try {
        if (sportAccessKey){
            return await axios.get(`${process.env.REACT_APP_API_URL}/api/skill/list_by_name_sport/${sportAccessKey}/${name}`)
            .then((response)=>{
                return response.data
            }).catch((error)=>{
                toast.error(error.response.data.message)
                return false
            })
        }
    } catch (error) {
        toast.error('An error ocurred.')
    }
}

