import { Button } from 'primereact/button'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../../context/AuthContext/AuthContext'
import { deleteMatch, listMyStats } from '../../../services/StatsService'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { PiNotePencil, PiTrash, PiUser, PiUsersFour, PiUsersThree, PiWarningCircle } from 'react-icons/pi'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { PresentationChart } from '@phosphor-icons/react'
import { Paginator } from 'primereact/paginator'
import PaginatorComponent from '../../../components/PaginatorComponent/PaginatorComponent'
import { Dropdown } from 'primereact/dropdown'

function MyStatistics() {
    const navigate = useNavigate()
    const {userAuth} = useContext(AuthContext)
    const [matches, setMatches] = useState()
    const [page, setPage] = useState(1)
    const [type, setType] = useState('all')
    const [total, setTotal] = useState(0)
    const types = [
        "all",
        "practice",
        "game",
        "player"
    ]
    
    // useEffect(()=>{
    //     userAuth && handleMyListStats()
    // },[])

    const handleURL = (_match) => {
        if (_match?.statystics_type === 'full' || _match?.statystics_type === 'game'){
            return `/app/rotations_stats/${_match?.access_key}`
        } else if (_match?.statystics_type === 'player') {
            return '/app/show_stats'
        } else if (_match?.statystics_type === 'practice') {
            return '/app/practice_stats/'+_match?.access_key
        }
    }

    const handleMyListStats = () => {
        userAuth && listMyStats(userAuth.user.access_key, page, type).then((res)=>{
            if (res && res.data){
                setTotal(res.total)
                var _matches = [...res.data]
            
                setMatches(_matches.map((item)=>(
                    {
                        ...item,
                        statsUrl: handleURL(item)
                    }
                )))
            }
        })
    }

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Title" align={'center'} />
                <Column header="Type" align={'center'} />
                <Column header="Actions" align={'center'} />
            </Row>
        </ColumnGroup>
    );

    const handleConfirmDelete = (item) => {
        confirmDialog({
            message: `Do you want to delete the match ${item.title}?`,
            header: 'Confirm?',
            icon: <PiWarningCircle />,
            defaultFocus: 'accept',
            accept: ()=>accept(item),
            reject
        });
    }

    const reject = () => {
    }

    const accept = (item) => {
        deleteMatch(item.access_key).then((res)=>{
            handleMyListStats()
        })
    }
    
    useEffect(()=>{
        handleMyListStats()
    },[page, type])
    
    return (
        <div className='flex flex-column gap-4'>
            <ConfirmDialog />
            <div className='flex gap-2 justify-content-between align-items-end'>
                <div className='flex flex-column gap-2'>
                    <label htmlFor='type'>Filter by type</label>
                    <Dropdown name='type' value={type} options={types} onChange={(e) => {setPage(1); setType(e.value)}} placeholder='Filter by type' />
                </div>
                <Button className='h-min' label='Start new stats' onClick={()=>navigate('/app/start_stats')} />
            </div>
            <div>
                <div className='font-bold text-2xl mb-2'>My Matches</div>
                <DataTable showGridlines stripedRows value={matches} headerColumnGroup={headerGroup} tableStyle={{ minWidth: '10rem' }}>
                    <Column align={'left'} field="title" className='p-2' />
                    <Column align={'center'} body={(_match=>
                        <div>
                            {_match.statystics_type}
                        </div>
                    )} className='p-2' />
                    <Column className='p-2' align={'right'} body={(_match)=>
                        <div className='flex gap-1 justify-content-center gap-3'>
                            <Button size='small' className='p-2' onClick={()=>handleConfirmDelete(_match)}><PiTrash size={24} /></Button>
                            <a href={'/app/statistics/'+_match.access_key} target="_blank" rel="noopener noreferrer" className="p-button font-bold p-2">
                                <PiNotePencil size={24} style={{color: 'white'}} />
                            </a>
                            <a href={_match.statsUrl} target="_blank" rel="noopener noreferrer" className="p-button font-bold p-2">
                                <PresentationChart size={24} style={{color: 'white'}} />
                            </a>
                        </div>
                    } />
                </DataTable>
                <div className='mt-2'>
                    <PaginatorComponent page={page} total={total} setPage={setPage} />
                </div>
            </div>
        </div>
    )
}

export default MyStatistics