import React, { useContext, useEffect, useState } from 'react'
import { listRallyActionsByMatch } from '../../../../services/StatsService'
import StatisticsContext from '../StatisticsContext'
import PaginatorComponent from '../../../../components/PaginatorComponent/PaginatorComponent'
import RallyActionsItemComponent from './RallyActionsItemComponent'

function RallyActionsComponent() {
    const {gridData, currentRallyContext} = useContext(StatisticsContext)
    const [page, setPage] = useState(1)
    const [rally, setRally] = useState()
    const [total, setTotal] = useState(0)

    useEffect(()=>{
        if (currentRallyContext?.length === 0 && gridData?.matchAccessKey){
            setPage(1)
            handleListRallyActions()
        } 
    },[currentRallyContext])

    useEffect(()=>{
        handleListRallyActions()
    },[page])

    const handleListRallyActions = () => {
        setRally()
        listRallyActionsByMatch(gridData?.matchAccessKey, page).then((res)=>{
            setTotal(res.total)
            setRally(res.data)
        })
    }

    return (
        rally ? <div>
            <div className='flex flex-wrap justify-content-between gap-2'>
            {
                rally?.map((item, key)=>(
                    <RallyActionsItemComponent refreshData={handleListRallyActions} key={key} rallyItem={item}/>
                ))
            }
            </div>
            <div className='mt-2'>
                <PaginatorComponent page={page} total={total} setPage={setPage} />
            </div>
        </div> : "Loading..."
    )
}

export default RallyActionsComponent