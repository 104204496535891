import { toast } from 'react-toastify';
import axios from 'axios';

export const newEvent = async (name, user_access_key, league_access_key) =>{
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/event/create`, {name, user_access_key, league_access_key})
        .then((response)=>{
            toast.success(`Event ${name} created!`)
            return response.data.access_key
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        console.log(error)
        toast.error('An error ocurred.')
    }
}

export const newEventDetails = async (event, user_access_key) =>{
    try {
        if (event){
            event.endDate = event?.endDate ? new Date(event.endDate) : event?.endDate
            event.startDate = event?.startDate ? new Date(event.startDate) : event?.startDate
            return await axios.post(`${process.env.REACT_APP_API_URL}/api/event/create`, {...event, user_access_key})
            .then((response)=>{
                toast.success(`Event ${event.name} created!`)
                return response.data.access_key
            }).catch((error)=>{
                toast.error(error.response.data.message)
                return false
            })
        } else {
            toast.error("Event's data invalid.")
        }
        // return docRef
    } catch (error) {
        console.log(error)
        toast.error("An error ocurred.")
    }
}

export const updateEventDetails = async (event, access_key, user_access_key) =>{
    try {
        if (event){
            event.endDate = event?.endDate ? new Date(event.endDate) : event?.endDate
            event.startDate = event?.startDate ? new Date(event.startDate) : event?.startDate
            return await axios.put(`${process.env.REACT_APP_API_URL}/api/event/update`, {...event, access_key})
            .then((response)=>{
                toast.success(`Event ${event.name} updated!`)
                return response.data.access_key
            }).catch((error)=>{
                toast.error(error.response.data.message)
                return false
            })
        } else {
            toast.error("Event's data invalid.")
        }
        // return docRef
    } catch (error) {
        console.log(error)
        toast.error("An error ocurred.")
    }
}

export const listEvents = async (
    name, 
    startDate, endDate, 
    mapBoundary, 
    addressMarker, miles, 
    sport, league, page = 1 ) =>{
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/event/list_by_filters/`, {
            name, 
            startDate, endDate, 
            mapBoundary, 
            addressMarker, miles, 
            sport, league, page})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const sendEmailEvents = async (access_key, zipcode_start, zipcode_end) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/event/send_email_events`, { access_key, zipcode_start, zipcode_end })
        .then((response)=>{
            toast.success('Emails sent.')
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const listEventsByName = async (name = '') => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/event/list_by_name/${name}`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const getEventByAccessKey = async (access_key) => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/event/get_by_access_key/${access_key}`)
        .then((response)=>{
            return response.data && response.data[0]
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const listEventsByLeague = async (access_key) => {
    if (access_key){
        try {
            return await axios.get(`${process.env.REACT_APP_API_URL}/api/event/list_by_league/${access_key}`)
            .then((response)=>{
                return response.data
            }).catch((error)=>{
                toast.error(error.response.data.message)
                return false
            })
        } catch (error) {
            toast.error('An error ocurred.')
        }
    } else {
        return null;
    }
}

