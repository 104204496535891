import React from 'react'
import StartStatsPlayerComoponent from './StartStatsPlayerComponent'
import { TabView, TabPanel } from 'primereact/tabview';
import StartStatsGameComponent from './StartStatsGameComponent'
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import StartStatsPracticeComponent from './StartStatsPracticeComponent';

function StartStats() {
    const navigate = useNavigate()
    return (
        <div className="m-2">
            <Button label='Return to Stats Home' onClick={()=>navigate('/app/mystats')} />
            <TabView>
                <TabPanel header="Player Statistics">
                    <StartStatsPlayerComoponent />
                </TabPanel>
                <TabPanel header="Rotations Statistics">
                    <StartStatsGameComponent />
                </TabPanel>
                <TabPanel header="Individual Statistics">
                    <StartStatsPracticeComponent />
                </TabPanel>
            </TabView>
        </div>
    )
}

export default StartStats