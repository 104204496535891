import React, { useContext, useEffect, useState } from 'react'
import {confirmDialog} from 'primereact/confirmdialog'
import AuthContext from '../../context/AuthContext/AuthContext'
import { listEventsByName, newEvent } from '../../services/EventService'
import { AutoComplete } from "primereact/autocomplete";
import { PiWarningCircle } from 'react-icons/pi';

function EventAutocompleteComponent({event, setEvent, size='', league_access_key = '', invalid = false}) {

    const {userAuth} = useContext(AuthContext)
    const [events, setEvents] = useState([])
    const [eventSelected, setEventSelected] = useState(null)
    const [filteredEvents, setFilteredEvents] = useState(null)
    const [name, setName] = useState('')

    useEffect(() => {
        handleListEventsByName(name);
    }, [name]);

    useEffect(()=>{
        setEventSelected(event?.name)
    },[event])

    useEffect(()=>{
        if (events.length){
            setFilteredEvents(events);
        } else {
            setFilteredEvents([{
                access_key: 0,
                name: 'Create '+ name
            }])
        }
    },[events])
    
    const handleListEventsByName = async (_name = '') => {
        setEvents(await listEventsByName(name))
    }

    const search = async (event) => {
        var _name = event.query.charAt(0).toUpperCase() + event.query.slice(1)
        await setName(_name)
    }

    const handleEventOnChange = async (e) => {
        if (typeof e.value === "object"){
            if (e.value.access_key === 0){
                confirmDialog({
                    message: `Do you want to create the event ${name}?`,
                    header: 'Confirm?',
                    icon: <PiWarningCircle />,
                    defaultFocus: 'accept',
                    accept,
                    reject
                });
            } else {
                await setEventSelected(e.value.name)
                await setEvent(e.value)
            }
        } else {
            await setEventSelected(e.value)
        }
    }
      
    const accept = () => {
        if (name && userAuth.user.access_key){
            newEvent(name, userAuth.user.access_key, league_access_key).then(async(access_key)=>{
                if (access_key){
                    await setEvent({access_key, name})
                    await setEventSelected(name)
                }
            });
        }
    }

    const reject = async () => {
        
    }

    const itemTemplate = (item) => {
        return(item.name)
    }

    return (
        <>
            <AutoComplete 
                className={`flex ${size} ${invalid && 'p-invalid'}`}
                placeholder="Enter the event name..."
                inputStyle={{width: '100%'}} style={{width: '100%'}}
                minLength={3}
                field="name" 
                value={eventSelected} 
                suggestions={filteredEvents} 
                completeMethod={search} 
                onChange={handleEventOnChange} 
                itemTemplate={itemTemplate}
            />
        </>
    )
}

export default EventAutocompleteComponent