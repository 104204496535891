import React, { useState } from 'react'
import AppBodyComponent from '../../../../components/AppBody/AppBodyComponent'
import './EventForm.css'
import { PiCaretLeft, PiCaretLeftBold } from 'react-icons/pi';
import EventFormComponent from '../../../../components/EventFormComponent/EventFormComponent';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';

function EventForm() {

    const {access_key} = useParams()
    const navigate = useNavigate();

    return (
        <AppBodyComponent activeMenuNumber={2}>
            <div className="event_form--container">
                <div className="event_form--actions">
                    <Button size='small' icon={<PiCaretLeftBold />} onClick={()=>navigate("/app/event_list")} />
                </div>
                <div className='form--container'>
                    <EventFormComponent access_key={access_key} />
                </div>
            </div>
        </AppBodyComponent>
    )
}

export default EventForm