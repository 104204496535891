import React, { useEffect, useState } from 'react'
import AppBodyComponent from '../../../../components/AppBody/AppBodyComponent'
import { APIProvider, AdvancedMarker, Map } from '@vis.gl/react-google-maps'
import MarkerMap from '../../../../components/MarkerMap/MarkerMap'
import './EventDetail.css'
import { PiCaretLeft } from 'react-icons/pi'
import { useNavigate, useParams } from 'react-router-dom'
import { getEventByAccessKey } from '../../../../services/EventService'
import { Button } from 'primereact/button'
import { Tag } from 'primereact/tag';
import { Pencil } from '@phosphor-icons/react'
import ReactGA from 'react-ga4'

function EventDetail() {

    ReactGA.send({ hitType: "pageview", page: "/event_detail", title: "Event Detail" });
    const {access_key} = useParams()
    const language = navigator.language

    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    const [item, setItem] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();

    useEffect(()=>{
        handleGetEventByAccessKey()
    },[access_key])

    useEffect(()=>{
        item && ReactGA.event({
            category: "Event",
            action: "Detail",
            label: item.name
        })
    },[item])

    const handleGetEventByAccessKey = async () => {
        setIsLoading(true)
        await setItem(await getEventByAccessKey(access_key))
        setIsLoading(false)
    }

    const handleJoinEvent = () => {

    }

    return (
        <AppBodyComponent activeMenuNumber={2}>
            {isLoading ? 'Loading...' : item ? 
            <div className="event_detail--container">
                <div className="event_detail--actions">
                    <Button onClick={()=>navigate(-1)} outlined size='small'><PiCaretLeft /></Button>
                    <div className="text--heading--h5" style={{textAlign: 'center'}}>{item.name}</div>
                    <Button onClick={()=>navigate('/app/event_form/'+access_key)} size='small'><Pencil size={'16px'} /></Button>
                    {/* <button onClick={handleJoinEvent} type="button" className="button--primary"><PiPlusCircle /> Join the event's community</button> */}
                </div>
                <div className="event_detail--body">
                    <div className="event_detail--menu">
                        <div className="event_detail--menu--head">
                            <div className="event_detail--menu_image">
                                <APIProvider apiKey={googleMapsApiKey}>
                                    <Map disableDefaultUI zoom={10} mapId={'event_list_map_id'}
                                    center={{lat: parseFloat(item.lat), lng: parseFloat(item.lng)}}>
                                        <AdvancedMarker position={{lat: parseFloat(item.lat), lng: parseFloat(item.lng)}}>
                                            <MarkerMap size='medium' type='sport' sport={item.sport_name} />
                                        </AdvancedMarker>
                                    </Map>
                                </APIProvider>
                            </div>
                            <div className="text--small--normal" style={{textAlign: 'center'}}>
                                {item.address || <Tag value="add info" onClick={()=>navigate('/app/event_form/'+access_key)} />}
                            </div>
                        </div>
                        <div className="event_detail--menu--items">
                            <div className="event_detail--menu--items--set">
                                <div className="text--regular--bold" style={{}}>Organized by</div>
                                <div className="text--medium--normal">{item.league_name ||  <Tag value="add info" onClick={()=>navigate('/app/event_form/'+access_key)} />}</div>
                            </div>
                            <div className="event_detail--menu--items--set">
                                <div className="text--regular--bold">Start date</div>
                                <div className="text--medium--normal">{item.start_date ? new Date(item.start_date).toLocaleDateString(language, {timeZone:"UTC"}) : <Tag value="add info" onClick={()=>navigate('/app/event_form/'+access_key)} />}</div>
                            </div>
                            <div className="event_detail--menu--items--set">
                                <div className="text--regular--bold">End date</div>
                                <div className="text--medium--normal">{item.end_date ? new Date(item.end_date).toLocaleDateString(language, {timeZone:"UTC"}) :  <Tag value="add info" onClick={()=>navigate('/app/event_form/'+access_key)} />}</div>
                            </div>
                            <div className="event_detail--menu--items--set">
                                <div className="text--regular--bold">Days</div>
                                <div className="text--medium--normal">{item.days || <Tag value="add info" onClick={()=>navigate('/app/event_form/'+access_key)} />}</div>
                            </div>
                        </div>
                    </div>
                    <div className="event_detail--description">
                        <div className="text--large--bold">Rules and Description</div>
                        <div className="text--regular--normal" dangerouslySetInnerHTML={{__html: item.description || <Tag value="add info" onClick={()=>navigate('/app/event_form/'+access_key)} />}}></div>
                    </div>
                </div>
            </div> : 
            'Event not found.'}
        </AppBodyComponent>
    )
}

export default EventDetail