import React from 'react'
import { PiFacebookLogoBold, PiInstagramLogoBold, PiYoutubeLogoBold } from 'react-icons/pi'
import LeagrLogo from '../../images/leagr_logo_br_129x50.png'
import './Footer.css'

function Footer() {
  return (
    <div className="footer">
      <div className="footer--content">
        <div className="footer--content-one">
          <img src={LeagrLogo} alt="Leagr logo" className='leagr-logo' />
          <div className="footer--contact">
            <span>Email:</span>
            <span>info@leagr.net</span>
          </div>
          <div className="footer--social">
          <a href='https://www.youtube.com/@leagr-app' target='_blank'><PiYoutubeLogoBold className='icons'/></a>
          <a href='https://www.instagram.com/leagr.app/' target='_blank'><PiInstagramLogoBold className='icons'/></a>
          <a href='https://www.facebook.com/profile.php?id=61558114080173' target='_blank'><PiFacebookLogoBold className='icons'/></a>
            {/* <PiInstagramLogoBold className='icons' />
            <PiFacebookLogoBold className='icons' /> */}
          </div>
        </div>
        <div className="footer--content-two">
          <span>Sign Up</span>
          <span>Log In</span>
          <span>Players</span>
          <span>Competitions</span>
          <span>Contact Us</span>
        </div>
      </div>
      <div className="footer--credits">
        <div className="footer--divider"></div>
        <div className="footer--credits-content">
          <div className="footer--policy-terms">
            <span>Privacy Policy</span>
            <span>Terms of Service</span>
            <span>Cookies Settings</span>
          </div>
          <div className="footer--copywrights">
            © 2023 Leagr. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer