import axios from 'axios';
import { toast } from 'react-toastify';

export const newLeague = async (name, user_access_key) =>{
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/league/create`, {name, user_access_key})
        .then((response)=>{
            toast.success(`League ${name} created!`)
            return response.data.access_key
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        console.log(error)
        toast.error('An error ocurred.')
    }
}

export const listLeagueByName = async (leagueName = '') =>{
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/league/list_by_name/${leagueName}`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('An error ocurred.')
    }
}

export const listUserMediasByLeague = async (access_key) => {
    if (access_key){
        try {
            return await axios.get(`${process.env.REACT_APP_API_URL}/api/user_media/list_by_league/${access_key}`)
            .then((response)=>{
                return response.data
            }).catch((error)=>{
                toast.error(error.response.data.message)
                return false
            })
        } catch (error) {
            toast.error('An error ocurred.')
        }
    } else {
        return null;
    }
}

export const getDetailsByAccessKey = async (access_key) => {
    if (access_key){
        try {
            return await axios.get(`${process.env.REACT_APP_API_URL}/api/league/details/${access_key}`)
            .then((response)=>{
                return response.data
            }).catch((error)=>{
                toast.error(error.response.data.message)
                return false
            })
        } catch (error) {
            toast.error('An error ocurred.')
        }
    } else {
        return null;
    }
}

