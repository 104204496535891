import React, { useContext, useEffect, useState } from 'react'
import AppBodyComponent from '../../../../components/AppBody/AppBodyComponent'
import './PlayerDetail.css'
import { PiCaretLeft, PiChats, PiEnvelopeSimpleBold, PiUserCircle } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import ImageUser from '../../../../images/user_image.png'
import PlayerPicture from '../../../../images/user_image_medium.png' 
import { getUserByAccessKey, sendEmailNewMessage } from '../../../../services/UserService'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputTextarea } from 'primereact/inputtextarea'
import AuthContext from '../../../../context/AuthContext/AuthContext'
import ReactGA from 'react-ga4'

function PlayerDetail({children, activeMenuNumber=1, access_key}) {
    ReactGA.send({ hitType: "pageview", page: "/player_detail", title: "Player Detail" });

    const [activeMenu, setActiveMenu] = useState(activeMenuNumber);
    const [message, setMessage] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const navigate = useNavigate();
    const {userAuth} = useContext(AuthContext);
    
    const [user, setUser] = useState()

    useEffect(()=>{
        handleGetUserByAccessKey()
    },[])

    useEffect(()=>{
        user && ReactGA.event({
            category: "Player",
            action: "Detail",
            label: user.first_name + " " + user.last_name
        })
    },[user])


    const handleGetUserByAccessKey = async () => {
        setUser(await getUserByAccessKey(access_key))
    }

    const handleCancelMessage = () => {
        setMessage('')
        setModalIsOpen(false)
    }

    const handleMenu = (number) => {
        setActiveMenu(number);
        number === 1 && navigate('/app/player_detail_about/'+access_key);
        number === 2 && navigate('/app/player_detail_skill/'+access_key);
        number === 3 && navigate('/app/player_detail_history/'+access_key);
        number === 4 && navigate('/app/player_detail_media/'+access_key);
    }

    const openDialog = () => {
        setModalIsOpen(true)
    }

    const handleConfirmMessage = () => {
        setModalIsOpen(false)
        sendEmailNewMessage(
            message, userAuth?.user.access_key,
            `${userAuth?.user.first_name} ${userAuth?.user.last_name}`, 
            user.email, 
            userAuth?.user.email)
    }

    return (
        <AppBodyComponent activeMenuNumber={3}>
            <Dialog header='Send Message' visible={modalIsOpen} onHide={() => setModalIsOpen(false)}
            footer={
                <div>
                    <Button label="Cancel" icon="pi pi-times" onClick={handleCancelMessage} className="p-button-text" />
                    <Button label="Send" icon="pi pi-check" onClick={handleConfirmMessage} autoFocus />
                </div>
            }>
                <InputTextarea value={message} onChange={(e) => setMessage(e.target.value)} rows={5} cols={30} />
            </Dialog>
            <div className="player_detail--container">
                {userAuth?.user ? 
                    <div className="player_detail--actions">
                        <Button outlined onClick={()=>navigate("/app/player_list")} size='small' icon={<PiCaretLeft size={'20px'} />} />
                        <Button size='small' onClick={openDialog} type="button" icon={<PiEnvelopeSimpleBold size={'20px'} />} label='Contact' />
                    </div>
                :
                    <div className="player_detail--actions">
                        <Button outlined onClick={()=>navigate("/register")} size='small' label={'Register to send a message to '+user?.first_name} />
                    </div>
                }
                {user && 
                <div className="player_detail--body">
                <div className="player_detail--menu">
                    <div className="player_detail--menu_image">
                        {user?.picture_url ? <img className='player_detail--menu_image--image' src={user?.picture_url} alt="user" /> : <PiUserCircle size={120}  style={{color: '#FFF'}}/>}
                        <div className="player_detail--menu_image--address">
                            <div className="text--regular--bold" style={{textAlign: 'center'}}>{user?.first_name} {user?.last_name}</div>
                            <div className="text--small--normal" style={{textAlign: 'center'}}>{user?.city}, {user?.state}</div>
                        </div>
                    </div>
                    <div className="player_detail--menu--items">
                        <a role='button' onClick={() => handleMenu(1)} className={'text--regular--bold '+(activeMenu===1 ? 'player_detail--menu--item--selected' : 'player_detail--menu--item')}>
                            About
                        </a>
                        <a role='button' onClick={() => handleMenu(2)} className={'text--regular--bold '+(activeMenu===2 ? 'player_detail--menu--item--selected' : 'player_detail--menu--item')}>
                            Skills
                        </a>
                        <a role='button' onClick={() => handleMenu(3)} className={'text--regular--bold '+(activeMenu===3 ? 'player_detail--menu--item--selected' : 'player_detail--menu--item')}>
                            History
                        </a>
                        <a role='button' onClick={() => handleMenu(4)} className={'text--regular--bold '+(activeMenu===4 ? 'player_detail--menu--item--selected' : 'player_detail--menu--item')}>
                            Media
                        </a>
                    </div>
                </div>
                <div className="player_detail--content">
                    {children}
                </div>
            </div>
            }
            </div>
        </AppBodyComponent>
    )
}

export default PlayerDetail