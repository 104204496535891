import React, { useContext, useEffect, useState } from 'react'
import {confirmDialog} from 'primereact/confirmdialog'
import AuthContext from '../../context/AuthContext/AuthContext'
import { listSportByName, newSport } from '../../services/SportService'
import { AutoComplete } from "primereact/autocomplete";
import { PiWarningCircle } from 'react-icons/pi';

function SportAutocompleteComponent({sport, setSport, size=''}) {

    const {userAuth} = useContext(AuthContext)
    const [sports, setSports] = useState([])
    const [sportSelected, setSportSelected] = useState('')
    const [filteredSports, setFilteredSports] = useState('')
    const [name, setName] = useState('')

    useEffect(() => {
        handleListSportsByName(name);
    }, [name]);

    useEffect(()=>{
        setSportSelected(sport?.name || '')
    },[sport])

    useEffect(()=>{
        if (sports?.length){
            setFilteredSports(sports);
        } else {
            setFilteredSports([{
                access_key: 0,
                name: 'Create '+ name
            }])
        }
    },[sports])
    
    const handleListSportsByName = async (_name = '') => {
        _name && setSports(await listSportByName(_name))
    }

    const search = async (event) => {
        var _name = event.query.charAt(0).toUpperCase() + event.query.slice(1)
        await setName(_name)
    }

    const handleSportOnChange = async (e) => {
        if (typeof e.value === "object"){
            if (e.value.access_key === 0){
                confirmDialog({
                    message: `Do you want to create the sport ${name}?`,
                    header: 'Confirm?',
                    icon: <PiWarningCircle />,
                    defaultFocus: 'accept',
                    accept,
                    reject
                });
            } else {
                await setSportSelected(e.value.name)
                await setSport(e.value)
            }
        } else {
            await setSportSelected(e.value)
        }
    }
      
    const accept = () => {
        if (name && userAuth.user.access_key){
            newSport(name, userAuth.user.access_key).then(async(access_key)=>{
                if (access_key){
                    await setSport({access_key, name})
                    await setSportSelected(name)
                }
            });
        }
    }

    const reject = async () => {
        
    }

    const itemTemplate = (item) => {
        return(item.name)
    }

    return (
        <>
            <AutoComplete 
                className={`flex ${size}`}
                placeholder="Enter the sport name..."
                inputStyle={{width: '100%'}} style={{width: '100%'}}
                minLength={3}
                field="name" 
                value={sportSelected} 
                suggestions={filteredSports} 
                completeMethod={search} 
                onChange={handleSportOnChange} 
                itemTemplate={itemTemplate}
            />
        </>
    )
}

export default SportAutocompleteComponent