import React, { createContext, useEffect, useRef, useState } from 'react'
import { getMatchById } from '../../../services/StatsService';
const StatisticsContext = createContext();

export const StatisticsProvider = ({children}) => {

    // const [gridData, setGridData] = useState(()=>{
    //     const statisticsSession = localStorage.getItem("gridData");
    //     try{
    //         return JSON.parse(statisticsSession)
    //     } catch {
    //         return null
    //     }
    // })

    // useEffect(()=>{
    //     localStorage.setItem("gridData", JSON.stringify(gridData))
    // },[gridData])

    const [gridData, setGridData] = useState()

    const _positions = [
        {
            number: 1,
        },
        {
            number: 6,
        },
        {
            number: 5,
        },
        {
            number: 4,
        },
        {
            number: 3,
        },
        {
            number: 2,
        },
    ]

    const [positions, setPositions] = useState(_positions)
    const [activeTeam, setActiveTeam] = useState(1)
    const [rotationA, setRotationA] = useState(1)
    const [rotationB, setRotationB] = useState(1)
    const [showSelectPosition, setShowSelectionPosition] = useState(false)
    const [playersA, setPlayersA] = useState()
    const [playersB, setPlayersB] = useState()
    const [currentRallyContext, setCurrentRallyContext] = useState([])
    const [gridLayout, setGridLayout] = useState(false)
    const currentActionRefContext = useRef()
    const [setMatchContext, setSetMatchContext] = useState(1) 
    const [enabledAutoRotationContext, setEnabledAutoRotationContext] = useState(true)
    const [activePlayersContext, setActivePlayersContext] = useState([])

    const handleGetMatch = (id) => {
        getMatchById(id).then((res)=>{
            setGridData(res)
        })
    }

    const handleRotationA = (_rotation) => {
        if (enabledAutoRotationContext) {
            const newRotation = _rotation + 1;
            newRotation <= 6 ? setRotationA(newRotation) : setRotationA(1)
        }
    }

    const handleRotationB = (_rotation) => {
        if (enabledAutoRotationContext) {
            const newRotation = _rotation + 1;
            newRotation <= 6 ? setRotationB(newRotation) : setRotationB(1)
        }
    }

    return (
        <StatisticsContext.Provider
        value={
            {
                positions,
                gridData, setGridData,
                handleGetMatch,
                handleRotationA, handleRotationB,
                rotationA, rotationB,
                showSelectPosition, setShowSelectionPosition,
                playersA, setPlayersA,
                playersB, setPlayersB,
                activeTeam, setActiveTeam,
                currentRallyContext, setCurrentRallyContext,
                gridLayout, setGridLayout,
                currentActionRefContext,
                setMatchContext, setSetMatchContext,
                enabledAutoRotationContext, setEnabledAutoRotationContext,
                activePlayersContext, setActivePlayersContext
            }}
        >
            {children}
        </StatisticsContext.Provider>
    )
}

export default StatisticsContext