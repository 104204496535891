import React, { useContext, useEffect, useState } from 'react'
import Profile from '../Profile'
import { useNavigate, useParams } from 'react-router-dom'
import './ProfileHistoryForm.css'
import AuthContext from '../../../../context/AuthContext/AuthContext'
import LeagueAutocompleteComponent from '../../../../components/LeagueAutocompleteComponent/LeagueAutocompleteComponent'
import EventAutocompleteComponent from '../../../../components/EventAutocompleteComponent/EventAutocompleteComponent'
import {Calendar} from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Button } from 'primereact/button'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { useForm } from 'react-hook-form'
import { createUserHistory, getUserHistoryByAccessKey, updateUserHistory } from '../../../../services/ProfileHistoryService'
import { Panel } from 'primereact/panel'

function ProfileHistoryForm() {
    const {userAuth} = useContext(AuthContext)
    const [league, setLeague] = useState('')
    const [event, setEvent] = useState('')
    const [date, setDate] = useState(null);
    const [position, setPosition] = useState('');
    const [award, setAward] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const {access_key} = useParams()

    const {setValue, register, onChange, handleSubmit, clearErrors, getValues, watch, formState: {errors}, reset, control} = useForm();

    register('date', {required: 'Required',});
    register('event', {required: 'Required',});

    useEffect(()=>{
        handleLoadUserHistory()
    },[access_key])

    const handleLoadUserHistory = async () => {
        if (access_key){
            const userHistory = await getUserHistoryByAccessKey(access_key)
            setAward(userHistory.awards || '')
            setPosition(userHistory.position || '')
            setValue("event", userHistory.event_access_key)
            setDate(userHistory.date ? new Date(userHistory.date) : '')
            setValue("date", userHistory.date ? new Date(userHistory.date) : '')
            setEvent({access_key: userHistory.event_access_key, name: userHistory.event_name})
            userHistory.league_access_key && setLeague({access_key: userHistory.league_access_key, name: userHistory.league_name})
        }
    }

    const handleSetLeague = (value) => {
        setLeague(value)
    }

    const handleSetEvent = (value) => {
        setValue("event", value)
        setEvent(value)
    }

    const handleSetDate = (e) => {
        setValue("date", e.target.value)
        setDate(e.target.value)
    }

    const navigate = useNavigate();

    const onSubmit = async () => {
        setIsLoading(true)
        if (access_key){
            await updateUserHistory(access_key, userAuth.user.access_key, league?.access_key, event?.access_key, date, position, award).then((res)=>{
                res && navigate('/app/profile_history')
            })
        } else {
            await createUserHistory(userAuth.user.access_key, league?.access_key, event?.access_key, date, position, award).then((res)=>{
                res && navigate('/app/profile_history')
            })
        }
        setIsLoading(false)
    }

    const onError = (errors) => {

    }

    const handleCancel = () => {
        navigate('/app/profile_history')
    }

    return (
        <Profile activeMenuNumber={3}>
            <ConfirmDialog />
            <div className="profile_history_form--container">
                <div className="profile_history_form--form">
                    <div className="text--medium--normal">Add History</div>
                    <div className="profile_history_form--content">
                        <form method="post" onSubmit={handleSubmit(onSubmit, onError)}>
                            <Panel header='More...' toggleable collapsed={true}>
                                <div className="form--row">
                                    <div className="form--fieldset">
                                        <label htmlFor="league" className="text--regular--normal">League</label>
                                        <LeagueAutocompleteComponent league={league} setLeague={handleSetLeague} />
                                    </div>
                                    <div className="form--fieldset">
                                        <label htmlFor="position" className="text--regular--normal">Position</label>
                                        <InputText type="number" name="position" id="position" value={position} onChange={(e)=>setPosition(e.target.value)} />
                                    </div>
                                </div>
                                <div className="form--row">
                                    <div className="form--fieldset">
                                        <label htmlFor="award" className="text--regular--normal">Awards</label>
                                        <InputTextarea rows={5} name="award" id="award" value={award} onChange={(e)=>setAward(e.target.value)} />
                                    </div>
                                </div>
                            </Panel>
                            <div className="form--row">
                                <div className="form--fieldset">
                                    <label htmlFor="event" className="text--regular--normal">Event</label>
                                    <EventAutocompleteComponent league_access_key={league?.access_key} 
                                    event={event} setEvent={handleSetEvent} leagueId={league} invalid={errors.event ? true : false} />
                                    {errors.event && <span className='error-text'>{errors.event?.message}</span>}
                                </div>
                                <div className="form--fieldset">
                                    <label htmlFor="date" className="text--regular--normal">Date</label>
                                    <Calendar className={errors.date && 'p-invalid'} name="date" id="date" value={date} 
                                    onChange={handleSetDate} />
                                    {errors.date && <span className='error-text'>{errors.date?.message}</span>}
                                </div>
                            </div>
                            <div className="form--action_row">
                                <Button loading={isLoading} type="submit" onClick={handleSubmit}>Save</Button>
                                <Button severity='secondary' onClick={handleCancel}>Back</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Profile>
    )
}

export default ProfileHistoryForm