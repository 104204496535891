import React from 'react'
import './ProfileMediaItem.css'
import { PiPencilBold, PiPlayCircleBold, PiTrashBold } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'

function ProfileMediaItem({data, isEditable=true, deleteProfileMedia}) {

    const navigate = useNavigate()

    const handleDelete = () => {
        deleteProfileMedia(data.access_key)
    }

    const handleEdit = () => {
        navigate('/app/profile_media_form/'+data.access_key)
    }

    return (
        <div className='profile_media_item--container'>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <div className="profile_media_item--set">
                                <div className="text--regular--normal">Date: </div>
                                <div className="text--medium--bold">{data.date && new Date(data.date).toLocaleDateString() || '-'}</div>
                            </div>
                        </td>
                        <td style={{verticalAlign: 'top'}}>
                            <div className="profile_media_item--set--actions">
                                <a className='icon_button' role='button' href={data.url} target="_blank" rel="noopener noreferrer">
                                    <PiPlayCircleBold role='button' />
                                </a>                                {isEditable &&
                                <>
                                    <PiPencilBold role='button' className='icon_button' onClick={handleEdit} />
                                    <PiTrashBold role='button' className='icon_button' onClick={handleDelete} />
                                </>
                                }
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <div className="profile_media_item--set">
                                <div className="text--regular--normal">Title: </div>
                                <div className="text--medium--bold">{data.title || '-'}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="profile_media_item--set">
                                <div className="text--regular--normal">Event: </div>
                                <div className="text--medium--bold">{data.event_name || '-'}</div>
                            </div>
                        </td>
                        <td>
                            <div className="profile_media_item--set">
                                <div className="text--regular--normal">League: </div>
                                <div className="text--medium--bold">{data.league_name || '-'}</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ProfileMediaItem