import { TabPanel, TabView } from 'primereact/tabview'
import React from 'react'
import MyStatistics from './MyStatistics'
import MyTeams from './MyTeams'

function MyStatsHome() {
    return (
        <div className='m-2'>
            <TabView>
                <TabPanel header="My Statistics">
                    <MyStatistics />
                </TabPanel>
                <TabPanel header="My Teams">
                    <MyTeams />
                </TabPanel>
            </TabView>
        </div>
    )
}

export default MyStatsHome