import React, { useContext, useEffect, useState } from 'react'
import StatisticsContext from '../StatisticsContext'
import { createRally } from '../../../../services/StatsService'
import AuthContext from '../../../../context/AuthContext/AuthContext'
import { useNavigate } from 'react-router-dom'
import PracticeStatisticsListItemComponent from './PracticeStatisticsListItemComponent'

function PracticeStatisticsListComponent() {

    const {gridData, playersA, setPlayersA, rotationA, 
        currentRallyContext, setCurrentRallyContext} = useContext(StatisticsContext)

    const [currentPlayerAction, setCurrentPlayerAction] = useState();
    
    useEffect(()=>{
        gridData && setPlayersA(gridData.playersTeamA)
    },[gridData])

    useEffect(()=>{
        if (currentPlayerAction) {
            setCurrentRallyContext([...currentRallyContext, currentPlayerAction])
            setCurrentPlayerAction()
        }
    },[currentPlayerAction])

    const changePositionA = (position, index) => {
        var playersList = [...playersA]
        playersList[index].position = Number(position)
        playersList.sort((a,b)=>(a.position ? Number(a.position) : 100) - Number(b.position ? Number(b.position) : 100))
        setPlayersA(playersList)
    }

    const changePlayerPosition = (_pos) => {
        var newPosition = _pos - 1
        newPosition = newPosition === 0 ? 6 : newPosition
        return newPosition
    }

    useEffect(()=>{
        if (playersA) {
            var tempPlayers = [...playersA]
            setPlayersA(tempPlayers.map((e)=>{
                return (
                    {
                        ...e,
                        position: e.position ? changePlayerPosition(e.position) : '',
                    }
                )
            }))
        }
    },[rotationA])

    return (
        <div className='m-2 flex flex-column gap-2' 
        style={{
            WebkitUserSelect: 'none', /* Safari */        
            MozUserSelect: 'none', /* Firefox */
            msUserSelect: 'none', /* IE10+/Edge */
            userSelect: 'none', /* Standard */}}>
            {playersA && 
                playersA.map((element, key)=>(
                    <PracticeStatisticsListItemComponent 
                    key={key} 
                    index={key} 
                    element={element} 
                    setCurrentPlayerAction={setCurrentPlayerAction}
                    changePositionA={changePositionA} />
                ))
            }
        </div>
    )

}

export default PracticeStatisticsListComponent