import { useContext } from "react";
import AuthContext from "../../context/AuthContext/AuthContext";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

export const ProtectedRoute = ({ children }) => {
  const { userAuth } = useContext(AuthContext);
  if (!userAuth?.user) {
    toast.error('Login to access this page.')
    return <Navigate to="/login" />;
  }
  return children;
};