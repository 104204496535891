import React from 'react'
import './ProfileHistoryItem.css'
import { PiPencilBold, PiTrashBold } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'

function ProfileHistoryItem({data, isEditable=true, deleteProfileHistory}) {

    const navigate = useNavigate()
    const handleDelete = () => {
        deleteProfileHistory(data.access_key)
    }

    const handleEdit = () => {
        navigate('/app/profile_history_form/'+data.access_key)
    }

    return (
        <div className='profile_history_item--container'>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <div className="profile_history_item--set">
                                <div className="text--regular--normal">Date: </div>
                                <div className="text--medium--bold">{new Date(data.date).toLocaleDateString() || '-'}</div>
                            </div>
                        </td>
                        <td>
                            <div className="profile_history_item--set">
                                <div className="text--regular--normal">Position: </div>
                                <div className="text--medium--bold">{data.position || '-'}</div>
                            </div>
                        </td>
                        <td style={{verticalAlign: 'top'}}>
                                { isEditable &&
                                    <div className="profile_history_item--set--actions">
                                        <PiPencilBold role='button' className='icon_button' onClick={handleEdit} />
                                        <PiTrashBold role='button' className='icon_button' onClick={handleDelete} />
                                    </div>
                                }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="profile_history_item--set">
                                <div className="text--regular--normal">Event: </div>
                                <div className="text--medium--bold">{data.event_name || '-'}</div>
                            </div>
                        </td>
                        <td colSpan={2}>
                            <div className="profile_history_item--set">
                                <div className="text--regular--normal">League: </div>
                                <div className="text--medium--bold">{data.league_name || '-'}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <div className="profile_history_item--set">
                                <div className="text--regular--normal">Awards: </div>
                                <div className="text--medium--bold">{data.awards || '-'}</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ProfileHistoryItem