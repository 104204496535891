import { Button } from 'primereact/button'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../../context/AuthContext/AuthContext'
import { deleteMatch, listMyStats } from '../../../services/StatsService'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { PiNotePencil, PiTrash, PiUserPlus, PiWarningCircle } from 'react-icons/pi'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { PresentationChart } from '@phosphor-icons/react'
import { listTeamByCreateBy, newTeamPlayers } from '../../../services/TeamService'
import TeamAutocompleteComponent from '../../../components/TeamAutocompleteComponent/TeamAutocompleteComponent'
import UserAutocompleteComponent from '../../../components/UserAutocompleteComponent/UserAutocompleteComponent'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'

function MyTeams() {
    const navigate = useNavigate()
    const [team, setTeam] = useState()
    const {userAuth} = useContext(AuthContext)
    const [matches, setMatches] = useState()
    const [teamPlayers, setTeamPlayers] = useState([]) 
    const skills = [
        {value: 9, label: 'Middle Blocker'},
        {value: 10, label: 'Outside Hitter'},
        {value: 11, label: 'Opposite Hitter'},
        {value: 12, label: 'Setter'},
        {value: 14, label: 'Libero'},
    ]

    useEffect(()=>{
        userAuth && handleListTeams()
    },[])

    const handleURL = (_match) => {
        if (_match?.statystics_type === 'full' || _match?.statystics_type === 'game'){
            return `/app/rotations_stats/${_match?.access_key}`
        } else {
            return '/app/show_stats'
        }
    }

    const handleListTeams = () => {
        listTeamByCreateBy(userAuth.user.access_key).then((res)=>{
            setMatches(res)
        })
    }

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Name" align={'center'} />
                <Column header="Actions" align={'center'} />
            </Row>
        </ColumnGroup>
    );

    const handleConfirmDelete = (item) => {
        confirmDialog({
            message: `Do you want to delete the match ${item.title}?`,
            header: 'Confirm?',
            icon: <PiWarningCircle />,
            defaultFocus: 'accept',
            accept: ()=>accept(item),
            reject
        });
    }

    const reject = () => {
    }

    const accept = (item) => {
        
    }

    const addTeamPlayers = () => {
        const _teamPlayer = {
            user: null,
            player_number: '',
            skill_id: '',
            report_order: '',
        }
        setTeamPlayers([...teamPlayers, _teamPlayer])
    }

    const handlePlayerNumber = (e, index) => {
        var _teamPlayers = [...teamPlayers]
        _teamPlayers[index].player_number = e.target.value
        setTeamPlayers(_teamPlayers)
    }

    const handleReportOrder = (e, index) => {
        var _teamPlayers = [...teamPlayers]
        _teamPlayers[index].report_order = e.target.value
        setTeamPlayers(_teamPlayers)
    }

    const handleSkill = (e, index) => {
        var _teamPlayers = [...teamPlayers]
        _teamPlayers[index].skill_id = e.target.value
        setTeamPlayers(_teamPlayers)
    }

    const handleRemoveTeamPLayer = (index) => {
        var _teamPlayers = [...teamPlayers]
        _teamPlayers = _teamPlayers.filter((e, key)=>key!==index)
        setTeamPlayers(_teamPlayers ? _teamPlayers : [])
    }

    const addUser = (e, key) => {
        var _teamPlayers = [...teamPlayers]
        _teamPlayers[key].user = e
        setTeamPlayers(_teamPlayers)
    }

    const saveTeamPlayers = () => {
        newTeamPlayers(team, teamPlayers, userAuth.user.access_key).then((res)=>{
            if (res){
                handleListTeams()
                setTeam()
                setTeamPlayers([])
            } 
        })
    }

    const handleSetTeam = (_team) => {
        setTeam(_team)
    }
    
    return (
        <div className='flex flex-column gap-2'>
            <ConfirmDialog />
            <div className='font-bold text-2xl'>New Team</div>
            <div className='flex gap-2'>
                <TeamAutocompleteComponent setTeam={handleSetTeam} team={team} />
                <Button className='p-2' onClick={addTeamPlayers}><PiUserPlus size={24} /></Button>
            </div>
            <div className='flex flex-column gap-2'>
                {teamPlayers && teamPlayers.map((item, key)=>(
                    <div className='flex gap-2' key={key}>
                        <UserAutocompleteComponent user={item.user} setUser={(e)=>addUser(e, key)} />
                        <Dropdown className='w-12rem' name='position' value={item.skill_id} options={skills} 
                        onChange={(e)=>handleSkill(e, key)} placeholder='Position' optionLabel='label' />                        
                        <InputText className='w-5rem' placeholder='Jersey #' value={item.player_number} name='player_number' onChange={(e)=>handlePlayerNumber(e, key)} />
                        <InputText className='w-5rem' placeholder='Order #' value={item.report_order} name='report_order' onChange={(e)=>handleReportOrder(e, key)} />
                        <Button size='small' className='p-2' onClick={()=>handleRemoveTeamPLayer(key)}><PiTrash size={24} /></Button>
                    </div>
                ))}
                {teamPlayers?.length > 0 && <Button label='Save Team' className='w-max' onClick={saveTeamPlayers} />}
            </div>
            <div>
                <div className='font-bold text-2xl mb-2'>My Teams</div>
                <DataTable showGridlines stripedRows value={matches} headerColumnGroup={headerGroup} tableStyle={{ minWidth: '10rem' }}>
                    <Column align={'left'} field="name" className='p-2' />
                    <Column className='p-2' align={'right'} body={(_team)=>
                        <div className='flex gap-1 justify-content-center gap-3'>
                            <a href={`/app/show_team_stats/${_team.access_key}`} target="_blank" rel="noopener noreferrer" className="p-button font-bold p-2">
                                <PresentationChart size={24} style={{color: 'white'}} />
                            </a>
                        </div>
                    } />
                </DataTable>
            </div>
        </div>
    )
}

export default MyTeams