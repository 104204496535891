import React, { useContext, useEffect, useState } from 'react'
import StatisticsContext from '../StatisticsContext'
import { createRally } from '../../../../services/StatsService'
import AuthContext from '../../../../context/AuthContext/AuthContext'
import CourtLayoutComponent from './CourtLayoutComponent'
import SideLayoutComponent from './SideLayoutComponent'
import MenuButtonsComponent from './MenuButtonsComponent'
import { actionsUtils } from '../Utils/ActionsUtils'
import { qualitiesFive, qualitiesFour, qualitiesThree } from '../Utils/QualitiesUtils'
import { courtPositionsUtil } from '../Utils/CourtPositionsUtils'
import ShowPlayerStats from '../ShowPlayerStats'
        

function PlayerStatisticsComponent() {

    const {gridData, setActiveTeam, playersA, setPlayersA, rotationA, currentRallyContext, 
        setCurrentRallyContext, gridLayout, setGridLayout, currentActionRefContext, setMatchContext} = useContext(StatisticsContext)
    const {userAuth} = useContext(AuthContext)

    useEffect(()=>{
        gridData && handleInitialData()
        setActiveTeam(1)
    },[gridData])

    const handleInitialData = () => {
        setPlayersA(gridData?.playersTeamA)
        setCurrentPlayer(gridData?.playersTeamA[0])
        setCourt({court: actions, type: 'Action'})
    }
    
    const [court, setCourt] = useState()
    const [currentCourtPosition, setCurrentCourtPosition] = useState('')
    const [currentAction, setCurrentAction] = useState('');
    const [currentQuality, setCurrentQuality] = useState(-1);
    const [currentPlayer, setCurrentPlayer] = useState('');
    const [currentPlayerAction, setCurrentPlayerAction] = useState();
    const [selectedPosition, setSelectedPosition] = useState()

    useEffect(()=>{
        currentActionRefContext.current = currentAction ? currentAction : null
    },[currentAction])    

    useEffect(()=>{
        currentPlayer && setPlayersA([currentPlayer])
        currentPlayer && setSelectedPosition(currentPlayer?.position)
    },[currentPlayer])

    const handleServeClick = () => {
        setCurrentAction(actions[5])
        setCourt({court: courtPositions, type: 'Position'})
    }

    const handleReceptionClick = () => {
        setCurrentAction(actions[3])
        setCourt({court: qualities_four, type: 'Quality'})
    }

    const handleSetClick = () => {
        setCurrentAction(actions[2])
        setCourt({court: courtPositions, type: 'Position'})
    }

    const handleAttackClick = () => {
        setCurrentAction(actions[0])
        setCourt({court: courtPositions, type: 'Position'})
    }

    const handleDigClick = () => {
        setCurrentAction(actions[4])
        setCourt({court: qualities_three, type: 'Quality'})
    }

    const handleBlockClick = () => {
        setCurrentAction(actions[1])
        setCourt({court: qualities_three, type: 'Quality'})
    }

    const handleQualityClick = (quality) => {
        const idx = qualities_five.findIndex((e)=>e.number == quality)
        setCurrentQuality(qualities_five[idx])
    }

    useEffect(()=>{
        currentQuality.number >= 0 && finishAction()
    },[currentQuality])

    const finishAction = () => {
        currentPlayer && currentAction && setCurrentPlayerAction({
            player: currentPlayer,
            player_id: currentPlayer.player.id,
            playerName: currentPlayer.number, 
            action_number: currentAction.number, 
            action: currentAction.id, 
            position_action: currentCourtPosition ? currentCourtPosition : null, 
            quality_value: currentQuality.number, 
            quality: currentQuality.id, 
            rotation: rotationA, 
            position: currentPlayer.position,
            team: null, 
            rally_phase: null,
            set_match: setMatchContext,
        })
        setCurrentQuality('')
        setCurrentCourtPosition('')
        setCurrentAction('')
        setCourt({court: actions, type: 'Action'})
    }

    useEffect(()=>{
        if (currentPlayerAction) {
            setCurrentRallyContext([...currentRallyContext, currentPlayerAction])
            setCurrentPlayerAction()
        }
    },[currentPlayerAction])

    const finishRally = () => {
        createRally({match_id: gridData.matchId, created_by: userAuth.user.access_key, rally: currentRallyContext}).then((res)=>{
            res && setCurrentRallyContext([])
        }).catch((error)=>{
            
        })
    }

    const handlePositionClick = (action) => {
        switch (action.number) {
            case 'ser':
                setCourt({court: qualities_five, type: 'Quality'})
                break;
        
            case 'att':
                setCourt({court: qualities_three, type: 'Quality'})
                break;
        
            case 'set':
                setCourt({court: qualities_three, type: 'Quality'})
                break;
            
            default:
                break;
        }
    }

    useEffect(()=>{
        currentCourtPosition > 0 && handlePositionClick(currentAction)
    },[currentCourtPosition])

    const actions = [
        {
            ...actionsUtils.attack,
            action: handleAttackClick,
        },
        {
            ...actionsUtils.block,
            action: handleBlockClick,
        },
        {
            ...actionsUtils.set,
            action: handleSetClick,
        },
        {
            ...actionsUtils.reception,
            action: handleReceptionClick,
        },
        {
            ...actionsUtils.dig,
            action: handleDigClick,
        },
        {
            ...actionsUtils.serve,
            action: handleServeClick,
        },
    ]
    
    const qualities_three = [
        {
            ...qualitiesThree.two,
            action: () => handleQualityClick(2),
        },
        {
            ...qualitiesThree.one,
            action: () => handleQualityClick(1),
        },
        {
            ...qualitiesThree.zero,
            action: () => handleQualityClick(0),
        },
    ]

    const qualities_four = [
        {
            ...qualitiesFour.three,
            action: () => handleQualityClick(3),
        },
        {
            ...qualitiesFour.two,
            action: () => handleQualityClick(2),
        },
        {
            ...qualitiesFour.one,
            action: () => handleQualityClick(1),
        },
        {
            ...qualitiesFour.zero,
            action: () => handleQualityClick(0),
        },
    ]

    const qualities_five = [
        {
            ...qualitiesFive.four,
            action: () => handleQualityClick(4),
        },
        {
            ...qualitiesFive.three,
            action: () => handleQualityClick(3),
        },
        {
            ...qualitiesFive.two,
            action: () => handleQualityClick(2),
        },
        {
            ...qualitiesFive.one,
            action: () => handleQualityClick(1),
        },
        {
            ...qualitiesFive.zero,
            action: () => handleQualityClick(0),
        },
    ]

    //need to be same order as rotations
    const positions = [
        {
            number: 1,
        },
        {
            number: 6,
        },
        {
            number: 5,
        },
        {
            number: 4,
        },
        {
            number: 3,
        },
        {
            number: 2,
        },
    ]

    //need to be same order as rotations
    const courtPositions = [
        {
            ...courtPositionsUtil.one,
            action: () => setCurrentCourtPosition(1),
        },
        {
            ...courtPositionsUtil.two,
            action: () => setCurrentCourtPosition(2),
        },
        {
            ...courtPositionsUtil.three,
            action: () => setCurrentCourtPosition(3),
        },
        {
            ...courtPositionsUtil.four,
            action: () => setCurrentCourtPosition(4),
        },
        {
            ...courtPositionsUtil.five,
            action: () => setCurrentCourtPosition(5),
        },
        {
            ...courtPositionsUtil.six,
            action: () => setCurrentCourtPosition(6),
        },
    ]

    const handleChangePosition = (e) => {
        setSelectedPosition(e.value)
        changePosition(e.value)
    }

    const changePosition = (position) => {
        var playersList = [...playersA]
        playersList?.forEach((item) => {
            item.position = item.number === item.number ? position : item.position
        });
        setPlayersA(playersList)
    }

    const changePlayerPosition = (_pos) => {
        var newPosition = _pos - 1
        newPosition = newPosition === 0 ? 6 : newPosition
        return newPosition
    }

    useEffect(()=>{
        if (playersA) {
            var tempPlayers = [...playersA]
            setPlayersA(tempPlayers.map((e)=>{
                var _newPlayer = {...e, position: changePlayerPosition(e.position)}

                setCurrentPlayer(_newPlayer)
                return (_newPlayer)
            }))
        }
    },[rotationA])

    return (
        <div className='flex flex-column gap-2 w-full'>
        {        
        gridLayout ? 
            <CourtLayoutComponent menuButtons={
                <MenuButtonsComponent 
                    gridLayout={gridLayout} 
                    setGridLayout={setGridLayout} 
                    currentPlayer={currentPlayer} 
                    selectedPosition={selectedPosition} 
                    handleChangePosition={handleChangePosition} 
                    currentAction={currentAction}
                    courtType={court?.type}
                    saveRally={finishRally} />
                } 
                court={court?.court} 
                courtPositions={courtPositions} 
                gridLayout={gridLayout} />
        :
            <SideLayoutComponent menuButtons={
                <MenuButtonsComponent 
                    gridLayout={gridLayout} 
                    setGridLayout={setGridLayout} 
                    currentPlayer={currentPlayer} 
                    selectedPosition={selectedPosition} 
                    handleChangePosition={handleChangePosition} 
                    currentAction={currentAction}
                    courtType={court?.type} 
                    saveRally={finishRally} 
                />
                } 
                court={court?.court} 
                courtPositions={courtPositions} 
                gridLayout={gridLayout} />
        }
        <ShowPlayerStats />
        </div>
    )

}

export default PlayerStatisticsComponent