import React from 'react'
import './InfoWindowMapEvent.css'
import SportsIconComponent from '../SportsIconComponent/SportsIconComponent'
import { Tag } from 'primereact/tag'

function InfoWindowMapEvent({data}) {
    return (
        <a href={'/app/event_detail/'+data.access_key}>
            <div className="info_window_map_event--container">
                <div className="info_window_map_event--head">
                    <div className="info_window_map_event--sport">
                        <SportsIconComponent size={16} sport={data.sport_name} />
                        <span className="text--xsmall--normal">{data.sport_name.toUpperCase() || <Tag value="add info" />
                        }</span>
                    </div>
                </div>
                <div className="info_window_map_event--head">
                    <div className="info_window_map_event--head--left">
                        <div className="text--small--bold">{data.name || <Tag value="add info" />
                        }</div>
                        <div className="text--xsmall--normal">Organized by {data.league_name || <Tag value="add info" />
                        }</div>
                    </div>
                    <div className="info_window_map_event--head--right">
                        <Tag style={{cursor: 'pointer',}}>More details</Tag>
                    </div>
                </div>
                <div className="info_window_map_event--content">
                    <div className="info_window_map_event--set">
                        <div className="text--xsmall--bold">Start date:</div>
                        <div className="text--small--normal">{
                            data.start_date ? new Date(data.start_date).toLocaleDateString() : <Tag value="add info" />
                        }
                        </div>
                    </div>
                    <div className="info_window_map_event--set">
                        <div className="text--xsmall--bold">End date:</div>
                        <div className="text--small--normal">{data.end_date ? new Date(data.end_date).toLocaleDateString() : <Tag value="add info" />
                        }</div>
                    </div>
                    <div className="info_window_map_event--set">
                        <div className="text--xsmall--bold">Week days:</div>
                        <div className="text--small--normal">{data.days || <Tag value="add info" />
                        }</div>
                    </div>
                    <div className="info_window_map_event--set">
                        <div className="text--xsmall--bold">Address:</div>
                        <div className="text--small--normal">{data.address || <Tag value="add info" />
                        }</div>
                    </div>
                </div>
            </div>
        </a>
    )
}

export default InfoWindowMapEvent