import React, { useContext, useEffect, useState } from 'react'
import Profile from '../Profile'
import { useNavigate, useParams } from 'react-router-dom'
import './ProfileMediaForm.css'
import AuthContext from '../../../../context/AuthContext/AuthContext'
import LeagueAutocompleteComponent from '../../../../components/LeagueAutocompleteComponent/LeagueAutocompleteComponent'
import EventAutocompleteComponent from '../../../../components/EventAutocompleteComponent/EventAutocompleteComponent'
import {Calendar} from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import { Panel } from 'primereact/panel'
import { Button } from 'primereact/button'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { useForm } from 'react-hook-form'
import { createUserMedia, getUserMediaByAccessKey, updateUserMedia } from '../../../../services/ProfileMediaService'

function ProfileMediaForm() {
    const {userAuth} = useContext(AuthContext)
    const [league, setLeague] = useState('')
    const [event, setEvent] = useState('')
    const [date, setDate] = useState('');
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const {access_key} = useParams()

    const {setValue, register, onChange, handleSubmit, clearErrors, getValues, watch, formState: {errors}, reset, control} = useForm();

    register('title', {required: 'Required',});
    register('url', {required: 'Required',});

    useEffect(()=>{
        handleLoadUserMedia()
    },[access_key])

    const handleLoadUserMedia = async () => {
        if (access_key){
            const userMedia = await getUserMediaByAccessKey(access_key)
            setTitle(userMedia.title || '')
            setValue("title", userMedia.title)
            setUrl(userMedia.url || '')
            setValue("url", userMedia.url)
            setDate(userMedia.date ? new Date(userMedia.date) : '')
            setEvent({access_key: userMedia.event_access_key, name: userMedia.event_name})
            setLeague({access_key: userMedia.league_access_key, name: userMedia.league_name})
            userMedia.league_access_key && setLeague({access_key: userMedia.league_access_key, name: userMedia.league_name})
        }
    }

    const handleSetLeague = (value) => {
        setLeague(value)
    }

    const handleSetEvent = (value) => {
        setEvent(value)
    }

    const handleSetTitle = (e) => {
        setValue("title", e.target.value)
        setTitle(e.target.value)
    }

    const handleSetUrl = (e) => {
        setValue("url", e.target.value)
        setUrl(e.target.value)
    }

    const navigate = useNavigate();

    const onSubmit = async () => {
        setIsLoading(true)
        if (access_key){
            await updateUserMedia(access_key, userAuth.user.access_key, league?.access_key, event?.access_key, date, title, url).then((res)=>{
                res && navigate('/app/profile_media')
            })
        } else {
            await createUserMedia(userAuth.user.access_key, league?.access_key, event?.access_key, date, title, url).then((res)=>{
                res && navigate('/app/profile_media')
            })
        }
        setIsLoading(false)
    }

    const onError = (errors) => {

    }

    const handleCancel = () => {
        navigate('/app/profile_media')
    }

    return (
        <Profile activeMenuNumber={4}>
            <ConfirmDialog />
            <div className="profile_media_form--container">
                <div className="profile_media_form--form">
                    <div className="text--medium--normal">Add Media</div>
                    <div className="profile_media_form--content">
                        <form method="post" onSubmit={handleSubmit(onSubmit, onError)}>
                            <div className="form--row">
                                <div className="form--fieldset">
                                    <label htmlFor="title" className="text--regular--normal">Title</label>
                                    <InputText className={errors.title && 'p-invalid'} name="title" id="title" value={title} onChange={handleSetTitle} />
                                    {errors.title && <span className='error-text'>{errors.title?.message}</span>}
                                </div>
                                <div className="form--fieldset">
                                    <label htmlFor="date" className="text--regular--normal">URL</label>
                                    <InputText className={errors.url && 'p-invalid'} name="url" id="url" value={url} onChange={handleSetUrl} />
                                    {errors.url && <span className='error-text'>{errors.url?.message}</span>}
                                </div>
                            </div>
                            <Panel header='More...' toggleable collapsed={true}>
                                <div className="form--row">
                                    <div className="form--fieldset">
                                        <label htmlFor="league" className="text--regular--normal">League</label>
                                        <LeagueAutocompleteComponent league={league} setLeague={handleSetLeague} />
                                    </div>
                                    <div className="form--fieldset">
                                        <label htmlFor="event" className="text--regular--normal">Event</label>
                                        <EventAutocompleteComponent league_access_key={league?.access_key} 
                                        event={event} setEvent={handleSetEvent} leagueId={league} />
                                    </div>
                                </div>
                                <div className="form--row">
                                    <div className="form--fieldset">
                                        <label htmlFor="date" className="text--regular--normal">Date</label>
                                        <Calendar className={errors.date && 'p-invalid'} name="date" id="date" value={date} 
                                        onChange={(e)=>setDate(e.target.value)} />
                                    </div>
                                </div>
                            </Panel>
                            <div className="form--action_row">
                                <Button loading={isLoading} type="submit" onClick={handleSubmit}>Save</Button>
                                <Button severity='secondary' onClick={handleCancel}>Back</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Profile>
    )
}

export default ProfileMediaForm