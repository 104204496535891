import React, { useEffect, useState } from 'react'
import PlayerDetail from '../PlayerDetail'
import ProfileSkillItem from '../../../Profile/ProfileSkill/ProfileSkillItem/ProfileSkillItem'
import './PlayerDetailSkill.css'
import { useParams } from 'react-router-dom'
import { listUserSportByUser } from '../../../../../services/UserSportService'


function PlayerDetailSkill() {
    const {access_key} = useParams()
    const [userSports, setUserSports] = useState()
    useEffect(() => {
        handleListUserSport()
    },[])

    const handleListUserSport = async () => {
        setUserSports(await listUserSportByUser(access_key))
    }


    return (
        <PlayerDetail activeMenuNumber={2} access_key={access_key}>
            <div className="player_detail_skill--content">
                {
                    userSports && userSports.map((item, key) => <ProfileSkillItem sportSkills={item} key={key} showContent={true} />)
                }
            </div>
        </PlayerDetail>
    )
}

export default PlayerDetailSkill