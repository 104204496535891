import React, { useEffect, useState } from 'react'
import { listUserMediasByLeague } from '../../../services/LeagueService'
import { Timeline } from 'primereact/timeline'
import { Avatar } from 'primereact/avatar'
import { PiPlayCircleBold } from 'react-icons/pi'
import { Panel } from 'primereact/panel'

function LeagueMediasList({access_key}) {

    const [userMedias, setUserMedias] = useState([])

    useEffect(()=>{
        handleListUserMedia()
    },[access_key])

    const handleListUserMedia = () => {
        access_key && listUserMediasByLeague(access_key).then((response)=>{
            setUserMedias(response)
        })
    }


    const header = (item) => {
        return (
            <div className='flex gap-1 flex-column'>
                {item.title}
                <div className="text--small--normal">Added by {item?.first_name} {item?.last_name}</div>
            </div>
        )
    }

    return (
        <div className="grid">
            {userMedias && userMedias.map((item, key)=>(
                <div className='col-12 md:col-6 xl:col-4' key={key}>
                    <Panel header={()=>header(item)}>
                        <div className='flex justify-content-between'>
                            <div>
                                <div className='text--small--normal'>
                                    {item.event_name}
                                </div>
                                <div className='text--small--bold'>{item.date ? new Date(item.date).toLocaleDateString() : '-'}</div>
                            </div>
                            <Avatar size='normal' shape='circle'>
                                <a className='icon_button' role='button' href={item.url} target="_blank" rel="noopener noreferrer">
                                    <PiPlayCircleBold role='button' style={{border: 'none'}} />
                                </a>
                            </Avatar> 
                        </div>
                    </Panel>
                </div>
            ))}

        </div>
    )
}

export default LeagueMediasList