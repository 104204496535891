import React, { useEffect, useState } from 'react'
import UserAutocompleteComponent from '../../../components/UserAutocompleteComponent/UserAutocompleteComponent'
import { getUserStats } from '../../../services/StatsService'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';        
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

function ShowPlayerStats() {
    const [user, setUser] = useState()
    const [stats, setStats] = useState()
    const [pointsSum, setPointsSum] = useState(0)
    const [serveSum, setServeSum] = useState(0)
    const [serveAvg, setServeAvg] = useState(0)
    const [receptionSum, setReceptionSum] = useState(0)
    const [receptionAvg, setReceptionAvg] = useState(0)
    const [attackSum, setAttackSum] = useState(0)
    const [attackAvg, setAttackAvg] = useState(0)
    const [digSum, setDigSum] = useState(0)
    const [blockSum, setBlockSum] = useState(0)
    const [setSum, setSetSum] = useState(0)

    useEffect(()=>{
        user && handleGetUserStats()
    },[user])

    const handleGetUserStats = () => {
        var tempPointSum = 0
        var tempServeSum = 0
        var tempServeAvg = 0
        var tempRecSum = 0
        var tempRecAvg = 0
        var tempAttSum = 0
        var tempAttAvg = 0
        var tempDigSum = 0
        var tempBloSum = 0
        var tempSetSum = 0
        getUserStats(user.player_id).then((res)=>{
            const newData = res.map((e)=>{
                const total_points = Number(e.att2)+Number(e.blo2)+Number(e.ser4)
                const ser_perc = Number(e.ser_total) > 0 ? parseFloat(((Number(e.ser4)*4)+(Number(e.ser3)*3)+(Number(e.ser2)*2)+(Number(e.ser1)*1))/Number(e.ser_total)).toFixed(2) : 0
                const rec_perc = Number(e.rec_total) > 0 ? parseFloat(((Number(e.rec3)*3)+(Number(e.rec2)*2)+(Number(e.rec1)*1))/Number(e.rec_total)).toFixed(2) : 0
                const att_perc = Number(e.att_total) > 0 ? parseFloat((Number(e.att2)-Number(e.att0))/Number(e.att_total)).toFixed(2) : 0

                tempPointSum += total_points
                tempServeSum += Number(e.ser_total)
                tempServeAvg += Number(ser_perc)
                tempRecSum += Number(e.rec_total)
                tempRecAvg += Number(rec_perc)
                tempAttSum += Number(e.att_total)
                tempAttAvg += Number(att_perc)
                tempDigSum += Number(e.dig_total)
                tempBloSum += Number(e.blo_total)
                tempSetSum += Number(e.set_total)
                return {
                    ...e,
                    total_points,
                    ser_perc,
                    rec_perc,
                    att_perc,
                }
            })
            setStats(newData)
            setPointsSum(tempPointSum)
            setServeSum(tempServeSum)
            setServeAvg(parseFloat(tempServeAvg/res.length).toFixed(2))
            setReceptionSum(tempRecSum)
            setReceptionAvg(parseFloat(tempRecAvg/res.length).toFixed(2))
            setAttackSum(tempAttSum)
            setAttackAvg(parseFloat(tempAttAvg/res.length).toFixed(2))
            setDigSum(tempDigSum)
            setBlockSum(tempBloSum)
            setSetSum(tempSetSum)
        })
    }

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Total" />
                <Column footer={pointsSum} footerStyle={{ textAlign: 'right' }} />
                <Column footer={serveSum} footerStyle={{ textAlign: 'right' }} />
                <Column footer={''} />
                <Column footer={''} />
                <Column footer={''} />
                <Column footer={''} />
                <Column footer={''} />
                <Column footer={serveAvg} footerStyle={{ textAlign: 'right' }} />
                <Column footer={receptionSum} footerStyle={{ textAlign: 'right' }} />
                <Column footer={''} />
                <Column footer={''} />
                <Column footer={''} />
                <Column footer={''} />
                <Column footer={receptionAvg} footerStyle={{ textAlign: 'right' }} />
                <Column footer={attackSum} footerStyle={{ textAlign: 'right' }} />
                <Column footer={''} />
                <Column footer={''} />
                <Column footer={''} />
                <Column footer={attackAvg} footerStyle={{ textAlign: 'right' }} />
                <Column footer={digSum} footerStyle={{ textAlign: 'right' }} />
                <Column footer={''} />
                <Column footer={''} />
                <Column footer={''} />
                <Column footer={blockSum} footerStyle={{ textAlign: 'right' }} />
                <Column footer={''} />
                <Column footer={''} />
                <Column footer={''} />
                <Column footer={setSum} footerStyle={{ textAlign: 'right' }} />
                <Column footer={''} />
                <Column footer={''} />
                <Column footer={''} />
            </Row>
        </ColumnGroup>
    );

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Matches" align={'center'} rowSpan={2} />
                <Column header="Points" align={'center'} rowSpan={2} />
                <Column header="Serve" align={'center'} colSpan={7} />
                <Column header="Reception" align={'center'} colSpan={6} />
                <Column header="Attack" align={'center'} colSpan={5} />
                <Column header="Dig" align={'center'} colSpan={4} />
                <Column header="Block" align={'center'} colSpan={4} />
                <Column header="Set" align={'center'} colSpan={4} />
            </Row>
            <Row>
                <Column align={'center'} header="Total" />
                <Column align={'center'} header="4" />
                <Column align={'center'} header="3" />
                <Column align={'center'} header="2" />
                <Column align={'center'} header="1" />
                <Column align={'center'} header="0" />
                <Column align={'center'} header="%" />

                <Column align={'center'} header="Total" />
                <Column align={'center'} header="3" />
                <Column align={'center'} header="2" />
                <Column align={'center'} header="1" />
                <Column align={'center'} header="0" />
                <Column align={'center'} header="%" />

                <Column align={'center'} header="Total" />
                <Column align={'center'} header="2" />
                <Column align={'center'} header="1" />
                <Column align={'center'} header="0" />
                <Column align={'center'} header="Eff" />

                <Column align={'center'} header="Total" />
                <Column align={'center'} header="2" />
                <Column align={'center'} header="1" />
                <Column align={'center'} header="0" />

                <Column align={'center'} header="Total" />
                <Column align={'center'} header="2" />
                <Column align={'center'} header="1" />
                <Column align={'center'} header="0" />

                <Column align={'center'} header="Total" />
                <Column align={'center'} header="2" />
                <Column align={'center'} header="1" />
                <Column align={'center'} header="0" />
            </Row>
        </ColumnGroup>
    );

    return (
        <div className='flex flex-column gap-4 m-4'>
            <UserAutocompleteComponent user={user} setUser={setUser} />
            {stats &&
            <DataTable footerColumnGroup={footerGroup} showGridlines stripedRows value={stats} headerColumnGroup={headerGroup} tableStyle={{ minWidth: '10rem' }}>
                <Column align={'left'} field="match_title" />

                <Column align={'right'} field="total_points" />

                <Column align={'right'} field="ser_total" />
                <Column align={'right'} field="ser4" />
                <Column align={'right'} field="ser3" />
                <Column align={'right'} field="ser2" />
                <Column align={'right'} field="ser1" />
                <Column align={'right'} field="ser0" />
                <Column align={'right'} field="ser_perc" />

                <Column align={'right'} field="rec_total" />
                <Column align={'right'} field="rec3" />
                <Column align={'right'} field="rec2" />
                <Column align={'right'} field="rec1" />
                <Column align={'right'} field="rec0" />
                <Column align={'right'} field="rec_perc" />

                <Column align={'right'} field="att_total" />
                <Column align={'right'} field="att2" />
                <Column align={'right'} field="att1" />
                <Column align={'right'} field="att0" />
                <Column align={'right'} field="att_perc" />

                <Column align={'right'} field="dig_total" />
                <Column align={'right'} field="dig2" />
                <Column align={'right'} field="dig1" />
                <Column align={'right'} field="dig0" />

                <Column align={'right'} field="blo_total" />
                <Column align={'right'} field="blo2" />
                <Column align={'right'} field="blo1" />
                <Column align={'right'} field="blo0" />

                <Column align={'right'} field="set_total" />
                <Column align={'right'} field="set2" />
                <Column align={'right'} field="set1" />
                <Column align={'right'} field="set0" />
            </DataTable>}
        </div>
    )
}

export default ShowPlayerStats