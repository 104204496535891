
export const actionsUtils = {
    attack: {
        id: 4,
        number: "att",
        label: "Attack",
        courtPosition: 3,
        color: "#f6ae2d",
    },
    block: {
        id: 5,
        number: "blo",
        label: "Block",
        courtPosition: 2,
        color: "#33658a",
    },
    set: {
        id: 3,
        number: "set",
        label: "Set",
        courtPosition: 1,
        color: "#86bbd8",
    },
    reception: {
        id: 2,
        number: "rec",
        label: "Reception",
        courtPosition: 6,
        color: "#0C2F68",
    },
    dig: {
        id: 6,
        number: "dig",
        label: "Dig",
        courtPosition: 5,
        color: "#f6ae2d",
    },
    serve: {
        id: 1,
        number: "ser",
        label: "Serve",
        courtPosition: 4,
        color: "#33658a",
    }
}

export const handleRecBackgroundColor = (total, perc) => {
    return  total > 0 ? 
                perc >= 2.4 ? 'bg-green-400' :  
                perc >= 2.1 ? 'bg-green-200' :  
                perc >= 2 ? 'bg-orange-200' : 'bg-red-200'   
            : ''
}

export const handleSerBackgroundColor = (total, perc) => {
    return  total > 0 ? 
                perc >= 2.3 ? 'bg-green-400' :  
                perc >= 2 ? 'bg-green-200' :  
                perc >= 1.7 ? 'bg-orange-200' : 'bg-red-200'   
            : ''
}

export const handleAttBackgroundColor = (total, perc) => {
    return  total > 0 ? 
                perc >= 0.5 ? 'bg-green-400' :  
                perc >= 0.26 ? 'bg-green-200' :  
                perc >= 0.2 ? 'bg-orange-200' : 'bg-red-200'   
            : ''
}