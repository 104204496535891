import React, { useContext, useEffect, useState } from 'react'
import Profile from '../Profile'
import './ProfileSkillForm.css'
import { Rating } from 'primereact/rating';
import ProfileSkillItem from './ProfileSkillItem/ProfileSkillItem'
import { useNavigate } from 'react-router-dom'
import { deleteUserSkill, listUserSkillByUserSport, newUserSkill } from '../../../../services/ProfileSkillService'
import AuthContext from '../../../../context/AuthContext/AuthContext'
import { toast } from 'react-toastify'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import SportAutocompleteComponent from '../../../../components/SportAutocompleteComponent/SportAutocompleteComponent';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import SkillAutocompleteComponent from '../../../../components/SkillAutocompleteComponent/SkillAutocompleteComponent';
import { create } from '../../../../services/UserSportSkillService';
import { PiWarningCircle } from 'react-icons/pi';
import { getUserSportByUserSport } from '../../../../services/UserSportService';

function ProfileSkillForm() {
    const navigate = useNavigate();
    const {userAuth} = useContext(AuthContext)

    const [sport, setSport] = useState([])

    const [skill, setSkill] = useState([])

    const [skillRating, setSkillRating] = useState('');
    const [sportRating, setSportRating] = useState('');
    const [sportSkills, setSportSkills] = useState();

    const [isFormValid, setIsFormValid] = useState(false)
    const [isLoadingUserSkills, setIsLoadingUsersSkills] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{
        sport && skill && sportRating && skillRating ? setIsFormValid(true) : setIsFormValid (false)
    },[sport, skill, skillRating, sportRating])

    useEffect(()=>{
        sport?.access_key && handleListUserSkillByUserSport();        
    },[sport])

    useEffect(()=>{
        setSportRating(sportSkills ? sportSkills.rating : '')
    },[sportSkills])

    const handleListUserSkillByUserSport = async () => {
        setIsLoadingUsersSkills(true)
        setSportSkills(await getUserSportByUserSport(userAuth.user.access_key, sport.access_key))
        setIsLoadingUsersSkills(false)
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        if (isFormValid){
            await create(userAuth.user.access_key, sport.access_key, skill.access_key, sportRating, skillRating).then((res)=>{
                handleListUserSkillByUserSport()
                setSkillRating('')
                setSkill([])
            })
        } else {
            !sport && toast.error('Select one sport.')
            !skill && toast.error('Select one skill.')
            !sportRating && toast.error('Inform your sport rating.')
            !skillRating && toast.error('Inform your skill rating.')
        }
        setIsLoading(false)
    }

    const handleCancel = () => {
        navigate('/app/profile_skill')
    }

    const handleRating = (e) => {
        setSkillRating(e.value)
    }

    const deleteSkill = async (skillIdProp, name) => {
        confirmDialog({
            message: `Do you want to delete the skill ${name}?`,
            header: 'Confirm?',
            icon: <PiWarningCircle />,
            defaultFocus: 'accept',
            accept: () => confirmDeleteUserSkill(skillIdProp),
            reject
        });
    }

    const reject = async () => {
        
    }

    const confirmDeleteUserSkill = async (skillIdProp) => {
        setIsLoadingUsersSkills(true)
        await deleteUserSkill(skillIdProp);
        await handleListUserSkillByUserSport();
        setIsLoadingUsersSkills(false)
    }
      
    return (
        <Profile activeMenuNumber={2}>
            <ConfirmDialog />
            <div className="profile_skill_form--container">
                <div className="profile_skill_form--form">
                    <div className="text--medium--normal">My Skills</div>
                    <div className="profile_skill_form--content">
                        <form method="post">
                        <div className="form--row">
                                <div className="form--fieldset">
                                    <label htmlFor="sport" className="text--regular--normal">Sports</label>
                                    <SportAutocompleteComponent setSport={(value)=>setSport(value)} sport={sport} />
                                </div>
                            </div>
                            <div className="form--row">
                                <div className="form--fieldset">
                                    <label htmlFor="sport" className="text--regular--normal">Sport's Rating</label>
                                    <InputText type="text" name="sport_rating" id="sport_rating" value={sportRating} 
                                    onChange={(e)=>setSportRating(e.target.value)} placeholder='C, B, BB, A, AA, ...' />
                                </div>
                            </div>
                            <div className="form--row">
                                <div className="form--fieldset">
                                    <label htmlFor="skill" className="text--regular--normal">Skills</label>
                                    <SkillAutocompleteComponent setSkill={(value)=>setSkill(value)} skill={skill} sportAccessKey={sport.access_key} />
                                </div>
                            </div>
                            <div className="form--row">
                                <div className="form--fieldset">
                                    <label htmlFor="skill" className="text--regular--normal">Skill's Rating</label>
                                    <Rating 
                                    cancel={false}
                                    value={skillRating}
                                    onChange={handleRating}
                                    readOnly={skill?.access_key ? false : true}
                                    />
                                </div>
                            </div>
                            <div className="form--action_row">
                                <Button loading={isLoading} disabled={!isFormValid} type="button" onClick={handleSubmit}>Save</Button>
                                <Button type="button" severity='secondary' onClick={handleCancel}>Back</Button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="profil_skill_form--detail">
                    <ProfileSkillItem isLoading={isLoadingUserSkills} isEditable={true}
                    sportSkills={sportSkills} showContent={sportSkills ? true : false} 
                    canDelete={true} deleteSkill={deleteSkill} />
                </div>
            </div>
        </Profile>
    )
}

export default ProfileSkillForm