import React, { useContext, useEffect, useState } from 'react'
import './Profile.css'
import { PiPencilBold, PiUserCircle } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'
import AuthContext from '../../../context/AuthContext/AuthContext'
import { Button } from 'primereact/button'
import { Avatar } from 'primereact/avatar'
import { Dialog } from 'primereact/dialog'
import { FileUpload } from 'primereact/fileupload'
import {v4 as uuidv4} from 'uuid'
import { firebase_storage } from '../../../firebase'
import firebase from 'firebase/compat/app';
import { toast } from 'react-toastify'
import Compress from 'compress.js'
import Cropper from 'react-easy-crop'
import getCroppedImg from '../../../utils/CropImage'

function Profile({children, activeMenuNumber=1}) {
    const [activeMenu, setActiveMenu] = useState(activeMenuNumber);
    const [modalVisible, setModalVisible] = useState(false)
    const navigate = useNavigate();
    const {userAuth, userUpdatePicture} = useContext(AuthContext)
    const [imageFile, setImageFile] = useState()
    const [fileName, setFileName] = useState()
    const [uploadProgress, setUploadProgress] = useState()
    useEffect(()=>{
        if (!userAuth.accessToken){
            navigate('/login')
        }
    },[])
    const [imageSrc, setImageSrc] = useState('')
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
  
    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    useEffect(()=>{
        imageSrc && croppedAreaPixels && handleCroppedImage()
    },[imageSrc, croppedAreaPixels])
    
    const handleCroppedImage = async () => {
        try {
            const croppedImage = await getCroppedImg(
            imageSrc,
            croppedAreaPixels,
            rotation
            )
            setCroppedImage(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(()=>{
        handleCompressImage()
    },[croppedImage])

    const handleCompressImage = async () => {
        if (croppedImage){
            let blob = await fetch(croppedImage).then(r => r.blob());
            const compress = new Compress()
            compress.compress([blob], {
                size: .25,
                quality: .75,
                maxWidth: 240,
                maxHeight: 240,
                resize: true,
            }).then(async (result)=>{
                const img = result[0]
                const base64str = img.data
                const imgExt = img.ext
                const file = await Compress.convertBase64ToFile(base64str, imgExt)
                setImageFile(file)
            })
            setFileName(uuidv4());
        }
    }

    const handleMenu = (number) => {
        setActiveMenu(number);
        number === 1 && navigate('/app/profile_form');
        number === 2 && navigate('/app/profile_skill');
        number === 3 && navigate('/app/profile_history');
        number === 4 && navigate('/app/profile_media');
    }

    const uploadImage = (e) => {
    }

    const selectImage = async (e) => {
        if (e.files){
            setImageSrc(e.files[0].objectURL)
            setImageFile(e.files[0])
        }
    }

    const beforeSendImage = async (e) => {
        var storageRef = firebase_storage.ref()
        var uploadTask = storageRef.child('images/users/'+fileName).put(imageFile)
        await uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, 
            (snapshot)=>{
                setUploadProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            }, 
            (error) => {
              switch (error.code) {
                case 'storage/unauthorized':
                    toast.error('Upload failed!')
                    break;
                case 'storage/canceled':
                    toast.error('Upload canceled!')
                    break;
                case 'storage/unknown':
                    toast.error('Upload storage not found!')
                  break;
              }
              setUploadProgress()
            }, 
            () => {
              // Upload completed successfully, now we can get the download URL
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                try{
                    toast.success('Image uploaded successfully.')
                    var storageRef = firebase_storage.ref()
                    userAuth?.user?.picture_name && 
                    storageRef.child('images/users/'+userAuth?.user?.picture_name).delete()
                    .then(()=>{})
                    .catch((err)=>{})
                    userUpdatePicture(userAuth.user.access_key, downloadURL, fileName)
                } catch (err) {
                    toast.error('Server error.')
                }
              });
              setUploadProgress()
            })
        setModalVisible(false)
    }

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        return (
            <div className={className} 
            style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
            </div>
        );
    };

    return (
        <AppBodyComponent activeMenuNumber={5}>

            <Dialog header='Upload Picture' visible={modalVisible} closable onHide={()=>setModalVisible(false)}>
                {
                    uploadProgress ? 'Uploading '+uploadProgress+'%' :
                    <FileUpload 
                    onSelect={selectImage} 
                    mode='advanced' 
                    name="picture" 
                    accept="image/*" 
                    maxFileSize={500000} 
                    emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} 
                    onUpload={uploadImage} 
                    onBeforeSend={beforeSendImage} 
                    headerTemplate={headerTemplate}
                    cancelOptions={{ icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' }}
                    uploadOptions={{ icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' }}
                    chooseOptions={{ icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' }}
                    itemTemplate={
                        <div style={{
                            width: '100%',
                            height: '300px',
                            position: 'relative'
                        }}>
                            <Cropper
                                showGrid={false}
                                image={imageSrc}
                                crop={crop}
                                rotation={rotation}
                                zoom={zoom}
                                aspect={1}
                                onCropChange={setCrop}
                                onRotationChange={setRotation}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                            />
                        </div>}
                    />
                }
            </Dialog>



            <div className="profile--container">
                <div className="profile--menu">
                    <div className="profile--user--menu_image">
                        {userAuth?.user?.picture_url ? <img className='profile--user--image' src={userAuth?.user?.picture_url} alt="user" /> : <PiUserCircle size={120}  style={{color: '#FFF'}}/>}
                        <Button text size='small' onClick={()=>setModalVisible(!modalVisible)}>
                            <center style={{display: 'flex', alignItems: 'flex-end', gap:'6px'}}><PiPencilBold /> picture</center>
                        </Button>
                    </div>
                    <div className="profile--menu--items">
                        <a role='button' onClick={() => handleMenu(1)} className={'text--regular--bold '+(activeMenu===1 ? 'profile--menu--item--selected' : 'profile--menu--item')}>
                            Profile
                        </a>
                        <a role='button' onClick={() => handleMenu(2)} className={'text--regular--bold '+(activeMenu===2 ? 'profile--menu--item--selected' : 'profile--menu--item')}>
                            Skills
                        </a>
                        <a role='button' onClick={() => handleMenu(3)} className={'text--regular--bold '+(activeMenu===3 ? 'profile--menu--item--selected' : 'profile--menu--item')}>
                            History
                        </a>
                        <a role='button' onClick={() => handleMenu(4)} className={'text--regular--bold '+(activeMenu===4 ? 'profile--menu--item--selected' : 'profile--menu--item')}>
                            Media
                        </a>
                    </div>
                </div>
                <div className="profile--content">
                    {children}
                </div>
            </div>
        </AppBodyComponent>
    )
}

export default Profile