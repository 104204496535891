import { Button } from 'primereact/button'
import React, { useContext, useState } from 'react'
import StatisticsContext from '../StatisticsContext'
import { Dialog } from 'primereact/dialog'
import RallyItemComponent from './RallyItemComponent'
import { PiCheckBold, PiPencil } from 'react-icons/pi'
import AuthContext from '../../../../context/AuthContext/AuthContext'

function RallyComponent({saveRally}) {
    const {currentRallyContext} = useContext(StatisticsContext)
    const [isRallyVisible, setIsRallyVisible] = useState(false)

    const footer = () => {
        return (
            <div className='flex gap-2 mt-2'>
                <Button onClick={()=>setIsRallyVisible(false)}>Close</Button>
                <Button onClick={finishRally} className='flex justify-content-between gap-2'>
                    {currentRallyContext?.length} <PiCheckBold size={18} />
                </Button>
            </div>
        )
    }

    const finishRally = () => {
        setIsRallyVisible(false)
        saveRally()
    }

    return (
        <div className='flex gap-2'>
            <Dialog header={`Rally`} visible={isRallyVisible} onHide={()=>setIsRallyVisible(false)} footer={footer}>
                <div className='flex flex-wrap gap-2'>
                {
                    currentRallyContext?.map((item, key)=>(
                        <RallyItemComponent index={key} key={key} rallyItem={item}/>
                    ))
                }
                </div>
            </Dialog>
            <Button size='small' onClick={()=>{setIsRallyVisible(!isRallyVisible)}} className='flex justify-content-between w-max gap-2 p-2 h-full'>
                {currentRallyContext?.length} <PiPencil className='-rotate-90' size={18} />
            </Button>
            <Button size='small' onClick={finishRally} className='flex justify-content-between w-max gap-2 p-2 h-full'>
                {currentRallyContext?.length} <PiCheckBold size={18} />
            </Button>
        </div>
    )
}

export default RallyComponent