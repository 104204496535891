import React from 'react'
import { PiArrowSquareUpRight, PiVolleyball } from 'react-icons/pi'
import './EventItemListComponent.css'
import { Tag } from 'primereact/tag'
import { Panel } from 'primereact/panel'
import { useNavigate } from 'react-router-dom'

function EventItemListComponent({data}) {
    const language = navigator.language
    const navigate = useNavigate();

    const header = () => {
        return (
            <div className="flex flex-row gap-2 align-items-center">
                <div className="event_item_list--head--right">
                    <a href={'/app/event_detail/'+data.access_key} style={{textDecoration: 'none',}}>
                        <div className="event_item_list--head--icon_frame">
                            <PiVolleyball style={{color: '#FCB300'}} size={24} />
                        </div>
                    </a>
                </div>
                <div className="">
                    <div className="text--regular--bold">
                        <a href={'/app/event_detail/'+data.access_key} style={{textDecoration: 'none',}}>
                            {data.name || <Tag value="add info" />}
                        </a>
                    </div>
                    <div className="text--small--bold flex gap-1">
                        <div className='text--small--normal'>Organized by</div> 
                        {data.league_name ? <a className='flex align-items-center' href={`/app/league/${data.league_access_key}`}> {data.league_name} <PiArrowSquareUpRight /></a> : 
                        <Tag value="add info" />
                    }</div>
                </div>
            </div>
        )        
    }

    return (
        <div className='col-12 md:col-6 xl:col-4 '>
            <Panel header={header}>
                <a href={'/app/event_detail/'+data.access_key} style={{textDecoration: 'none',}}>
                    <div className="grid">
                        <div className="col-4">
                            <div className="text--small--bold">Start:</div>
                            <div className="text--regular--normal">{data.start_date ? new Date(data.start_date).toLocaleDateString(language, {timeZone:"UTC"}) : <Tag value="add info" />}</div>
                        </div>
                        <div className="col-4">
                            <div className="text--small--bold">End:</div>
                            <div className="text--regular--normal">{data.end_date ? new Date(data.end_date).toLocaleDateString(language, {timeZone:"UTC"}) : <Tag value="add info" />}</div>
                        </div>
                        <div className="col-4">
                            <div className="text--small--bold">Days:</div>
                            <div className="text--regular--normal">{data.days || 
                                <Tag value="add info" />
                            }</div>
                        </div>
                        <div className="col-12">
                            <div className="text--small--bold">Address:</div>
                            <div className="text--regular--normal">{data.address || 
                                <Tag value="add info" />
                            }</div>
                        </div>
                    </div>
                </a>
            </Panel>
        </div>
    )
}

export default EventItemListComponent