import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../../../components/Navbar/Navbar'
import Footer from '../../../components/Footer/Footer'
import './Register.css'
import LoginImage from '../../../images/login_image.jpg'
import { useNavigate } from 'react-router-dom'
import { PiFacebookLogoBold, PiGoogleLogoBold } from 'react-icons/pi'
import { usePlacesWidget } from 'react-google-autocomplete'
import { RequestType, geocode, setDefaults } from 'react-geocode'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import AuthContext from '../../../context/AuthContext/AuthContext'
import {InputText} from 'primereact/inputtext'
import {Button} from 'primereact/button'
import { jwtDecode } from 'jwt-decode'
import { GoogleLogin } from '@react-oauth/google'
import IPData from 'ipdata'
import ReactGA from 'react-ga4'

function Register() {
    ReactGA.send({ hitType: "pageview", page: "/register", title: "Register User" });
    const {setValue, register, handleSubmit, clearErrors, getValues, formState: {errors}} = useForm();

    const {userRegister, googleRegister} = useContext(AuthContext);

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)

    register('marker');

    setDefaults({
        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // Your API key here.
        language: "en", // Default language for responses.
        region: "us", // Default region for responses.
    });

    const onSubmit = async (data) => {
        setIsLoading(true)
        const ipdata = new IPData(process.env.REACT_APP_IPDATA)
        await ipdata.lookup().then((info)=>{
            console.log(info)
            // setValue('zipcode', '94805') //workaround when outside USA
            setValue('zipcode', info.postal ? info.postal : '')
            setValue('marker', {lat: info.latitude, lng: info.longitude});
            setValue('city', info.city);
            setValue('state', info.region_code ? info.region_code : info.region );
        })

        await userRegister(getValues()).then((response)=>{
            response && navigate('/app/welcome')
        }).catch((error) => {
            toast.error('Sorry. An error ocurred.')
        })
    
        // await geocode(RequestType.ADDRESS, getValues("zipcode"))
        // .then(async ({ results }) => {
        //     const { lat, lng } = results[0].geometry.location;
        //     // setValue('marker', {lat: lat, lng: lng});
        //     // setValue('city', results[0].address_components[1].short_name);
        //     const idx = results[0].address_components.length === 4 ? 2 : 3
        //     // setValue('state', results[0].address_components[idx].short_name);
        // })
        // .catch((error) => {
        //     setIsLoading(false)
        //     toast.error('Sorry. An error ocurred.')
        // });
        setIsLoading(false)
    }

    const onError = (errors) => {
        // console.log(errors)
    }

    const handleGoogleSignUp = async (response) => {
        setIsLoading(true)
        const ipdata = new IPData(process.env.REACT_APP_IPDATA)
        await ipdata.lookup().then((info)=>{
            setValue('zipcode', info.postal)
        })
        const googleUser = jwtDecode(response.credential)
        setValue('first_name', googleUser.given_name);
        setValue('last_name', googleUser.family_name);
        setValue('email', googleUser.email);
        setValue('picture_url', googleUser.picture ? googleUser.picture : '');

        await geocode(RequestType.ADDRESS, getValues("zipcode"))
        .then(async ({ results }) => {
            const { lat, lng } = results[0].geometry.location;
            setValue('marker', {lat: lat, lng: lng});
            setValue('city', results[0].address_components[1].short_name);
            const idx = results[0].address_components.length === 4 ? 2 : 3
            setValue('state', results[0].address_components[idx].short_name);
            await googleRegister(getValues()).then((response)=>{
                response && navigate('/app/welcome')
            }).catch((error) => {
                toast.error('An error ocurred.')
            })
        })
        .catch((error) => {
            toast.error('Sorry. An error ocurred.')
        });
        setIsLoading(false)


    }

    const handleGoogleError = (response) => {
        console.log(response)
    }

    const handleFacebookSignUp = () => {
        navigate('/app/home')
    }

    return (
    <div>
        <Navbar />
            <div className="register--container">
                <div className="register--content">
                    <img src={LoginImage} alt="pins map" className='register--image' />
                    <div className="register--form">
                        <div className="text--heading--h2" style={{paddingBottom: '12px'}}>Sign Up</div>
                        <GoogleLogin onSuccess={handleGoogleSignUp} onError={handleGoogleError} text='Sign up with Google' />
                        {/* <button type="button" className='button--social facebook' onClick={handleFacebookSignUp}>
                            <PiFacebookLogoBold size={16} />
                            Sign up with Facebook
                        </button> */}
                        <div className="register--separator">or</div>
                        <form onSubmit={handleSubmit(onSubmit, onError)}>
                            <div className="form--row">
                                <div className="form--error-fieldset">
                                    <InputText {...register("first_name", {required: 'Required', maxLength: {value: 20, message: 'Max 20 characters'}})} placeholder='First name' 
                                    style={errors.first_name && {border: "#DF1B3F 1px solid"}} aria-invalid={errors.first_name?"true":"false"} />
                                    {errors.first_name && <span className='error-text'>{errors.first_name?.message}</span>}
                                </div>
                                <div className="form--error-fieldset">
                                    <InputText {...register("last_name", {required: "Required", maxLength: {value: 20, message: 'Max 20 characters'}})} placeholder='Last name' 
                                    style={errors.last_name && {border: "#DF1B3F 1px solid"}} aria-invalid={errors.last_name?"true":"false"} />
                                    {errors.last_name && <span className='error-text'>{errors.last_name?.message}</span>}
                                </div>
                            </div>
                            <div className="form--row">
                                <div className="form--error-fieldset">
                                    <InputText {...register("email", {required: "Required", pattern: {value: "/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/", message:'Invlaid email'}})} 
                                    placeholder='Email' style={errors.email && {border: "#DF1B3F 1px solid"}} aria-invalid={errors.email?"true":"false"} />
                                    {errors.email && <span className='error-text'>{errors.email?.message}</span>}
                                </div>
                                <div className="form--error-fieldset">
                                    <InputText type='password' {...register("password", {required: 'Required'})} placeholder='Password' 
                                    style={errors.password && {border: "#DF1B3F 1px solid"}} aria-invalid={errors.zipcode?"true":"false"} />
                                    {errors.password && <span className='error-text'>{errors.password?.message}</span>}
                                </div>
                            </div>
                            <div className="form--action_row">
                                <Button loading={isLoading} type="submit" label='Sign Up' />
                            </div>
                            <div className="form--action_row">
                                <Button text severity='primary' onClick={()=>navigate('/login')} type="button" label='Login' />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        <Footer />
    </div>
    )
}

export default Register