import axios from 'axios';
import { toast } from 'react-toastify';

export const newSport = async (name, user_access_key) =>{
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/sport/create`, {name, user_access_key})
        .then((response)=>{
            toast.success(`Sport ${name} created!`)
            return response.data.access_key
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        console.log(error)
        toast.error('An error ocurred.')
    }
}

export const listSportByName = async (sportName = '') =>{
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/sport/list_by_name/${sportName}`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('An error ocurred.')
    }
}

