import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserByAccessKey, unsubscribeEmail } from '../../../services/UserService'
import { Panel } from 'primereact/panel'
import Navbar from '../../../components/Navbar/Navbar'
import { toast } from 'react-toastify'
import { Button } from 'primereact/button'

function EmailUnsubscribe() {
    const {code} = useParams()

    const [user, setUser] = useState()
    const navigate = useNavigate()

    useEffect(()=>{
        handleGetUser()
    },[code])

    const handleGetUser = () => {
        code && getUserByAccessKey(code).then((res)=>{
            setUser(res)
        }).catch((err)=>{
            toast.error(err.message)
        })
    }

    const confirmUnsubscribe = () => {
        user && unsubscribeEmail(user.access_key).then((res)=>{
            res && navigate('/')
        })
    }

    return (
        <div>
            <Navbar />
            <div className='pt-8 px-4'>
                <Panel header='Unsubscribe Email'>
                    Are you sure you want to unsubscribe the email {user?.email}?
                    <div className='mt-4'>
                        <Button label='Confirm' disabled={!code} severity='' onClick={confirmUnsubscribe} />
                    </div>
                </Panel>
            </div>
        </div>
    )
}

export default EmailUnsubscribe