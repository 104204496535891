import React, { useState, useRef } from 'react'
import './Navbar.css'
import leagr_logo from '../../images/leagr_logo_129x50.png'
import {PiListBold, PiXBold} from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'

function Navbar() {

  const navigate = useNavigate()
  const [hideMenu, setHideMenu] = useState(true);
  const windowSize = useRef([window.innerWidth, window.innerHeight])

  const handleHideMenu = () => {
    setHideMenu(!hideMenu)
  }

  const navigateLogin = () => {
    navigate("/login")
  }

  const navigateRegister = () => {
    navigate("/register")
  }

  return (
    <>
    {windowSize.current[0] > 780 ?
      <div className='navbar' >
        <div className="navbar--head">
          <a href="/">
            <img src={leagr_logo} alt='Leagr logo' className='logo--size'/>
          </a>
        </div>
        <div className="navbar--actions">
          <div className="navbar--menu">
            {/* <span className='text--regular--normal' style={{color: '#FFF'}}>Players</span>
            <span className='text--regular--normal' style={{color: '#FFF'}}>Competitions</span>
            <span className='text--regular--normal' style={{color: '#FFF'}}>Contact us</span> */}
          </div>
          <div className="navbar--buttons">
            <Button type='button' size='small' onClick={navigateRegister}>Register</Button>
            <Button type='button' size='small' onClick={navigateLogin}>Log In</Button>
          </div>
        </div>
      </div>
      :
      <div className='navbar' >
        <div className="navbar--head">
          <a href='/'><img src={leagr_logo} alt='Leagr logo' className='logo--size'/></a>
          <div className="flex gap-2">
            <Button onClick={navigateRegister} label='Register' size='small' />
            <Button onClick={navigateLogin} label='Login' size='small' />
          </div>
        </div>
      </div>
    }
    </>
  )
}

export default Navbar