import React, { useContext, useEffect, useState } from 'react'
import AppBodyComponent from '../../../../components/AppBody/AppBodyComponent'
import './PlayerList.css'
import { useNavigate } from 'react-router-dom'
import { PiFadersBold, PiXBold } from 'react-icons/pi'
// import ReactModal from 'react-modal'
import { APIProvider, AdvancedMarker, InfoWindow, Map } from '@vis.gl/react-google-maps'
import MarkerMap from '../../../../components/MarkerMap/MarkerMap'
import InfoWindowMapPlayer from '../../../../components/InfoWindowMapPlayer/InfoWindowMapPlayer'
import PlayerItemListComponent from '../PlayerComponent/PlayerItemListComponent/PlayerItemListComponent'
import { Rating } from 'primereact/rating';
import { listUsersByFilters } from '../../../../services/UserService'
import { Button } from 'primereact/button'
import { MapTrifold, Rows } from '@phosphor-icons/react'
import { Dialog } from 'primereact/dialog'
import SportAutocompleteComponent from '../../../../components/SportAutocompleteComponent/SportAutocompleteComponent'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown';
import AuthContext from '../../../../context/AuthContext/AuthContext'
import { listSkillByNameSport } from '../../../../services/SkillService'
import { RequestType, geocode, setDefaults } from 'react-geocode'
import { toast } from 'react-toastify'
import PaginatorComponent from '../../../../components/PaginatorComponent/PaginatorComponent'
import ReactGA from 'react-ga4'
import { Panel } from 'primereact/panel'

function PlayerList() {
    ReactGA.send({ hitType: "pageview", page: "/player_list", title: "Player List" });

    const {userAuth} = useContext(AuthContext)
    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    const [infowindowShown, setInfowindowShown] = useState(false);
    const [infoWindowData, setInfoWindowData] = useState()
    const [infoWindowLat, setInfoWindowLat] = useState();
    const [infoWindowLng, setInfoWindowLng] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [playerList, setPlayerList] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState('')
    const [sport, setSport] = useState('')
    const [skill, setSkill] = useState('')
    const [address, setAddress] = useState(userAuth.user.zipcode)
    const [addressMarker, setAddressMarker] = useState({lat: userAuth.user.lat, lng: userAuth.user.lng})
    const [miles, setMiles] = useState(userAuth?.user?.setting_miles_distance || 500)
    const [skills, setSkills] = useState([])
    const [mapBoundaries, setMapBoundaries] = useState()
    const [showSearchAreaButton, setShowSearchAreaButton] = useState(false)
    const [rating, setRating] = useState('')


    const navigate = useNavigate();

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(1)
    useEffect(()=>{
        handleListUserByFilters()
    },[page])

    useEffect(()=>{
        handleListSkills()
    },[sport])

    const handleListSkills = async () => {
        await setSkills(await listSkillByNameSport('', sport.access_key))
    }

    const handleListUserByFilters = async () => {
        setIsLoading(true)
        const response = await listUsersByFilters(name, 
            showMap ? mapBoundaries : null,
            !showMap ? addressMarker : null, !showMap ? miles : null, 
            sport, skill, rating, 
            page)
        setPlayerList(response?.data)
        setTotal(response?.total)
        setIsLoading(false)
    }

    const toggleInfoWindow = (data) => {
        setInfoWindowLat(parseFloat(data.lat))
        setInfoWindowLng(parseFloat(data.lng))
        setInfoWindowData(data)
        setInfowindowShown(previousState => !previousState)
    };
    setDefaults({
        key: googleMapsApiKey, // Your API key here.
        language: "en", // Default language for responses.
        region: "us", // Default region for responses.
    });
    function getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1) ) + min;
    }

    useEffect(()=>{
        let _mapBoundaries = {}
        if (playerList && playerList.length){
            _mapBoundaries = {
                east: parseFloat(playerList[0].lng),
                north: parseFloat(playerList[0].lat),
                south: parseFloat(playerList[0].lat),
                west: parseFloat(playerList[0].lng),
            }
            playerList.forEach((item, idx)=>{
                playerList[idx].lng = parseFloat(item.lng) + (getRndInteger(10, -10)*0.001)
                playerList[idx].lat = parseFloat(item.lat) + (getRndInteger(10, -10)*0.001)

                _mapBoundaries.east = parseFloat(item.lng) > _mapBoundaries.east ? parseFloat(item.lng) : _mapBoundaries.east
                _mapBoundaries.west = parseFloat(item.lng) < _mapBoundaries.west ? parseFloat(item.lng) : _mapBoundaries.west
                _mapBoundaries.north = parseFloat(item.lat) > _mapBoundaries.north ? parseFloat(item.lat) : _mapBoundaries.north
                _mapBoundaries.south = parseFloat(item.lat) < _mapBoundaries.south ? parseFloat(item.lat) : _mapBoundaries.south
            })
        }
        setMapBoundaries(_mapBoundaries)
    },[playerList])

    const closeInfoWindow = () => setInfowindowShown(false);
    const handleModalIsOpen = () => {
        setModalIsOpen(!modalIsOpen)
    }

    const handleConfirmFilter = async () => {
        setModalIsOpen(!modalIsOpen)
        address ? 
        await geocode(RequestType.ADDRESS, address)
        .then(async ({ results }) => {
            const { lat, lng } = results[0].geometry.location;
            await setAddressMarker({lat: lat, lng: lng});
            handleListUserByFilters();
        })
        .catch((error) => {
            toast.error('Address invalid')
        }) : handleListUserByFilters()
    }

    const handleCancelFilter = () => {
        setModalIsOpen(!modalIsOpen)
    }

    const handleShowMap = (param) => {
        setShowMap(param)
    }

    const handleDragingMap = (e) => {
        setShowSearchAreaButton(true)
        const _mapBoundaries = {
            east: e.detail.bounds.east,
            north: e.detail.bounds.north,
            south: e.detail.bounds.south,
            west: e.detail.bounds.west,
        }
        setMapBoundaries(_mapBoundaries)
    }

    return (
        <AppBodyComponent activeMenuNumber={3}>
            <div className="flex flex-column gap-2">

                <Panel unstyled>
                    <div className="flex justify-content-between flex-wrap p-2 bg-blue-900 w-full border-round gap-2">
                        <div className='flex'>
                            <span className="p-buttonset">
                                <Button severity='secondary' onClick={()=>handleShowMap(false)} size='small'>
                                    <Rows weight='bold' />
                                </Button>
                                <Button severity='secondary' onClick={()=>handleShowMap(true)} size='small'>
                                    <MapTrifold weight='bold' />
                                </Button>
                            </span>
                        </div>
                        <div className='flex'>
                            <Button severity='secondary' onClick={handleModalIsOpen} size='small'>
                                <PiFadersBold />
                            </Button>
                        </div>
                    </div>
                </Panel>

                <Dialog header="Filter" visible={modalIsOpen} onHide={() => setModalIsOpen(false)} 
                footer={
                    <div>
                        <Button label="Cancel" icon="pi pi-times" onClick={handleCancelFilter} className="p-button-text" />
                        <Button label="Apply" icon="pi pi-check" onClick={handleConfirmFilter} autoFocus />
                    </div>
                }>
                    <div className="player_list--modal--container">
                        <div className='flex flex-column'>
                            <label htmlFor='name' className='text--small--normal'>Player name</label>
                            <InputText value={name} onChange={(e)=>setName(e.target.value)} name="name" id="name" className='p-inputtext-sm' />
                        </div>
                        <div className='flex flex-column'>
                            <label htmlFor='search' className='text--small--normal'>Address</label>
                            <InputText value={address} onChange={(e)=>setAddress(e.target.value)} className='p-inputtext-sm' name="address" id="address" />
                            <small>Address, zipcode, city, ...</small>
                        </div>
                        <div className='flex flex-column'>
                            <label htmlFor='search' className='text--small--normal'>Distance</label>
                            <InputText value={miles} onChange={(e)=>setMiles(e.target.value)} className='p-inputtext-sm' type="number" name="miles" id="miles"/>
                            <small>Enter the distance in miles</small>
                        </div>
                        <div className='flex flex-column'>
                            <label htmlFor='sport' className='text--small--normal'>Sport</label>
                            <SportAutocompleteComponent sport={sport} setSport={(value)=>setSport(value)} size='p-inputtext-sm' />
                        </div>
                        <div className='flex flex-column'>
                            <label htmlFor='rating' className='text--small--normal'>Sport rating</label>
                            <InputText value={rating} onChange={(e)=>setRating(e.target.value)} name="rating" id="rating" placeholder='C, B, BB, A, AA, AAA...' className='p-inputtext-sm' />
                        </div>
                        <div className='flex flex-column'>
                            <label htmlFor='skill' className='text--small--normal'>Skill</label>
                            <Dropdown size='small' value={skill} onChange={(e) => setSkill(e.value)} options={skills || []} 
                            optionLabel="name" placeholder="Select a skill" className="w-full md:w-14rem p-inputtext-sm"
                            itemTemplate={(item)=>item.name} />
                        </div>
                    </div>
                </Dialog>



                <div className="flex flex-wrap gap-4">
                    {isLoading ? 'Loading...' : 
                    !playerList?.length ? 'No players found' :  
                    (showMap && mapBoundaries) ? 
                        <div className="player_list--map_area w-full">
                            <APIProvider apiKey={googleMapsApiKey}>
                                {showSearchAreaButton && <Button label='Search in this area' size='small' onClick={handleListUserByFilters} />}
                                <Map onBoundsChanged={handleDragingMap} 
                                mapId={'player_list_map_id'}
                                initialBounds={{
                                    east: mapBoundaries.east,
                                    north: mapBoundaries.north,
                                    south: mapBoundaries.south,
                                    west: mapBoundaries.west
                                }}
                                >
                                    <>
                                        {
                                            playerList.map((item, key)=>(
                                                parseFloat(item.lat) != NaN && parseFloat(item.lng) != NaN && 
                                                <AdvancedMarker draggable={true} key={key} position={{
                                                    lat: parseFloat(item.lat), 
                                                    lng: parseFloat(item.lng)
                                                }} 
                                                onClick={()=>toggleInfoWindow(item)}>
                                                    <MarkerMap size='medium' type='user' userImg={item.picture_url} userName={`${item.first_name[0]}${item.last_name[0]}`} />
                                                </AdvancedMarker>
                                            ))
                                        }
                                        <>
                                            {
                                                infowindowShown && (
                                                    <InfoWindow 
                                                        position={{
                                                            lat: parseFloat(infoWindowLat) != NaN && parseFloat(infoWindowLat), 
                                                            lng: parseFloat(infoWindowLng) != NaN && parseFloat(infoWindowLng)
                                                        }} 
                                                        onCloseClick={closeInfoWindow}>
                                                        <InfoWindowMapPlayer data={infoWindowData} />
                                                    </InfoWindow>
                                                )
                                            }
                                        </>
                                    </>
                                </Map>
                            </APIProvider>
                        </div>
                    :
                        <div className='flex flex-column gap-4'>
                            <div className="grid">
                                {playerList ? playerList?.map((item, key)=>(
                                    <PlayerItemListComponent key={key} data={item} />
                                )) : 'No players found.'}
                            </div>
                            <PaginatorComponent page={page} total={total} setPage={setPage} />
                        </div>
                    }
                </div>
            </div>
        </AppBodyComponent>
    )
}

export default PlayerList