import React, { useContext, useEffect, useState } from 'react'
import Profile from '../Profile'
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { RequestType, geocode, setDefaults } from 'react-geocode';
import { toast } from 'react-toastify';
import { usePlacesWidget } from 'react-google-autocomplete';
import AuthContext from '../../../../context/AuthContext/AuthContext';
import {InputText} from 'primereact/inputtext'
import { Button } from 'primereact/button';
import {Editor} from 'primereact/editor'

function ProfileForm() {
    const navigate = useNavigate();
    const {setValue, register, handleSubmit, clearErrors, getValues, watch, formState: {errors}, reset} = useForm();

    const {userUpdateProfile, userAuth} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false)
    const [aboutMe, setAboutMe] = useState(userAuth?.user?.about_me)
    
    const { onChange, onBlur } = register('zipcode', {
        required: 'Select a valid Zip Code',         
    });
    register('marker');

    useEffect(()=>{
        if (!userAuth.accessToken) {
            navigate('/login')
        } else {
            reset({
                first_name: userAuth?.user.first_name,
                last_name: userAuth?.user.last_name,
                username: userAuth?.user.username,
                lat: userAuth?.user.lat,
                lng: userAuth?.user.lng,
                city: userAuth?.user.city,
                state: userAuth?.user.state,
                about_me: userAuth?.user.about_me,
                zipcode: userAuth?.user.zipcode,
            })
        }
    },[userAuth, reset])

    setDefaults({
        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // Your API key here.
        language: "en", // Default language for responses.
        region: "us", // Default region for responses.
    });

    const handleAboutMe = (e) => {
        setValue("about_me", e.htmlValue)
        setAboutMe(e.htmlValue)
    }

    const onSubmit = async (data) => {
        setIsLoading(true)
        if (data.zipcode !== userAuth.user.zipcode){
            await geocode(RequestType.ADDRESS, data.zipcode)
            .then(async ({ results }) => {
                const { lat, lng } = results[0].geometry.location;
                setValue('lat', lat);
                setValue('lng', lng);
                setValue('city', results[0].address_components[1].short_name);
                setValue('state', results[0].address_components[2].short_name);
                setValue('access_key', userAuth.user.access_key);
                await userUpdateProfile(getValues())
            })
            .catch((error) => {
                toast.error('Sorry. An error ocurred.')
            });
        } else {
            setValue('access_key', userAuth.user.access_key);
            await userUpdateProfile(getValues())
        }
        setIsLoading(false)
    }

    const onError = (errors) => {
    }

    const handleZipCode = (e) => {
        setValue("zipcode", e.target.value)
        clearErrors("zipcode")
    }
    
    return (
        <Profile activeMenuNumber={1}>
            <div className="form--container">
                <form onSubmit={handleSubmit(onSubmit, onError)} className='text--regular--normal'>
                    <div className="form--row">
                        <div className="form--error-fieldset">
                            <InputText type='text' {...register("first_name", {required: 'Required', maxLength: {value: 20, message: 'Max 20 characters'}})} placeholder='First name' 
                            style={errors.first_name && {border: "#DF1B3F 1px solid"}} aria-invalid={errors.first_name?"true":"false"} />
                            {errors.first_name && <span className='error-text'>{errors.first_name?.message}</span>}
                        </div>
                        <div className="form--error-fieldset">
                            <InputText type='text' {...register("last_name", {required: "Required", maxLength: {value: 20, message: 'Max 20 characters'}})} placeholder='Last name' 
                            style={errors.last_name && {border: "#DF1B3F 1px solid"}} aria-invalid={errors.last_name?"true":"false"} />
                            {errors.last_name && <span className='error-text'>{errors.last_name?.message}</span>}
                        </div>
                    </div>
                    <div className="form--row">
                        <div className="form--error-fieldset">
                            <InputText type='text' {...register("username", {required: "Required", pattern: {value: "/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/", message:'Invlaid username'}})} 
                            placeholder='Username' style={errors.username && {border: "#DF1B3F 1px solid"}} aria-invalid={errors.username?"true":"false"} />
                            {errors.username && <span className='error-text'>{errors.username?.message}</span>}
                        </div>
                        <div className="form--error-fieldset">
                            <InputText {...register("zipcode", {required: "Required", minLength: {value: 5, message:'Invlaid zipcode'}})}
                            name="zipcode" id="zipcode" placeholder='Zip Code' onChange={handleZipCode}
                            style={errors.zipcode && {border: "#DF1B3F 1px solid"}} aria-invalid={errors.zipcode?"true":"false"} />
                            {errors.zipcode && <span className='error-text'>{errors.zipcode?.message}</span>}
                        </div>
                    </div>
                    <div className="form--row">
                        <div className="form--fieldset">
                            <Editor value={aboutMe} onTextChange={handleAboutMe} style={{height: '220px'}} placeholder='About Me'></Editor>
                        </div>
                    </div>
                    <div className="form--action_row">
                        <Button loading={isLoading} type="submit">Save</Button>
                    </div>
                </form>
            </div>
        </Profile>
    )
}

export default ProfileForm