import React, { useEffect, useState } from 'react'
import { getPracticeStats } from '../../../services/StatsService'
import { useParams } from 'react-router-dom';
import TableStatsComponent from './Components/TableStatsComponent';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

function ShowPracticeStats() {
    const {access_key} = useParams()
    const [data, setData] = useState()
    const [setSelected, setSetSelected] = useState([])

    const sets = [
        {value: 0, label: 'All sets'},
        {value: 1, label: 'Set 1'},
        {value: 2, label: 'Set 2'},
        {value: 3, label: 'Set 3'},
        {value: 4, label: 'Set 4'},
        {value: 5, label: 'Set 5'}]

    useEffect(()=>{
        access_key && handleGetPracticeStats()
    },[access_key])

    const handleGetPracticeStats = () => {
        getPracticeStats(access_key, setSelected).then((res)=>{
            setData(res)
        })
    }
    
    return (
        <div className='flex flex-column gap-4 m-4'>
            <div className='flex align-items-center justify-content-between gap-2'>
                {data && data?.length && <div className='text-2xl'>{data[0]?.title}</div>}
                <div className='flex gap-2'>
                    <Dropdown value={setSelected} onChange={(e) => setSetSelected(e.value)} options={sets} optionLabel="label" 
                    placeholder="Select a Set" />
                    <Button label='Refresh Data' className='w-max' onClick={handleGetPracticeStats} />
                </div>
            </div>
            <TableStatsComponent tableStatsData={data} />
        </div>
    )
}

export default ShowPracticeStats