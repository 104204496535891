import React, { useContext, useEffect, useState } from 'react'
import ProfileSkillItem from './ProfileSkillItem/ProfileSkillItem'
import Profile from '../Profile'
import './ProfileSkill.css'
import { useNavigate } from 'react-router-dom'
import { listUserSkillsByUser } from '../../../../services/ProfileSkillService'
import AuthContext from '../../../../context/AuthContext/AuthContext'
import { Button } from 'primereact/button'
import { listUserSportByUser } from '../../../../services/UserSportService'

function ProfileSkill() {
    const navigate = useNavigate()

    const {userAuth} = useContext(AuthContext)
    const [sportSkills, setSportSkills] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        handleListUserSkills()
    }, [])

    const handleListUserSkills = async () => {
        setIsLoading(true)
        await setSportSkills(await listUserSportByUser(userAuth.user.access_key))
        setIsLoading(false)
    }

    const handleAddSkill = () => {
        navigate('/app/profile_skill_form')
    }

    return (
         <Profile activeMenuNumber={2}>
            <div className="profile_skill--container">
                <div className="profile_skill--action">
                    <Button type="button" onClick={handleAddSkill}>Manage my skills</Button>
                </div>
                <div className="profile_skill--content">
                    {isLoading ? <span>Loading...</span> : 
                    sportSkills && sportSkills.map((item, key)=>(
                        <ProfileSkillItem key={key} sportSkills={item} isEditable={true} />
                    ))
                    }
                </div>                
            </div>
         </Profile>
    )
}

export default ProfileSkill