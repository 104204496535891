import React, { useContext, useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';        
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { handleAttBackgroundColor, handleRecBackgroundColor, handleSerBackgroundColor } from '../Utils/ActionsUtils';
import TableHighlightsStatsComponent from './TableHighlightsStatsComponent';
import StatisticsContext from '../StatisticsContext';

function TableStatsComponent({tableStatsData}) {
    const {activePlayersContext} = useContext(StatisticsContext)
    const [stats, setStats] = useState()
    const [pointsSum, setPointsSum] = useState(0)
    
    const [serveSum, setServeSum] = useState(0)
    const [serveAvg, setServeAvg] = useState(0)
    const [serveSumA, setServeSumA] = useState(0)
    const [serveSumB, setServeSumB] = useState(0)
    const [serveSumC, setServeSumC] = useState(0)
    const [serveSumD, setServeSumD] = useState(0)
    const [serveSumE, setServeSumE] = useState(0)
    const [serveBGColor, setServeBGColor] = useState('')
    
    const [receptionSum, setReceptionSum] = useState(0)
    const [receptionAvg, setReceptionAvg] = useState(0)
    const [receptionSumA, setReceptionSumA] = useState(0)
    const [receptionSumB, setReceptionSumB] = useState(0)
    const [receptionSumC, setReceptionSumC] = useState(0)
    const [receptionSumD, setReceptionSumD] = useState(0)
    const [receptionSumE, setReceptionSumE] = useState(0)
    const [receptionBGColor, setReceptionBGColor] = useState('')
    
    const [attackSum, setAttackSum] = useState(0)
    const [attackAvg, setAttackAvg] = useState(0)
    const [attackSumA, setAttackSumA] = useState(0)
    const [attackSumB, setAttackSumB] = useState(0)
    const [attackSumC, setAttackSumC] = useState(0)
    const [attackBGColor, setAttackBGColor] = useState('')

    const [digSum, setDigSum] = useState(0)
    const [digSumA, setDigSumA] = useState(0)
    const [digSumB, setDigSumB] = useState(0)
    const [digSumC, setDigSumC] = useState(0)

    const [blockSum, setBlockSum] = useState(0)
    const [bloSumA, setBloSumA] = useState(0)
    const [bloSumB, setBloSumB] = useState(0)
    const [bloSumC, setBloSumC] = useState(0)

    const [setSum, setSetSum] = useState(0)
    const [setSumA, setSetSumA] = useState(0)
    const [setSumB, setSetSumB] = useState(0)
    const [setSumC, setSetSumC] = useState(0)

    useEffect(()=>{
        tableStatsData && handleGetPracticeStats()
    },[tableStatsData])

    const handleRowColor = (row) => {
        return  row.skill_id === 9 ? "bg-yellow-100" : 
                row.skill_id === 10 ? "bg-blue-100" : 
                row.skill_id === 11 ? "bg-bluegray-100" : 
                row.skill_id === 12 ? "bg-pink-100" : 
                row.skill_id === 14 ? "bg-purple-100" : ''
    }

    const ratingColors = ["#ff8c5a", "#ffb234", "#ffd934", "#add633", "#a0c15a"]

    const handleGetPracticeStats = () => {
        var tempPointSum = 0

        var tempSerSum = 0
        var tempSerA = 0
        var tempSerB = 0
        var tempSerC = 0
        var tempSerD = 0
        var tempSerE = 0
        var tempSerAvg = 0
        var tempSerWeight = 0

        var tempRecSum = 0
        var tempRecA = 0
        var tempRecB = 0
        var tempRecC = 0
        var tempRecD = 0
        var tempRecE = 0
        var tempRecAvg = 0
        var tempRecWeight = 0

        var tempAttSum = 0
        var tempAttA = 0
        var tempAttB = 0
        var tempAttC = 0
        var tempAttAvg = 0
        var tempAttWeight = 0

        var tempDigSum = 0
        var tempDigA = 0
        var tempDigB = 0
        var tempDigC = 0
        
        var tempBloSum = 0
        var tempBloA = 0
        var tempBloB = 0
        var tempBloC = 0

        var tempSetSum = 0
        var tempSetA = 0
        var tempSetB = 0
        var tempSetC = 0

        if (tableStatsData){
            const newData = tableStatsData?.map((e)=>{
                e.color = ratingColors[0]
                const total_points = Number(e.att2)+Number(e.blo2)+Number(e.ser4)
                const ser_perc = Number(e.ser_total) > 0 ? 
                                parseFloat(
                                    ((Number(e.ser4)*4)+(Number(e.ser3)*3)+(Number(e.ser2)*2)+(Number(e.ser1)*1))/Number(e.ser_total)
                                ).toFixed(2) : 0
                const rec_perc = Number(e.rec_total) > 0 ? 
                                parseFloat(
                                    ((Number(e.rec4)*4)+(Number(e.rec3)*3)+(Number(e.rec2)*2)+(Number(e.rec1)*1))/Number(e.rec_total)
                                ).toFixed(2) : 0
                const att_perc = Number(e.att_total) > 0 ? 
                                parseFloat(
                                    (Number(e.att2)-Number(e.att0))/Number(e.att_total)
                                ).toFixed(2) : 0
                const blo_perc = Number(e.blo_total) > 0 ? 
                                parseFloat(
                                    (Number(e.blo2)-Number(e.blo0))/Number(e.blo_total)
                                ).toFixed(2) : 0
                const set_perc = Number(e.set_total) > 0 ? 
                                parseFloat(
                                    (Number(e.set2)-Number(e.set0))/Number(e.set_total)
                                ).toFixed(2) : 0
                const dig_perc = Number(e.dig_total) > 0 ? 
                                parseFloat(
                                    (Number(e.dig2)-Number(e.dig0))/Number(e.dig_total)
                                ).toFixed(2) : 0



                tempPointSum += total_points
                tempSerSum += Number(e.ser_total)
                tempSerA += Number(e.ser4)
                tempSerB += Number(e.ser3)
                tempSerC += Number(e.ser2)
                tempSerD += Number(e.ser1)
                tempSerE += Number(e.ser0)
                tempSerAvg += Number(ser_perc)
                tempSerWeight += Number(e.ser_total) * Number(ser_perc)

                tempRecSum += Number(e.rec_total)
                tempRecA += Number(e.rec4)
                tempRecB += Number(e.rec3)
                tempRecC += Number(e.rec2)
                tempRecD += Number(e.rec1)
                tempRecE += Number(e.rec0)
                tempRecAvg += Number(rec_perc)
                tempRecWeight += Number(e.rec_total) * Number(rec_perc)

                tempAttSum += Number(e.att_total)
                tempAttAvg += Number(att_perc)
                tempAttA += Number(e.att2)
                tempAttB += Number(e.att1)
                tempAttC += Number(e.att0)
                tempAttWeight += Number(e.att_total) * Number(att_perc)

                tempDigSum += Number(e.dig_total)
                tempDigA += Number(e.dig2)
                tempDigB += Number(e.dig1)
                tempDigC += Number(e.dig0)

                tempBloSum += Number(e.blo_total)
                tempBloA += Number(e.blo2)
                tempBloB += Number(e.blo1)
                tempBloC += Number(e.blo0)

                tempSetSum += Number(e.set_total)
                tempSetA += Number(e.set2)
                tempSetB += Number(e.set1)
                tempSetC += Number(e.set0)

                return {
                    ...e,
                    total_points,
                    ser_perc,
                    rec_perc,
                    att_perc,
                    blo_perc,
                    dig_perc,
                    set_perc,
                }
            })
            setStats(newData)
            setPointsSum(tempPointSum)
            
            setServeSum(tempSerSum)
            const _serAvg = parseFloat(tempSerWeight/tempSerSum).toFixed(2)
            setServeAvg(_serAvg)
            setServeSumA(tempSerA)
            setServeSumB(tempSerB)
            setServeSumC(tempSerC)
            setServeSumD(tempSerD)
            setServeSumE(tempSerE)
            setServeBGColor(handleSerBackgroundColor(tempSerSum, _serAvg))

            setReceptionSum(tempRecSum)
            const _recAvg = parseFloat(tempRecWeight/tempRecSum).toFixed(2)
            setReceptionAvg(_recAvg)
            setReceptionSumA(tempRecA)
            setReceptionSumB(tempRecB)
            setReceptionSumC(tempRecC)
            setReceptionSumD(tempRecD)
            setReceptionSumE(tempRecE)
            setReceptionBGColor(handleRecBackgroundColor(tempRecSum, _recAvg))

            setAttackSum(tempAttSum)
            const _attAvg = parseFloat(tempAttWeight/tempAttSum).toFixed(2)
            setAttackAvg(_attAvg)
            setAttackSumA(tempAttA)
            setAttackSumB(tempAttB)
            setAttackSumC(tempAttC)
            setAttackBGColor(handleAttBackgroundColor(tempAttSum, _attAvg))

            setDigSum(tempDigSum)
            setDigSumA(tempDigA)
            setDigSumB(tempDigB)
            setDigSumC(tempDigC)

            setBlockSum(tempBloSum)
            setBloSumA(tempBloA)
            setBloSumB(tempBloB)
            setBloSumC(tempBloC)

            setSetSum(tempSetSum)
            setSetSumA(tempSetA)
            setSetSumB(tempSetB)
            setSetSumC(tempSetC)
        }
    }

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column headerClassName='p-1 border-left-3 border-top-3 border-right-1 border-gray-900' header="#" align={'center'} rowSpan={2} />
                <Column headerClassName='p-1 border-left-3 border-top-3 border-right-1 border-gray-900' header="Players" align={'center'} rowSpan={2} />
                <Column headerClassName='p-1 border-left-2 border-top-3 border-right-1 border-gray-900' header="Points" align={'center'} rowSpan={2} />
                <Column headerClassName='p-1 border-left-2 border-top-3 border-right-1 border-gray-900' header="Serve" align={'center'} colSpan={7} />
                <Column headerClassName='p-1 border-left-2 border-top-3 border-right-1 border-gray-900' header="Reception" align={'center'} colSpan={7} />
                <Column headerClassName='p-1 border-left-2 border-top-3 border-right-1 border-gray-900' header="Attack" align={'center'} colSpan={5} />
                <Column headerClassName='p-1 border-left-2 border-top-3 border-right-1 border-gray-900' header="Dig" align={'center'} colSpan={4} />
                <Column headerClassName='p-1 border-left-2 border-top-3 border-right-1 border-gray-900' header="Block" align={'center'} colSpan={4} />
                <Column headerClassName='p-1 border-left-2 border-top-3 border-right-3 border-gray-900' header="Set" align={'center'} colSpan={4} />
            </Row>
            <Row>
                <Column headerClassName='border-left-2 border-gray-900' align={'center'} header="Tot" />
                <Column headerClassName='border-gray-900 p-1' align={'center'} header="A" />
                <Column headerClassName='border-gray-900 p-1' align={'center'} header="B" />
                <Column headerClassName='border-gray-900 p-1' align={'center'} header="C" />
                <Column headerClassName='border-gray-900 p-1' align={'center'} header="D" />
                <Column headerClassName='border-gray-900 p-1' align={'center'} header="E" />
                <Column headerClassName='border-right-1 border-gray-900' align={'center'} header="%" />

                <Column headerClassName='border-left-2 border-gray-900' align={'center'} header="Tot" />
                <Column headerClassName='border-gray-900 p-1' align={'center'} header="A" />
                <Column headerClassName='border-gray-900 p-1' align={'center'} header="B" />
                <Column headerClassName='border-gray-900 p-1' align={'center'} header="C" />
                <Column headerClassName='border-gray-900 p-1' align={'center'} header="D" />
                <Column headerClassName='border-gray-900 p-1' align={'center'} header="E" />
                <Column headerClassName='border-right-1 border-gray-900' align={'center'} header="%" />

                <Column headerClassName='border-left-2 border-gray-900' align={'center'} header="Tot" />
                <Column headerClassName='border-gray-900 p-1' align={'center'} header="A" />
                <Column headerClassName='border-gray-900 p-1' align={'center'} header="B" />
                <Column headerClassName='border-gray-900 p-1' align={'center'} header="C" />
                <Column headerClassName='border-right-1 border-gray-900' align={'center'} header="Eff" />

                <Column headerClassName='border-left-2 border-gray-900' align={'center'} header="Tot" />
                <Column headerClassName='border-gray-900 p-1' align={'center'} header="A" />
                <Column headerClassName='border-gray-900 p-1' align={'center'} header="B" />
                <Column headerClassName='border-right-1 border-gray-900 p-1' align={'center'} header="C" />

                <Column headerClassName='border-left-2 border-gray-900' align={'center'} header="Tot" />
                <Column headerClassName='border-gray-900 p-1' align={'center'} header="A" />
                <Column headerClassName='border-gray-900 p-1' align={'center'} header="B" />
                <Column headerClassName='border-right-1 border-gray-900 p-1' align={'center'} header="C" />

                <Column headerClassName='border-left-2 border-gray-900' align={'center'} header="Tot" />
                <Column headerClassName='border-gray-900 p-1' align={'center'} header="A" />
                <Column headerClassName='border-gray-900 p-1' align={'center'} header="B" />
                <Column headerClassName='border-right-1 border-gray-900 border-right-3 p-1' align={'center'} header="C" />
            </Row>
        </ColumnGroup>
    );

    return (
        <div className='flex flex-column gap-2'>
            {activePlayersContext?.length ? <TableHighlightsStatsComponent tableStatsData={stats}/> : '' }
            <DataTable rowClassName={handleRowColor} showGridlines stripedRows value={stats} headerColumnGroup={headerGroup} tableStyle={{ minWidth: '10rem' }}>
                <Column footerClassName='border-bottom-3' className='text-gray-900 p-1 border-left-3 border-right-1 p-1 border-gray-900' align={'center'} field="player_number" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 p-1 border-left-3 border-right-1 p-1 border-gray-900' footer="Total" align={'left'} field="player_name" />

                <Column footerClassName='border-bottom-3' className='text-gray-900 border-left-2 border-right-1 p-1 border-gray-900' footer={pointsSum} align={'right'} field="total_points" />

                <Column footerClassName='border-bottom-3' className='text-gray-900 border-left-2 border-gray-900 p-1' footer={serveSum} align={'right'} field="ser_total" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-gray-900 p-1' footer={serveSumA} align={'right'} field="ser4" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-gray-900 p-1' footer={serveSumB} align={'right'} field="ser3" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-gray-900 p-1' footer={serveSumC} align={'right'} field="ser2" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-gray-900 p-1' footer={serveSumD} align={'right'} field="ser1" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-gray-900 p-1' footer={serveSumE} align={'right'} field="ser0" />
                <Column footerClassName={`border-bottom-3 ${serveBGColor}`} className='font-bold text-gray-900 border-right-1 border-gray-900 p-1' footer={serveAvg} align={'right'} field="ser_perc"
                bodyClassName={(item)=>handleSerBackgroundColor(item.ser_total, item.ser_perc)} />

                <Column footerClassName='border-bottom-3' className='text-gray-900 border-left-2 p-1 border-gray-900' footer={receptionSum} align={'right'} field="rec_total" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-gray-900 p-1' footer={receptionSumA} align={'right'} field="rec4" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-gray-900 p-1' footer={receptionSumB} align={'right'} field="rec3" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-gray-900 p-1' footer={receptionSumC} align={'right'} field="rec2" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-gray-900 p-1' footer={receptionSumD} align={'right'} field="rec1" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-gray-900 p-1' footer={receptionSumE} align={'right'} field="rec0" />
                <Column footerClassName={`border-bottom-3 ${receptionBGColor}`} className='font-bold text-gray-900 border-right-1 p-1 border-gray-900' footer={receptionAvg} align={'right'} field="rec_perc" 
                bodyClassName={(item)=>handleRecBackgroundColor(item.rec_total, item.rec_perc)} />

                <Column footerClassName='border-bottom-3' className='text-gray-900 border-left-2 p-1 border-gray-900' footer={attackSum} align={'right'} field="att_total" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-gray-900 p-1' footer={attackSumA} align={'right'} field="att2" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-gray-900 p-1' footer={attackSumB} align={'right'} field="att1" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-gray-900 p-1' footer={attackSumC} align={'right'} field="att0" />
                <Column footerClassName={`border-bottom-3 ${attackBGColor}`} className='font-bold text-gray-900 border-right-1 p-1 border-gray-900' footer={attackAvg} align={'right'} field="att_perc" 
                bodyClassName={(item)=>handleAttBackgroundColor(item.att_total, item.att_perc)} />

                <Column footerClassName='border-bottom-3' className='text-gray-900 border-left-2 p-1 border-gray-900' footer={digSum} align={'right'} field="dig_total" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-gray-900 p-1' footer={digSumA} align={'right'} field="dig2" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-gray-900 p-1' footer={digSumB} align={'right'} field="dig1" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-right-1 p-1 border-gray-900' footer={digSumC} align={'right'} field="dig0" />

                <Column footerClassName='border-bottom-3' className='text-gray-900 border-left-2 p-1 border-gray-900' footer={blockSum} align={'right'} field="blo_total" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-gray-900 p-1' footer={bloSumA} align={'right'} field="blo2" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-gray-900 p-1' footer={bloSumB} align={'right'} field="blo1" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-right-1 p-1 border-gray-900' footer={bloSumC} align={'right'} field="blo0" />

                <Column footerClassName='border-bottom-3' className='text-gray-900 border-left-2 p-1 border-gray-900' footer={setSum} align={'right'} field="set_total" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-gray-900 p-1' footer={setSumA} align={'right'} field="set2" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-gray-900 p-1' footer={setSumB} align={'right'} field="set1" />
                <Column footerClassName='border-bottom-3' className='text-gray-900 border-right-3 p-1 border-gray-900' footer={setSumC} align={'right'} field="set0" />
            </DataTable>
            <div className='flex flex-column gap-2 text-xs'>
                <div>
                    *Serve: A - Ace/point, B - Good. Can only set a high ball somewhere, C - Medium. Setter can’t set middle, D - Bad. Perfect/Good pass, E - error
                </div>
                <div>
                    *Reception: A - Perfect. Setter doesn’t move, B - Good. Setter can set all front row players, C - Medium. Setter can’t set middle, D - Bad. Can only set a high ball somewhere, E - Error
                </div>
                <div>
                    *Attack: A - Point, B - Keep playing, C - Error/Block
                </div>
                <div>
                    *Dig: A - Can set, B - Free ball return, C - Error
                </div>
                <div>
                    *Block: A - Point, B - Touch and keep playing, C - Error/Net
                </div>
                <div>
                    *Set: A - Broken/Single/No block, B - Double/Triple block, C - Error
                </div>
            </div>
        </div>
    )
}

export default TableStatsComponent