import React, { useEffect, useState } from 'react'
import PlayerDetail from '../PlayerDetail'
import '../PlayerDetail.css'
import { useParams } from 'react-router-dom'
import { getUserByAccessKey } from '../../../../../services/UserService'
import { Helmet } from 'react-helmet-async'

function PlayerDetailAbout() {
    const {access_key} = useParams()
    const [user, setUser] = useState()

    useEffect(()=>{
        handleGetUserByAccessKey()
    },[])

    const handleGetUserByAccessKey = async () => {
        setUser(await getUserByAccessKey(access_key))
    }
    return (
        <PlayerDetail activeMenuNumber={1} access_key={access_key} user={user}>
            <Helmet>
                {/* <meta property="og:url" content={`https://leagr.net/${user?.username}`} />
                <meta property="og:title" content={`${user?.first_name} ${user?.last_name} - LEAGR`} />
                <meta property="og:description" content={user?.about_me} /> */}
                <meta property="og:url" content="https://leagr.net/register" />
                <meta property="og:title" content="Title - LEAGR" />
                <meta property="og:description" content="LEAGR WebApp" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/leagr-15c76.appspot.com/o/images%2Fplayer_profile_social_media_share.jpg?alt=media&token=9ac6646e-3745-4f4f-930b-525aae6c7674" />
            </Helmet>
            <div className="player_detail--content">
                <p className="text--regular--normal" dangerouslySetInnerHTML={{__html: user?.about_me}} />
            </div>
        </PlayerDetail>
    )
}

export default PlayerDetailAbout