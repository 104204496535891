import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React, { useContext, useEffect, useState } from 'react'
import { startStats } from '../../../services/StatsService'
import AuthContext from '../../../context/AuthContext/AuthContext'
import { useNavigate } from 'react-router-dom'
import TeamAutocompleteComponent from '../../../components/TeamAutocompleteComponent/TeamAutocompleteComponent'
import PlayerNumberComponent from './Components/PlayerNumberComponent'
import StatisticsContext from './StatisticsContext'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { Divider } from 'primereact/divider'
import { listPlayersByTeamId, loadDefaultTeamPlayers } from '../../../services/TeamService'
import { DataTable } from 'primereact/datatable'
import { Row } from 'primereact/row'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { Calendar } from 'primereact/calendar'

function StartStatsPracticeComponent() {
    const [playersTeam, setPlayersTeam] = useState([])
    const [team, setTeam] = useState('')
    const language = navigator.language
    const [matchName, setMatchName] = useState('Individual Stats '+ new Date().toLocaleDateString(language))
    const [date, setDate] = useState(new Date())
    const {userAuth} = useContext(AuthContext)
    const navigate = useNavigate()

    useEffect(()=>{
        team && handleSetPlayersTeam()
    }, [team])
    
    const handleSetPlayersTeam = () => {
        if (team){
            listPlayersByTeamId(team.access_key).then((res)=>{
                setPlayersTeam(res)
            })
            
        }
    }
    
    const handleStartStats = (statisticsType) => {
        var _playersTeam = []
        playersTeam.forEach((item)=>{
            _playersTeam.push({
                player:{
                    access_key: item.access_key,
                    id: item.id,
                    first_name: item.first_name,
                    last_name: item.last_name,
                },
                number: item.player_number,
                position: null
            })
        })

        startStats(matchName, statisticsType, team, null, _playersTeam, null, userAuth.user.access_key, date).then(({matchId, matchAccessKey, teamAId, teamBId})=>{
            matchId && navigate('/app/statistics/'+matchAccessKey)
        })
    }
    
    const headerGroup = (
        <ColumnGroup>
            <Row>
                <ColumnGroup header="First Name" align={'center'} />
                <Column header="Last Name" align={'center'} />
                <Column header="#" align={'center'} />
            </Row>
        </ColumnGroup>
    );
    return (
        <div className='flex flex-column gap-2'>
            <ConfirmDialog />
            <div className='flex gap-2'>
                <div className="flex flex-column gap-2 w-full">
                    <label htmlFor="match" className='font-bold'>Title</label>
                    <InputText name='match' value={matchName} className='w-full p-inputtext-sm' onChange={(e)=>setMatchName(e.target.value)} />
                </div>
                <div className="flex flex-column gap-2 w-full">
                    <label htmlFor="date" className='font-bold'>Date</label>
                    <Calendar name='date' value={date} className='w-full p-inputtext-sm' onChange={(e)=>setDate(e.target.value)} />
                </div>
            </div>
            <div className='flex flex-column gap-2 w-full'>
                <label htmlFor="match" className='font-bold'>Your Team</label>
                <TeamAutocompleteComponent setTeam={(e)=>setTeam(e)} team={team} size='p-inputtext-sm' />
                <label htmlFor="match" className='font-bold'>Team Players</label>
                <DataTable showGridlines stripedRows value={playersTeam} headerColumnGroup={headerGroup} tableStyle={{ minWidth: '10rem' }}>
                    <Column align={'left'} field="first_name" className='p-2' />
                    <Column align={'left'} field="last_name" className='p-2' />
                    <Column className='p-2' align={'right'} field='player_number' />
                </DataTable>
            </div>
            <div className='flex gap-2'>
                <Button label='Start Individual Stats' className='w-max' onClick={()=>handleStartStats('practice')} />
            </div>
        </div>
    )
}

export default StartStatsPracticeComponent