import { Card } from 'primereact/card'
import React, { useContext } from 'react'
import { PiArrowClockwise } from 'react-icons/pi'
import StatisticsContext from './StatisticsContext'

function ShowGameStatsItemComponent({item, teamAName}) {
    const {gridData} = useContext(StatisticsContext)
    let markMaxPosition = 1
    let markMinPosition = 1
    let maxPosition = 1
    let minPosition = 10000
    const maxColor = 'bg-green-300'
    const minColor = 'bg-red-300'

    if (item.action_name === "Attack" || item.action_name === "Set"){
        if (Number(item.courtPositionFour) >= maxPosition) {markMaxPosition = 4; maxPosition = Number(item.courtPositionFour);}
        if (Number(item.courtPositionThree) >= maxPosition) {markMaxPosition = 3; maxPosition = Number(item.courtPositionThree);}
        if (Number(item.courtPositionTwo) >= maxPosition) {markMaxPosition = 2; maxPosition = Number(item.courtPositionTwo);}
        if (Number(item.courtPositionFive) >= maxPosition) {markMaxPosition = 5; maxPosition = Number(item.courtPositionFive);}
        if (Number(item.courtPositionSix) >= maxPosition) {markMaxPosition = 6; maxPosition = Number(item.courtPositionSix);}
        if (Number(item.courtPositionOne) >= maxPosition) {markMaxPosition = 1; maxPosition = Number(item.courtPositionOne);}
    
        if (Number(item.courtPositionFour) > 0 && Number(item.courtPositionFour) <= minPosition) {markMinPosition = 4; minPosition = Number(item.courtPositionFour);}
        if (Number(item.courtPositionThree) > 0 && Number(item.courtPositionThree) <= minPosition) {markMinPosition = 3; minPosition = Number(item.courtPositionThree);}
        if (Number(item.courtPositionTwo) > 0 && Number(item.courtPositionTwo) <= minPosition) {markMinPosition = 2; minPosition = Number(item.courtPositionTwo);}
        if (Number(item.courtPositionFive) > 0 && Number(item.courtPositionFive) <= minPosition) {markMinPosition = 5; minPosition = Number(item.courtPositionFive);}
        if (Number(item.courtPositionSix) > 0 && Number(item.courtPositionSix) <= minPosition) {markMinPosition = 6; minPosition = Number(item.courtPositionSix);}
        if (Number(item.courtPositionOne) > 0 && Number(item.courtPositionOne) <= minPosition) {markMinPosition = 1; minPosition = Number(item.courtPositionOne);}
    } else {
        if (Number(item.courtPositionFour) > 0 && Number(item.qualityFour) >= maxPosition) {markMaxPosition = 4; maxPosition = Number(item.qualityFour);}
        if (Number(item.courtPositionThree) > 0 && Number(item.qualityThree) >= maxPosition) {markMaxPosition = 3; maxPosition = Number(item.qualityThree);}
        if (Number(item.courtPositionTwo) > 0 && Number(item.qualityTwo) >= maxPosition) {markMaxPosition = 2; maxPosition = Number(item.qualityTwo);}
        if (Number(item.courtPositionFive) > 0 && Number(item.qualityFive) >= maxPosition) {markMaxPosition = 5; maxPosition = Number(item.qualityFive);}
        if (Number(item.courtPositionSix) > 0 && Number(item.qualitySix) >= maxPosition) {markMaxPosition = 6; maxPosition = Number(item.qualitySix);}
        if (Number(item.courtPositionOne) > 0 && Number(item.qualityOne) >= maxPosition) {markMaxPosition = 1; maxPosition = Number(item.qualityOne);}
    
        if (Number(item.courtPositionFour) > 0 && Number(item.qualityFour) <= minPosition) {markMinPosition = 4; minPosition = Number(item.qualityFour);}
        if (Number(item.courtPositionThree) > 0 && Number(item.qualityThree) <= minPosition) {markMinPosition = 3; minPosition = Number(item.qualityThree);}
        if (Number(item.courtPositionTwo) > 0 && Number(item.qualityTwo) <= minPosition) {markMinPosition = 2; minPosition = Number(item.qualityTwo);}
        if (Number(item.courtPositionFive) > 0 && Number(item.qualityFive) <= minPosition) {markMinPosition = 5; minPosition = Number(item.qualityFive);}
        if (Number(item.courtPositionSix) > 0 && Number(item.qualitySix) <= minPosition) {markMinPosition = 6; minPosition = Number(item.qualitySix);}
        if (Number(item.courtPositionOne) > 0 && Number(item.qualityOne) <= minPosition) {markMinPosition = 1; minPosition = Number(item.qualityOne);}    
    }

    const getColor = (courtPosition, quality) => {
        if (Number(courtPosition) > 0) 
            if (item.action_name === "Attack" || item.action_name === "Set"){
                return (maxPosition === Number(courtPosition)) ? maxColor : 
                (minPosition === Number(courtPosition)) ? minColor : ''
            } else {
                return (maxPosition === Number(quality)) ? maxColor : 
                (minPosition === Number(quality)) ? minColor : ''
            }
    }

    const handleCourtColor = () => {
        return item.team_name === teamAName ? '#86bbd8' : '#f6ae2d'
    }

    return (
        <div className={`p-4 w-11rem h-16rem text-black border-round`} style={{backgroundColor: handleCourtColor()}}>
            <div className='mb-2'>
                <div className='flex align-items-center justify-content-between text-xl font-bold'>
                    <div>{item.action_name}</div>
                    <div className='flex align-items-center'><PiArrowClockwise className='-rotate-90' size={20} /> {item.rotation_id}</div>
                </div>
                <div className='text-sm'>
                    {item.team_name}   
                </div>
            </div>
            <div className='flex h-4rem text-sm bg-white'>
                <div className={`border-right-none col-4 border-1 flex flex-column justify-content-center align-items-center ${getColor(item.courtPositionFour, item.qualityFour)}`}>
                    <div>{item.courtPositionFour}</div>
                    <div>{parseFloat(Number(item.courtPositionFour) / item.courtPositionTotal * 100).toFixed(0)+"%"}</div>
                    <div>{parseFloat(Number(item.qualityFour) / item.courtPositionTotal).toFixed(2)}</div>
                </div>
                <div className={`border-x-none col-4 border-1 flex flex-column justify-content-center align-items-center ${getColor(item.courtPositionThree, item.qualityThree)}`}>
                    <div>{item.courtPositionThree}</div>
                    <div>{parseFloat(Number(item.courtPositionThree) / item.courtPositionTotal * 100).toFixed(0)+"%"}</div>
                    <div>{parseFloat(Number(item.qualityThree) / item.courtPositionTotal).toFixed(2)}</div>
                </div>
                <div className={`border-left-none col-4 border-1 flex flex-column justify-content-center align-items-center ${getColor(item.courtPositionTwo, item.qualityTwo)}`}>
                    <div>{item.courtPositionTwo}</div>
                    <div>{parseFloat(Number(item.courtPositionTwo) / item.courtPositionTotal * 100).toFixed(0)+"%"}</div>
                    <div>{parseFloat(Number(item.qualityTwo) / item.courtPositionTotal).toFixed(2)}</div>
                </div>
            </div>
            <div className='flex h-6rem text-sm bg-white'>
                <div className={`border-right-none col-4 border-1 flex flex-column justify-content-center align-items-center ${getColor(item.courtPositionFive, item.qualityFive)}`}>
                    <div>{item.courtPositionFive}</div>
                    <div>{parseFloat(Number(item.courtPositionFive) / item.courtPositionTotal * 100).toFixed(0)+"%"}</div>
                    <div>{parseFloat(Number(item.qualityFive) / item.courtPositionTotal).toFixed(2)}</div>
                </div>
                <div className={`border-x-none col-4 border-1 flex flex-column justify-content-center align-items-center ${getColor(item.courtPositionSix, item.qualitySix)}`}>
                    <div>{item.courtPositionSix}</div>
                    <div>{parseFloat(Number(item.courtPositionSix) / item.courtPositionTotal * 100).toFixed(0)+"%"}</div>
                    <div>{parseFloat(Number(item.qualitySix) / item.courtPositionTotal).toFixed(2)}</div>
                </div>
                <div className={`border-left-none col-4 border-1 flex flex-column justify-content-center align-items-center ${getColor(item.courtPositionOne, item.qualityOne)}`}>
                    <div>{item.courtPositionOne}</div>
                    <div>{parseFloat(Number(item.courtPositionOne) / item.courtPositionTotal * 100).toFixed(0)+"%"}</div>
                    <div>{parseFloat(Number(item.qualityOne) / item.courtPositionTotal).toFixed(2)}</div>
                </div>
            </div>
        </div>
    )
}

export default ShowGameStatsItemComponent